
export const PDFUtil = {
   getCustomFonts() {
     return {
       IrisUPC: {
         normal: 'IrisUPC-Regular.ttf',
         bold: 'IrisUPC-Bold.ttf',
         italics: 'IrisUPC-Italic.ttf',
         bolditalics: 'IrisUPC-BoldItalic.ttf'
       },
       K2DNew: {
         normal: 'K2DNew-Regular.ttf',
         bold: 'K2DNew-Bold.ttf',
         italics: 'K2DNew-Italic.ttf',
         bolditalics: 'K2DNew-BoldItalic.ttf'
       },
       THSarabunNew: {
         normal: 'THSarabunNew-Regular.ttf',
         bold: 'THSarabunNew-Bold.ttf',
         italics: 'THSarabunNew-Italic.ttf',
         bolditalics: 'THSarabunNew-BoldItalic.ttf'
       },
       Roboto: {
         normal: 'Roboto-Regular.ttf',
         bold: 'Roboto-Medium.ttf',
         italics: 'Roboto-Italic.ttf',
         bolditalics: 'Roboto-MediumItalic.ttf'
       },
     }
   }
}
