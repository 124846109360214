import React, { Component } from 'react';
import { PORT } from '../../../config/config'
import {connect} from "react-redux";

// var port = window.env.PORT; yarn upgrade

// const port = window.env.PORT;

class CarEditStatus extends Component{
   constructor(props) {
     super(props);
     this.state = {
        car_statuses: []
     }
     this.setCarStatus = this.setCarStatus.bind(this);
     this.getCarStatusButton = this.getCarStatusButton.bind(this);
   }

   componentDidMount() {
     // let car = this.props.car
     this.setCarStatus()
   }

  setCarStatus() {
     let type = this.props.type
     let car_statuses = []

     if (PORT === 9056 && (type ==='stock' || type === 'technician')) {
       car_statuses = [
         {car_status_id: 1, car_status_name: 'กำลังมา', car_status_color: '#cfcfcf'},
         {car_status_id: 2, car_status_name: 'มาใหม่', car_status_color: '#f7c407'},
         {car_status_id: 3, car_status_name: 'กำลังแต่ง/ซ่อม', car_status_color: '#f76060'},
         {car_status_id: 6, car_status_name: 'พร้อมขาย', car_status_color: '#1d9ed3'},
       ]
     } else if (PORT === 9056 && (type ==='sale' || type === 'headsale')) {
       car_statuses = [
         {car_status_id: 6, car_status_name: 'พร้อมขาย', car_status_color: '#1d9ed3'},
         {car_status_id: 7, car_status_name: 'ติดจอง', car_status_color: '#e5703d'},
         {car_status_id: 10, car_status_name: 'อยู่ระหว่างจัดไฟแนนซ์', car_status_color: '#4285f4'},
         {car_status_id: 12, car_status_name: 'ไฟแนนซ์อนุมัติ', car_status_color: '#9dc183'},
       ]
     } else if (PORT === 9008) {
       // 27 รออนุมัติ  ##ECCA9C
       // 28 C0D6E8 -- ล้างรอออก
       car_statuses = [
         {car_status_id: 1, car_status_name: 'กำลังมา', car_status_color: '#cfcfcf'},
         {car_status_id: 2, car_status_name: 'มาใหม่', car_status_color: '#f7c407'},
         {car_status_id: 3, car_status_name: 'กำลังแต่ง/ซ่อม', car_status_color: '#f76060'},
         {car_status_id: 6, car_status_name: 'พร้อมขาย', car_status_color: '#1d9ed3'},
         {car_status_id: 7, car_status_name: 'ติดจอง', car_status_color: '#e5703d'},
         {car_status_id: 10, car_status_name: 'อยู่ระหว่างจัดไฟแนนซ์', car_status_color: '#4285f4'},
         {car_status_id: 29, car_status_name: 'รออนุมัติ', car_status_color: '#ECCA9C'},
         {car_status_id: 12, car_status_name: 'ไฟแนนซ์อนุมัติ', car_status_color: '#9dc183'},
         {car_status_id: 28, car_status_name: 'ล้างรอออก', car_status_color: '#C0D6E8'},
       ]
     } else if (PORT === 9059) {
       // 28 แก้ไข 29 เช็คก่อนส่งมอบ
       car_statuses = [
         {car_status_id: 1, car_status_name: 'กำลังมา', car_status_color: '#cfcfcf'},
         {car_status_id: 2, car_status_name: 'มาใหม่', car_status_color: '#f7c407'},
         {car_status_id: 3, car_status_name: 'กำลังแต่ง/ซ่อม', car_status_color: '#f76060'},
         {car_status_id: 6, car_status_name: 'พร้อมขาย', car_status_color: '#1d9ed3'},
         {car_status_id: 7, car_status_name: 'ติดจอง', car_status_color: '#e5703d'},
         {car_status_id: 28, car_status_name: 'แก้ไข', car_status_color: '#D37676'},
         {car_status_id: 29, car_status_name: 'เช็คก่อนส่งมอบ', car_status_color: '#B0C5A4'},
       ]
     } else {
       car_statuses = [
         {car_status_id: 1, car_status_name: 'กำลังมา', car_status_color: '#cfcfcf'},
         {car_status_id: 2, car_status_name: 'มาใหม่', car_status_color: '#f7c407'},
         {car_status_id: 3, car_status_name: 'กำลังแต่ง/ซ่อม', car_status_color: '#f76060'},
         {car_status_id: 6, car_status_name: 'พร้อมขาย', car_status_color: '#1d9ed3'},
         {car_status_id: 7, car_status_name: 'ติดจอง', car_status_color: '#e5703d'},
         {car_status_id: 10, car_status_name: 'อยู่ระหว่างจัดไฟแนนซ์', car_status_color: '#4285f4'},
         {car_status_id: 12, car_status_name: 'ไฟแนนซ์อนุมัติ', car_status_color: '#9dc183'},
       ]
     }
     this.setState({car_statuses: car_statuses})
  }

  getCarStatusButton(car_id) {
     let car_statuses = this.state.car_statuses
     let car_statuses_html = car_statuses.map((car_status, index) =>  (
         <div className="form-group col-md-6 text-center" key={index}>
           <button type="button"
                   className="btn btn-lg"
                   style={{backgroundColor: car_status.car_status_color, width: 200, height:50}}
                   onClick={()=>{
                     this.props.onHandle.change(car_id, car_status.car_status_id)
                   }}
           >
             {car_status.car_status_name}
           </button>
         </div>
     ))
    return car_statuses_html
  }

  render(){
    let car = this.props.car
    return(
      <div className="card card-accent-info">
        <div className="card-header">
          <strong className="text-title">แก้ไขสถานะรถ</strong>
        </div>

        {Number(PORT) === 9034 ?
            <div className="card-block">
              <div className="row">
                <div className="form-group col-md-12 h5">
                  {car.car_name}
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 h6">
                  สถานะปัจจุบัน : <span className="badge"
                                        style={{backgroundColor: car.car_status_color}} >{car.car_status_name}</span>
                </div>
              </div>


              <div className="row">

                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#cfcfcf', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 1)
                          }}
                  >
                    {
                      'กำลังมา'
                    }
                  </button>
                </div>
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#f7c407', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 2)
                          }}
                  >
                    {
                      'มาใหม่'
                    }
                  </button>
                </div>

                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#f76060', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 3)
                          }}
                  >

                    {
                      'กำลังแต่ง'
                    }

                  </button>
                </div>
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#1d9ed3', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 6)
                          }}
                  >
                    พร้อมขาย
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#e5703d', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 7)
                          }}
                  >
                    ติดจอง
                  </button>
                </div>

                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#4285f4', width: 230, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 10)
                          }}
                  >
                    อยู่ระหว่างจัดไฟแนนซ์
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#9dc183', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 12)
                          }}
                  >
                    ไฟแนนซ์อนุมัติ
                  </button>
                </div>

                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#FF9966', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 14)
                          }}
                  >
                    รถรอซื้อเข้า
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#FFCC99', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 15)
                          }}
                  >
                    ฝากตรวจแล้ว
                  </button>
                </div>

                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#FFCC66', width: 230, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 16)
                          }}
                  >
                    ฝากตรวจ + รอใบวิศวะ
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#CCCC33', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 17)
                          }}
                  >
                    แจ้งย้าย
                  </button>
                </div>

                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#CC6699', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 18)
                          }}
                  >
                    รอรับเข้า
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#66FF00', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 19)
                          }}
                  >
                    โอนแล้ว
                  </button>
                </div>
              </div>

            </div>

            :

            <div className="card-block">
              <div className="row">
                <div className="form-group col-md-12 h5">
                  {car.car_name}
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 h6">
                  สถานะปัจจุบัน : <span className="badge"
                                        style={{backgroundColor: car.car_status_color}} >{car.car_status_name}</span>
                </div>
              </div>

              <div className="row">
                {this.getCarStatusButton(car.car_id)}
              </div>

              {/*<div className="row">

                <div className="form-group col-md-6 text-center">
                  <button type="button"
                          className="btn btn-lg"
                          style={{backgroundColor: '#cfcfcf', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 1)
                          }}
                  >
                    {
                      'กำลังมา'
                    }
                  </button>
                </div>
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#f7c407', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 2)
                          }}
                  >
                    {
                      'มาใหม่'
                    }
                  </button>
                </div>

                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#f76060', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 3)
                          }}
                  >

                    {
                      'กำลังแต่ง'
                    }

                  </button>
                </div>
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#1d9ed3', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 6)
                          }}
                  >
                    พร้อมขาย
                  </button>
                </div>

                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#e5703d', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 7)
                          }}
                  >
                    ติดจอง
                  </button>
                </div>

                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#4285f4', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 10)
                          }}
                  >
                    อยู่ระหว่างจัดไฟแนนซ์
                  </button>
                </div>

                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#9dc183', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 12)
                          }}
                  >
                    ไฟแนนซ์อนุมัติ
                  </button>
                </div>

              </div>*/}

              <div className="row">

              </div>

              <div className="row">

              </div>

            </div>
        }


          <div className="card-footer text-right">
            <button type="reset"
                    onClick={this.props.onHandle.cancel}
                    className="btn btn-sm btn-danger">
              <i className="fa fa-refresh"></i> ยกเลิก
            </button>
            <button type="submit"
                    className="btn btn-sm btn-primary hidden">
              <i className="fa fa-save"></i> บันทึก
            </button>

            <input type="hidden" id="car_id" name="car_id" value={this.props.car.car_id}/>

          </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  type: state.auth.type,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarEditStatus)
