import React, { Component } from 'react';
import 'moment/locale/th';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import stringUtil from '../../utils/stringUtil'
import {APIURL, PORT} from "../../config/config";
import axios from "axios";

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew-Regular.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

class DeliverCarPDF extends Component {
  constructor (props) {
    super(props);
    this.state = {
      company_setting: null,
    }
    this.makePDF = this.makePDF.bind(this)
    this.makePDF2 = this.makePDF2.bind(this)
    this.loadCompanySetting = this.loadCompanySetting.bind(this)
  }

  async loadCompanySetting() {
    await axios.get(APIURL + '/company_setting/1')
        .then(res => {
      let data = res.data
      let company_setting = data.company_setting
      this.setState({
        company_setting: company_setting
      })
    })
  }



  async componentDidMount () {
    await this.loadCompanySetting();


    if(PORT === 9014){
      this.makePDF2()
    }else {
      this.makePDF()
    }
  }

  //9025 9053 nalicar
  makePDF() {
    const { car_sell } = this.props

    let cardIdImage64 = this.props.cardIdImage64
    let carDeliverImage64 = this.props.carDeliverImage64

    const customer_name = car_sell.customer_name + ' ' + car_sell.customer_lastname
    const customer_card_id = car_sell.customer_card_id === null ? '-' : car_sell.customer_card_id
    const customer_mobile = car_sell.customer_mobile

    const car_brand_name = car_sell.car_brand_name
    const car_model = car_sell.car_model_name
    const car_name = car_brand_name+' '+car_model

    let car_license_plate = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.car_license_plate_new : car_sell.car_license_plate_old
    let province_name = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.province_name_new : car_sell.province_name_old

    let car_license = car_license_plate+' '+province_name
    let bank_finance_name = car_sell.bank_finance_name_new

    let customer_name_pad = stringUtil.addSpacePaddingFill(customer_name, 34)
    let customer_card_pad = stringUtil.addSpacePaddingFill(customer_card_id, 34)
    let customer_mobile_pad = stringUtil.addSpacePaddingFill(customer_mobile, 34)
    let car_name_pad = stringUtil.addSpacePaddingFill(car_name, 40)
    let car_license_pad = stringUtil.addSpacePaddingFill(car_license, 40)
    let bank_finance_name_pad = stringUtil.addSpacePaddingFill(bank_finance_name, 40)

    let blockCustomer = [
      {
        columns: [
          { width: 68, style: 'normal',
            text: 'ชื่อผู้เช่าซื้อ' ,
          },
          { width: '32%', style: ['normalLeft', 'bottomLine'],
            text:  customer_name_pad,
          },
          { width: 100, style: 'normal',
            text: 'เลขบัตรประชาชน' ,
          },
          { width: 'auto', style: ['normalLeft', 'bottomLine'],
            text: customer_card_pad,
          },
        ],
        columnGap: 2,
        lineHeight:1
      },
      {
        columns: [
          { width: 58, style: 'normal',
            text: 'เบอร์โทร' ,
          },
          { width: '32%', style: ['normalLeft', 'bottomLine'],
            text:  customer_mobile_pad,
          },
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 16, 0, 0],
      }
    ]

    let blockCar = [
      {
        columns: [
          { width: 76, style: 'normal',
            text: 'เช่าซื้อรถยนต์' ,
          },
          { width: '32%', style: ['normalLeft', 'bottomLine'],
            text:  car_name_pad,
          },
          { width: 100, style: 'normal',
            text: 'หมายเลขทะเบียน' ,
          },
          { width: 'auto', style: ['normalLeft', 'bottomLine'],
            text: car_license_pad,
          },
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 16, 0, 0],
      },
      {
        columns: [
          { width: 110, style: 'normal',
            text: 'จัดไฟแนนซ์ธนาคาร' ,
          },
          { width: '32%', style: ['normalLeft', 'bottomLine'],
            text:  bank_finance_name_pad,
          }
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 16, 0, 0],
      },
      {
        columns: [
          { width: '100%', style: 'normal',
            text: 'ได้ตรวจสอบสภาพรถ และรับรถสภาพเรียบร้อยแล้ว' ,
          },
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 16, 0, 0],
      },
    ]

    let tableSign = {
      margin: [0, 45, 0, 10],
      layout: 'noBorders', // optional
      table: {
        widths: ['25%', '25%', '50%'],
        body: [
          [
            { text: '' },
            { text: '' },
            { text: 'ลงชื่อผู้เช่าซื้อ...............................................................................', lineHeight:1.5 },
          ],
          [
            { text: '' },
            { text: '' },
            { text: 'ได้รับรถวันที่.................../.............................../..........................', lineHeight:1.5 },
          ],
        ]
      }
    }

    let blockImage = []
    if (cardIdImage64 !== null && carDeliverImage64 !== null) {
      blockImage = [
        {
          columns: [
            {
              width: 260,
              //text: 'image'
              image: cardIdImage64
            },
            {
              width: 260,
              //text: 'image'
              image: carDeliverImage64
            },
          ],
          columnGap: 10,
          lineHeight: 1.3,
          margin: [ 0, 30, 0, 0 ], // margin: [left, top, right, bottom]
        },
      ]
    }

    const docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [40, 50, 40, 40],
      content: [
        { text: 'ใบรับรถ-ส่งมอบรถ', style: 'header', margin: [0, 0, 0, 20], },
        blockCustomer,
        blockCar,
        tableSign,
        blockImage
      ],
      defaultStyle: {
        font: 'THSarabunNew',
        fontSize: 15,
      },

      styles: {
        header: {
          alignment: 'center',
          bold: true,
          fontSize: 20
        },
        normal: {
          fontSize: 16,
        },
        normalRight: {
          fontSize: 16,
          alignment: 'right'
        },
        normalLeft: {
          fontSize: 16,
          alignment: 'left',
        },
        left22: {
          marginLeft: 22
        },
        topic: {
          bold: true,
          fontSize: 16
        },
        noBorder: {
          bold: true,
          alignment: 'right'
        },
        lineVertical: {
          marginTop: -5
        },
        textStatus: {
          marginTop: -5,
          color: 'red'
        },
        headTable: {
          fontSize: 12,
          padding: '0 10 0 10'
        },
        table: {
          fontSize: 12
        },
        underline: {
          decoration: 'underline'
        },
        bottomLine: {
          decoration: 'underline',
          decorationStyle: 'dotted',
        }
      }
    };
    pdfMake.createPdf(docDefinition).open()
  }

  makePDF2() {
    const { car_sell } = this.props
    const customer_name = car_sell.customer_name + ' ' + car_sell.customer_lastname
    const customer_card_id = car_sell.customer_card_id === null ? '-' : car_sell.customer_card_id
    const customer_mobile = car_sell.customer_mobile

    const car_brand_name = car_sell.car_brand_name
    const car_model = car_sell.car_model_name
    const car_name = car_brand_name+' '+car_model

    const { company_setting } = this.state
    let company_name = company_setting.company_name
    let company_address = company_setting.company_address
    //let company_address_all = company_setting.company_address
    //let company_tel = company_setting.company_tel
    /*let company_arr = company_address_all.split('ต.')
    if(PORT === 9014){
      if (company_arr.length === 2) {
        let company_arr1 = company_arr[0]
        let company_arr2 = company_arr[1]
        company_address = company_arr1 +'\nต.'+ company_arr2
      }
    }else {
      company_address = company_address_all
    }*/


    let base64Img = this.props.carBase64Img

    let logo_width = 100
    if (company_address.length >=60 ) {
      logo_width = 76
    } else if (company_address.length >=57 ) {
      logo_width = 96
    }
    let headerLeft = {image: base64Img, width: logo_width, rowSpan: 2, margin: [0, -10, 0, 0]};

    let car_license_plate = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.car_license_plate_new : car_sell.car_license_plate_old
    let province_name = car_sell.car_license_plate_new !== '' &&  car_sell.car_license_plate_new !==  car_sell.car_license_plate_old ? car_sell.province_name_new : car_sell.province_name_old

    let car_license = car_license_plate+' '+province_name
    let bank_finance_name = car_sell.bank_finance_name_new

    let car_num_engines = car_sell.car_num_engines //เครื่องยนต์
    let car_number = car_sell.car_number //ตัวรถ

    let customer_name_pad = stringUtil.addSpacePaddingFill(customer_name, 50)
    let customer_card_pad = stringUtil.addSpacePaddingFill(customer_card_id, 34)
    let customer_mobile_pad = stringUtil.addSpacePaddingFill(customer_mobile, 42)
    let car_name_pad = stringUtil.addSpacePaddingFill(car_name, 38)
    let car_license_pad = stringUtil.addSpacePaddingFill(car_license, 38)
    let bank_finance_name_pad = stringUtil.addSpacePaddingFill(bank_finance_name, 40)

    let car_num_engines_pad = stringUtil.addSpacePaddingFill(car_num_engines, 38)
    let car_number_pad = stringUtil.addSpacePaddingFill(car_number, 32)

    let dateDeliverCar = [
      {
        columns: [
          { width: 50, style: 'normal',
            text: '' ,
          },
          { width: '50%', style: ['normalLeft', 'bottomLine'],
            text:  '',
          },
          { width: 30, style: 'normal',
            text: 'วันที่' ,
          },
          { width: '30%', style: ['normalLeft'], margin: [0, 5, 0, 0],
            text: '.........................................................',
          },
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 6, 0, 14],
      }
    ]

    let blockCustomer = [
      {
        columns: [
          { width: 67, style: 'normal',
            text: 'ชื่อผู้เช่าซื้อ' ,
          },
          { width: '36%', style: ['normalLeft', 'bottomLine'],
            text:  customer_name_pad,
          },
          { width: 100, style: 'normal',
            text: 'เลขบัตรประชาชน' ,
          },
          { width: 'auto', style: ['normalLeft', 'bottomLine'],
            text: customer_card_pad,
          },
        ],
        columnGap: 2,
        lineHeight:1
      },
      {
        columns: [
          { width: 80, style: 'normal',
            text: 'เบอร์โทรศัพท์' ,
          },
          { width: 'auto', style: ['normalLeft', 'bottomLine'],
            text:  customer_mobile_pad,
          },
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 12, 0, 0],
      }
    ]

    let blockCar = [
      {
        columns: [
          { width: 78, style: 'normal',
            text: 'เช่าซื้อรถยนต์' ,
          },
          { width: '33%', style: ['normalLeft', 'bottomLine'],
            text:  car_name_pad,
          },
          { width: 100, style: 'normal',
            text: 'หมายเลขทะเบียน' ,
          },
          { width: 'auto', style: ['normalLeft', 'bottomLine'],
            text: car_license_pad,
          },
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 12, 0, 0],
      },
      {
        columns: [
          { width: 100, style: 'normal',
            text: 'หมายเลขเครื่องยนต์' ,
          },
          { width: '29%', style: ['normalLeft', 'bottomLine'],
            text:  car_num_engines_pad,
          },
          { width: 100, style: 'normal',
            text: ' หมายเลขตัวถัง' ,
          },
          { width: '*', style: ['normalLeft', 'bottomLine'],
            text: car_number_pad,
          },
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 12, 0, 0],
      },
      {
        columns: [
          { width: 100, style: 'normal',
            text: 'จัดไฟแนนซ์ธนาคาร' ,
          },
          { width: '32%', style: ['normalLeft', 'bottomLine'],
            text:  bank_finance_name_pad,
          }
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 12, 0, 0],
      },
      {
        columns: [
          { width: '100%', style: 'normal',
            text: 'ได้ตรวจสอบสภาพรถ และรับรถสภาพเรียบร้อยแล้ว' ,
          },
        ],
        columnGap: 2,
        lineHeight:1,
        margin: [0, 16, 0, 0],
      },
    ]

    let tableSign = {
      margin: [0, 45, 0, 10],
      layout: 'noBorders', // optional
      table: {
        widths: ['38%', '49%', '13%'],
        body: [
          [
            { text: '' },
            { text: 'ลงชื่อผู้เช่าซื้อ.............................................................................' },
            { text: 'ผู้รับรถยนต์', lineHeight:1.7 },
          ],
          [
            { text: '' },
            { text: 'ลงชื่อ.........................................................................................' },
            { text: 'พนักงานขาย'},
          ],
          [
            { text: '' },
            { text: '' },
            { text: '(ผู้มอบรถยนต์)', margin: [-6, -8, 0, 0],lineHeight:1.5 },
          ],
          [
            { text: '' },
            { text: 'ลงชื่อ.........................................................................................' },
            { text: 'ผู้จัดการ', lineHeight:1.7},
          ],
          [
            { text: '' },
            { text: 'ลงชื่อ.........................................................................................' },
            { text: 'พยาน', lineHeight:1.7},
          ],
          [
            { text: '' },
            { text: 'ลงชื่อ.........................................................................................' },
            { text: 'พยาน', lineHeight:1.7},
          ],
        ]
      }
    }

    const docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [50, 50, 40, 40],
      content: [
        {
          table: {
            widths: [ '20%', '60%', '20%' ],
            body: [
              [
                headerLeft,
                {text: company_name, style: 'bodyhead', colSpan: 2, margin: [-20, 0, 0, 0],},
                '',
              ],
              [
                '',
                {text:  company_address , style: 'normalCenter', colSpan: 2, margin: [-20, 0, 0, 0],},
                '',
              ],
              [
                '',
                {text:  '' , style: 'normalCenter'},
                '',
              ],
            ],
          },
          layout: 'noBorders'
        },
        { text: 'ใบรับรถ-ส่งมอบรถ', style: 'header', margin: [0, 3, 0, 20],},
        dateDeliverCar,
        blockCustomer,
        blockCar,
        tableSign
      ],
      defaultStyle: {
        font: 'THSarabunNew',
        fontSize: 15,
      },

      styles: {
        header: {
          alignment: 'center',
          bold: true,
          fontSize: 20
        },
        normal: {
          fontSize: 16,
        },
        normalRight: {
          fontSize: 16,
          alignment: 'right'
        },
        normalLeft: {
          fontSize: 16,
          alignment: 'left',
        },
        left22: {
          marginLeft: 22
        },
        topic: {
          bold: true,
          fontSize: 16
        },
        noBorder: {
          bold: true,
          alignment: 'right'
        },
        lineVertical: {
          marginTop: -5
        },
        textStatus: {
          marginTop: -5,
          color: 'red'
        },
        headTable: {
          fontSize: 12,
          padding: '0 10 0 10'
        },
        table: {
          fontSize: 12
        },
        underline: {
          decoration: 'underline'
        },
        bottomLine: {
          decoration: 'underline',
          decorationStyle: 'dotted',
        },
        bodyhead: {
          fontSize: 17,
          bold: true,
          alignment: 'center'
        },
        normalCenter: {
          fontSize: 15,
          alignment: 'center'
        },
      }
    };
    pdfMake.createPdf(docDefinition).open()
  }

  render () {
    return <></>
  }

}

export default DeliverCarPDF
