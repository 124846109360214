import React, { Component } from 'react'
import axios from 'axios'
import moment from 'moment'
import {APIURL, PORT} from '../../../config/config'
import pdfMake from 'pdfmake/build/pdfmake'
import { Modal, ModalBody } from 'reactstrap'
import { save } from 'save-file'
import {CarSellUtil, CarsUtil} from "../../../utils/carSellUtil";

const port = window.env.PORT;

class CarInAll extends Component{

  constructor (props) {
    super(props)
    this.state = {
      car_list: [],
      reserve_status: [7, 10, 12],
      toggle_car_parking: false,
      car_parking_name: '',
      car_parking_order: 0,
      actionType: 'บันทึก',
      car_parking_list: [],
      car_parking_index: '',
      width: 0,
      height: 0
    }

    this.loadCarAll = this.loadCarAll.bind(this);
    this.printCarAll = this.printCarAll.bind(this);
    this.onChangeParkLocation = this.onChangeParkLocation.bind(this);
    this.onChangePlateRed = this.onChangePlateRed.bind(this);
    this.onChangeTunedCar = this.onChangeTunedCar.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.loadCarParking = this.loadCarParking.bind(this);
    this.toggleCarParking = this.toggleCarParking.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onCarParkingDelete = this.onCarParkingDelete.bind(this);
    this.onCarParkingEdit = this.onCarParkingEdit.bind(this);
    this.onCarParkSubmit = this.onCarParkSubmit.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.exportCarInAllSummary = this.exportCarInAllSummary.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidMount() {
    CarsUtil.removeCarSelect()
    CarSellUtil.removeCarSellSelect()

    this.loadCarParking();
    this.loadCarAll();

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  loadCarAll() {
    let url = APIURL+'/car'
    axios.get(url).then(res => {
      let data = res.data.map(car => {
        return {...car, car_edit: 1}
      })
      // let carData = data.sort((a, b) => {
      //   var dateA = new Date(a.car_date_in)
      //   var dateB = new Date(b.car_date_in)
      //   return (dateB.getTime() > dateA.getTime()) ? b : a
      // })
      this.setState({car_list: data});
    })
  }

  loadCarParking() {
    let url = APIURL+'/car_parking'
    axios.get(url).then(res => {
      let data = res.data
      this.setState({
        car_parking_list: data
      })
    })
  }

  onInputChange(event) {
    event.preventDefault()
    let value = event.target.value;
    let name = event.target.name;

    this.setState({
      [name]: value
    })
  }

  onCarParkingDelete(car_parking_index) {
    const { car_parking_list } = this.state;
    const car_parking = car_parking_list[car_parking_index];

    this.setState({
      car_parking_index: car_parking_index
    })

    if(window.confirm('คุณต้องการลบข้อมูล '+ car_parking.car_parking_name )) {
      let url = APIURL+'/car_parking/' + car_parking.car_parking_id
      axios.delete(url).then(res => {
        let result = res.data
        if(result === 'success') {
          this.loadCarParking()
        }
      })
    }
  }

  onCarParkingEdit(car_parking_index) {
    const { car_parking_list } = this.state;
    const car_parking = car_parking_list[car_parking_index];

    this.setState({
      car_parking_index: car_parking_index,
      actionType: 'แก้ไข',
      car_parking_name: car_parking.car_parking_name,
      car_parking_order: car_parking.car_parking_order
    })
  }

  toggleCarParking() {
        this.setState({
          toggle_car_parking: !this.state.toggle_car_parking,
          actionType: 'บันทึก'
        })
  }

  printCarAll() {
    // sum by การจอด park_location
    // หน้าเต็นท์
    // let _ = require('lodash');

    let car_parking_list = this.state.car_parking_list
    if (port === 9005 || port === '9005') {
      // pisan
      let car_parking_list_data = this.state.car_parking_list
      car_parking_list = car_parking_list_data.sort((a, b) => (a.car_parking_order_pdf > b.car_parking_order_pdf) ? 1 : -1)
    }

    // let car_list = this.state.car_list
    // let carParkUniq = _.unionBy(car_list, 'park_location')

    let carPartCounts = []
    for (let i = 0 ; i < car_parking_list.length ; i++) {
      let car_parking_name = car_parking_list[i].car_parking_name
      let car_parking_order_pdf = car_parking_list[i].car_parking_order_pdf
      let car_parking_group = car_parking_list[i].car_parking_group
      let inCar = this.state.car_list.filter(car => car.park_location === car_parking_name ).map((car, index) => {
        return car
      })
      // รายการอืนๆ ฝั่งขวา
      if (car_parking_group === 0 && inCar.length !==0) {
        carPartCounts.push({
          car_parking_name: car_parking_name,
          car_parking_qty: inCar.length,
          car_parking_order_pdf: car_parking_order_pdf,
          car_parking_group: car_parking_group
        })
      } else if(car_parking_group === 1){
        carPartCounts.push({
          car_parking_name: car_parking_name,
          car_parking_qty: inCar.length,
          car_parking_order_pdf: car_parking_order_pdf,
          car_parking_group: car_parking_group
        })
      }

    }



    //car.car_status_id===7
    let reserve_status = this.state.reserve_status
    let carReserve = this.state.car_list.filter(car => reserve_status.includes(car.car_status_id) ).map((car, index) => {
      return car
    })

    let widthTableSum = [120, 70, 30, 120, 70,30]
    let carSums = []
    let count = 0;
    let checkPair = carPartCounts.length % 2 === 0

    while (count < carPartCounts.length) {
      let carPark1 = carPartCounts[count]
      let carPark2 = null

      if (checkPair) {
         carPark2 = carPartCounts[count+1]
      } else {
        if (count+1 === carPartCounts.length){
          carPark2 = {car_parking_name: '',  car_parking_qty: ''}
        } else {
          carPark2 = carPartCounts[count+1]
        }
      }

      count += 2
      let sumRow = [
        {
          text: carPark1.car_parking_name,
          style: 'textBold',
          border: [false, false, false, false]
        },
        {
          text: carPark1.car_parking_qty,
          style: 'textCenterBoldRed',
          border: [false, false, false, false]
        },
        {
          text: 'คัน',
          style: 'textBold',
          border: [false, false, false, false]
        },
        {
          text: carPark2.car_parking_name === '' ? '' : carPark2.car_parking_name,
          style: 'textBold',
          border: [false, false, false, false]
        },
        {
          text: carPark2.car_parking_qty === '' ? '' : carPark2.car_parking_qty,
          style: 'textCenterBoldRed',
          border: [false, false, false, false]
        },
        {
          text: carPark2.car_parking_name === '' ? '' :'คัน',
          style: 'textBold',
          border: [false, false, false, false]
        },
      ];
      carSums.push(sumRow)
    }


    let carAllRows = []
    let raito  = 100/6;
    let widthTable = [raito*0.35+'%', '*', raito*1.0+'%', raito*0.45+'%', raito*0.9+'%', raito*0.45+'%', raito*0.45+'%']

    let tableHeader = [
      { style: 'bodyBold', text: 'ลำดับ',},
      { style: 'bodyBold', text: 'ยี่ห้อ/รุ่น',},
      { style: 'bodyBold', text: 'ทะเบียน',},
      { style: 'bodyBold', text: 'ป้ายแดง',},
      { style: 'bodyBold', text: 'รถจอด',},
      { style: 'bodyBold', text: 'รถแต่ง',},
      { style: 'bodyBold', text: 'รถจอง',},
    ];

    carAllRows.push(tableHeader);

    this.state.car_list.forEach((car, index) => {
      let row = []
      let order = index + 1;
      //var date_in = moment(car.car_date_in).format('DD-MM-YYYY')
      let car_detail = car.car_name+' '+car.car_color_name
      let license_new = car.car_license_plate_new+'\n'+car.province_name_new
      let license_old = car.car_license_plate_old+'\n'+car.province_name_old
      let license = car.car_license_plate_new === '' ? license_old : license_new

      let plate_red = car.plate_red
      let park_location = car.park_location
      let tuned_car = car.tuned_car
      let car_status_name = reserve_status.includes(car.car_status_id)? "จอง" : "-"
      // var car_status_name2 = car.car_status_name

      let itemOrder = {
        text: order,
        style: 'textCenter'
      };
      let itemCarDetail = {
        text: car_detail,
        style: 'bodyText'
      };
      let itemLicense = {
        text: license,
        style: 'textCenter'
      }
      let itemPlateRed = {
        text: plate_red,
        style: 'textCenterRed'
      }
      let itemPark = {
        text: park_location,
        style: 'textCenter'
      }
      // +'\n'+car_status_name2
      let itemTunedCar = {
        text: tuned_car,
        style: 'textCenter'
      }
      let itemCarStatusName = {
        text: car_status_name,
        style: 'textCenterRed'
      }

      row.push(itemOrder)
      row.push(itemCarDetail)
      row.push(itemLicense)
      row.push(itemPlateRed)
      row.push(itemPark)
      row.push(itemTunedCar)
      row.push(itemCarStatusName)
      //
      carAllRows.push(row)
    });

    // { canvas: [
    //   {
    //     type: 'line',
    //     x1: 5,  y1: 2,
    //     x2: 500, y2: 2,
    //     lineWidth: 0.5
    //   }
    // ]
    // },

    // widths: ['33%','33%','33%','33%','33%','33%'],
    let tableCarSums
    let tableCarReserveSum

    let headerText = "ข้อมูลรถในเต็นท์ วันที่ "+moment(new Date()).local('TH').format('LLL')

    if (PORT !== 9005) {

      let carReserveSumPdf = [
        {
          text: ' รถทั้งหมด',
          style: 'textBold',
          border: [false, false, false, false]
        },
        {
          text: this.state.car_list.length,
          style: 'textCenterBoldRed',
          border: [false, false, false, false]
        },
        {
          text: 'คัน',
          style: 'textBold',
          border: [false, false, false, false]
        },
        {
          text: 'รถจอง',
          style: 'textBold',
          border: [false, false, false, false]
        },
        {
          text: carReserve.length,
          style: 'textCenterBoldRed',
          border: [false, false, false, false]
        },
        {
          text: 'คัน',
          style: 'textBold',
          border: [false, false, false, false]
        },
      ]

      tableCarReserveSum = {
        table: {
          widths: widthTableSum,
          body: [carReserveSumPdf]
        }
      }

      tableCarSums =  {
        table: {
          widths: widthTableSum,
          body: carSums
        }
      }

    } else if (PORT === 9005) {

      headerText = "ข้อมูลรถในบริษัท วันที่ "+moment(new Date()).local('TH').format('LLL')

      let carReserveSumLeft = [
        {
          text: ' รถทั้งหมด',
          style: 'textBold',
          border: [false, false, false, false]
        },
        {
          text: this.state.car_list.length,
          style: 'textCenterBoldRed',
          border: [false, false, false, false]
        },
        {
          text: 'คัน',
          style: 'textBold',
          border: [false, false, false, false]
        },
      ]

      let carReserveSumRight = [
        {
          text: 'รถจอง',
          style: 'textBold',
          border: [false, false, false, false]
        },
        {
          text: carReserve.length,
          style: 'textCenterBoldRed',
          border: [false, false, false, false]
        },
        {
          text: 'คัน',
          style: 'textBold',
          border: [false, false, false, false]
        },
      ]

      /* car sum ตามการจดอ */
      let CarSumsLeft = carPartCounts.filter(car => car.car_parking_group === 1)
      let CarSumsRight = carPartCounts.filter(car => car.car_parking_group === 0)

      let carSumsLeftRow = []
      let carSumsRightRow = []

      carSumsLeftRow.push(carReserveSumLeft)
      carSumsRightRow.push(carReserveSumRight)

      let count = 0;
      while (count < CarSumsLeft.length) {
        let carPark1 = CarSumsLeft[count]
        let sumRow = [
          {
            text: carPark1.car_parking_name,
            style: 'textBold',
            border: [false, false, false, false]
          },
          {
            text: carPark1.car_parking_qty,
            style: 'textCenterBoldRed',
            border: [false, false, false, false]
          },
          {
            text: 'คัน',
            style: 'textBold',
            border: [false, false, false, false]
          }
        ]
        carSumsLeftRow.push(sumRow)
        count++;
      }


      count = 0;
      while (count < CarSumsRight.length) {
        let carPark2 = CarSumsRight[count]
        let sumRow = [
          {
            text: carPark2.car_parking_name,
            style: 'textBold',
            border: [false, false, false, false]
          },
          {
            text: carPark2.car_parking_qty,
            style: 'textCenterBoldRed',
            border: [false, false, false, false]
          },
          {
            text: 'คัน',
            style: 'textBold',
            border: [false, false, false, false]
          }
        ]
        carSumsRightRow.push(sumRow)
        count++;
      }

      let widthTableSumLeft = [120, 70, 30]

      let tableCarSumLeft = {
        table: {
          widths: widthTableSumLeft,
          body: carSumsLeftRow
        },
        border: [false, false, false, false]
      }

      let tableCarSumRight = {
        table: {
          widths: widthTableSumLeft,
          body: carSumsRightRow
        },
        border: [false, false, false, false]
      }

      tableCarSums = {
        table: {
              widths: ['50%', '50%'],
              body: [
                [
                  tableCarSumLeft,
                  tableCarSumRight
                ]
              ]
        }
      }
    }

    let docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [45, 30, 45, 30], // margin: [left, top, right, bottom]
      content: [
        {text: headerText, style: 'bodyHead', lineHeight: 1.3},
        tableCarReserveSum,
        tableCarSums,
        {
          table: {
            headerRows: 1,
            widths: widthTable,
            body: carAllRows
          }
        },
      ],
      styles: {
        bodyHead: {
          fontSize: 20,
          bold: true,
          alignment: 'center'
        },
        bodyText: {
          fontSize: 14,
        },
        bodyBold: {
          fontSize: 14,
          bold: true,
          alignment: 'center'
        },
        textCenter: {
          fontSize: 14,
          alignment: 'center'
        },
        textCenterRed: {
          fontSize: 14,
          alignment: 'center',
          color: "#ff0000",
        },
        textCenterBoldRed: {
          fontSize: 16,
          alignment: 'center',
          color: "#ff0000",
          bold: true,
        },
        textBold: {
          fontSize: 16,
          bold: true,
        },
        textBoldRight: {
          fontSize: 16,
          bold: true,
          alignment: 'right'
        },
        textBoldCenter: {
          fontSize: 16,
          bold: true,
          alignment: 'center'
        },
      },
      defaultStyle: {
        font: 'THSarabunNew'
      }
    };

    pdfMake.createPdf(docDefinition).open()

  }

  onChangeParkLocation(carSelect, index, event) {

    var car_id = carSelect.car_id
    var park_location = event.target.value
    var car_list = this.state.car_list.map(car => car.car_id === car_id ? {...car, park_location: park_location, car_edit: 2 } : car )

    this.setState({
      car_list: car_list
    });

  }

  onChangePlateRed(carSelect, index, event) {

    let car_id = carSelect.car_id
    let plate_red = event.target.value
    let car_list = this.state.car_list.map(car => car.car_id === car_id ? {...car, plate_red: plate_red, car_edit: 2 } : car )

    this.setState({
      car_list: car_list
    });

  }

  onChangeTunedCar(carSelect, index, event) {

    let car_id = carSelect.car_id
    let tuned_car = event.target.value
    let car_list = this.state.car_list.map(car => car.car_id === car_id ? {...car, tuned_car: tuned_car, car_edit: 2 } : car )

    this.setState({
      car_list: car_list
    });

  }

  onClickSave(carSelect, index,) {
    let car_id = carSelect.car_id
    let plate_red = carSelect.plate_red
    let park_location = carSelect.park_location
    let tuned_car = carSelect.tuned_car

    //update to db
    let data = {
      plate_red: plate_red,
      park_location: park_location,
      tuned_car: tuned_car
    }

    let url = APIURL+'/car/park/'+car_id
    axios.put(url, data).then(res => {
      if(res.status === 200) {
        var car_list = this.state.car_list.map(car => car.car_id === car_id ? {...car, car_edit: 3 } : car )
        this.setState({
          car_list: car_list
        });
      }
    })
      .catch(error => {
        alert("พบข้อผิดพลาด : "+error)
      })

  }

  onCarParkSubmit() {
    const {
      car_parking_name,
      car_parking_order,
      actionType,
      car_parking_index,
      car_parking_list
    } = this.state

    if (car_parking_name === '' || car_parking_order === '' ) {
      alert('กรุณากรอกข้อมูลให้ครบถ้วน')
      return
    }

    const car_parking = car_parking_list[car_parking_index]

    let data = {
      car_parking_name,
      car_parking_order,
    }

    if (actionType === 'แก้ไข') {
      data = {...data, car_parking_id: car_parking.car_parking_id }
      let url = APIURL+'/car_parking/'+car_parking.car_parking_id
      axios.put(url, data).then(res => {
        let result = res.data
        if(result === 'success') {
          this.setState({
            car_parking_name: '',
            car_parking_order: '',
            actionType: 'บันทึก',
            car_parking_index: '',
          }, () => {
            this.loadCarParking();
            this.loadCarAll();
          })
        }
      })

    } else if (actionType === 'บันทึก') {
      let url = APIURL+'/car_parking'
      axios.post(url, data).then(res => {
        let result = res.data
        if(result === 'success') {
          this.loadCarParking()
        }
      })
    }

  }

  async exportCarInAllSummary(){
    // const car_status_id = this.state.car_status_id
    // ยังไม่ทำ ทำของ nk ก่อน
    this.setState({
      isLoading: true
    })

    // const data = {
    //   car_status_id: car_status_id,
    // }

    axios.post(APIURL + '/report/carparkingexcel', {})
      .then( async res => {
        const excelBuffer = res.data.excelBuffer
        const currentDatetime = res.data.currentDatetime
        const fileName = 'ข้อมูลรถในเต็นท์ ' + ' วันที่' + currentDatetime + '.xlsx'
        this.setState({
          isLoading: false
        })
        await save(excelBuffer, fileName)

      }).catch(error => {
        console.log('error:', error)
    })
  }

/* <option value="หน้าเต็นท์">หน้าเต็นท์ สาขา 1 </option> */
/* <option value="หลังเต็นท์">หลังเต็นท์ สาขา 2</option>  */

  render() {

    let width = this.state.width


    let car_parking_list = this.state.car_parking_list
    let car_parking_list_order = car_parking_list.sort((a, b) => (a.car_parking_order > b.car_parking_order) ? 1 : -1)


    let carParkingOptions = car_parking_list_order.map((parking, index) => {
      return <option value={parking.car_parking_name} key={index}> {parking.car_parking_name} </option>
    })

    let tbodyList = this.state.car_list.map((car, index) => {

       return (
         <tr key={index}>
           <td className="text-center">{index+1}</td>
           <td>
             <div>
               {car.car_name} {car.car_color_name}
               <br/>
               วันที่ซื้อรถเข้า: {moment(car.car_date_in).format('DD/MM/YYYY')}
             </div>
           </td>
           <td className="text-center">
             {car.car_license_plate_new !== '' ? (car.car_license_plate_new) : (car.car_license_plate_old)} <br/> {car.province_name_new}
           </td>
           <td className="text-center">
             <input type="text"
                    className="form-control text-center"
                    id="plate_red"
                    name="plate_red"
                    maxLength={10}
                    value={this.state.car_list[index].plate_red}
                    onChange={(event)=> this.onChangePlateRed(car, index, event)}
             />
           </td>
           <td className="text-center">
             <select
               className="form-control"
               value={this.state.car_list[index].park_location}
               onChange={(event) => this.onChangeParkLocation(car, index, event)}
             >
               {carParkingOptions}
             </select>
           </td>
           <td className="text-center">
             <select
               className="form-control"
               value={this.state.car_list[index].tuned_car}
               onChange={(event) => this.onChangeTunedCar(car, index, event)}
             >
               <option value="แต่ง">แต่ง</option>
               <option value="-">-</option>
             </select>
           </td>
           <td className="text-center  ">{ this.state.reserve_status.includes(car.car_status_id)? "จอง" : "-"}</td>
           <td className="text-center">
              <button
                className={car.car_edit === 1 ? "btn btn-sm btn-success" : (car.car_edit === 2) ? "btn btn-sm btn-warning" : "btn btn-sm " }
                disabled={car.car_edit===3}
                onClick={() => this.onClickSave(car, index)}
              >บันทึก</button>
           </td>
         </tr>
       )
     });

    let tbEmpty = (
       <tr><td colSpan={8} className="text-danger text-center">ไม่พบข้อมูลรถ</td></tr>
     )
    let tbBody = this.state.car_list.length===0 ? tbEmpty : tbodyList

    let carParkingBody = this.state.car_parking_list.map((parking, index) => {
      return <tr key={index}>
        <td>{parking.car_parking_name}</td>
        <td className="text-center">{parking.car_parking_order}</td>
        <td className="text-center">
          <button className="btn btn-sm btn-danger mr-2"
            onClick={() => this.onCarParkingDelete(index)}
          >
            <i className="fa fa-remove"/> ลบ
          </button>
          <button className="btn btn-sm btn-warning"
             onClick={() => this.onCarParkingEdit(index)}
          >
            <i className="fa fa-edit"/> แก้ไข
          </button>
        </td>
      </tr>
    })

    let  carParkingEmpty = (
      <tr><td colSpan={3} className="text-danger text-center">ไม่พบข้อมูล</td></tr>
    )

    let carParking = this.state.car_parking_list.length === 0 ? carParkingEmpty : carParkingBody



     return (
       <div className="card">
         <div className="card-block">

           <Modal className="modal-lg modal-dialog"
                  isOpen={this.state.toggle_car_parking}
                  toggle={this.toggleCarParking}
                  style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
           >
             <ModalBody>
               <div className="card card-accent-primary">
                 <div className="card-header">
                   <strong className="text-title">ข้อมูลรถจอด</strong>
                 </div>
                 <div className="card-block">
                   <div className="row">
                     <div className="form-group col-sm-4">
                       <label htmlFor="car_parking_name">ชื่อตำแหน่งจอด</label>
                       <input type="text"
                              className="form-control"
                              id="car_parking_name"
                              name="car_parking_name"
                              placeholder=""
                              value={this.state.car_parking_name || ''}
                              onChange={this.onInputChange}
                       />
                     </div>
                     <div className="form-group col-sm-3">
                       <label htmlFor="car_parking_order">ลำดับการแสดงผล</label>
                       <input
                              className="form-control"
                              id="car_parking_order"
                              name="car_parking_order"
                              type="number"
                              placeholder=""
                              value={this.state.car_parking_order}
                              onChange={this.onInputChange}
                       />
                     </div>
                     <div className="form-group col-sm-2">
                       <button type="submit"
                               className={this.state.actionType==="แก้ไข"?  "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}
                               style={{marginTop: '1.85rem'}}
                               onClick={this.onCarParkSubmit}
                       >
                         <i className={this.state.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/>
                         &nbsp; {this.state.actionType}
                       </button>
                     </div>
                   </div>

                   <div className="row">
                     <div className="form-group col-sm-12">
                          <table className="table">
                            <thead>
                            <tr>
                             <th>ชื่อตำแหน่งจอด</th>
                             <th className="text-center" >ลำดับการแสดงผล</th>
                             <th className="text-center">จัดการ</th>
                            </tr>
                            </thead>
                            <tbody>
                              {carParking}
                            </tbody>
                          </table>
                     </div>
                   </div>

                 </div>
                 <div className="card-footer text-right">
                   <button type="reset"
                           className="btn btn-sm btn-danger"
                           onClick={this.toggleCarParking}
                   >
                     <i className="fa fa-close"/> ยกเลิก
                   </button>
                  {/* <button type="submit" className={this.props.actionType==="แก้ไข"?  "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}>
                     <i className={this.props.actionType==="แก้ไข"? "fa fa-edit": "fa fa-save"}></i> {this.props.actionType}
                   </button>*/}
                 </div>
               </div>
             </ModalBody>
           </Modal>

           <div className="row">
             <div className="col-sm-12 col-md-12">
               <div className="card card-accent-info">
                 <div className="card-header">
                   <strong className="text-title"> ข้อมูลรถในเต็นท์ </strong>

                   {/*<button type="button"
                           className="btn btn-success float-right ml-2"
                           onClick={() => this.exportCarInAllSummary()}
                   >
                     Excel
                   </button>*/}

                   <button className="btn btn-primary float-right" onClick={this.printCarAll}>
                     <i className="icon-printer"/>&nbsp; พิมพ์
                   </button>

                   <button className="btn btn-secondary float-right mr-2" onClick={() => this.toggleCarParking()}>
                     <i className="icon-pencil"/>&nbsp; เพิ่มรถจอด
                   </button>


                 </div>
                 <div className="card-block">
                 <table className="table table-striped">
                   <thead>
                     <tr>
                       <th className="text-center">#</th>
                       <th className="text-center">ยี่ห้อ/รุ่น</th>
                       <th className="text-center">ทะเบียน</th>
                       <th className="text-center" style={{width: 120}}>ป้ายแดง</th>
                       <th className="text-center">รถจอด</th>
                       <th className="text-center" style={{width: 100}}>รถแต่ง</th>
                       <th className="text-center">รถจอง</th>
                       <th className="text-center">จัดการ</th>
                     </tr>
                   </thead>
                   <tbody>
                   {tbBody}
                   </tbody>
                 </table>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     )
   }
}

export default CarInAll;
