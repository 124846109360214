// CustomerFollowupList

import React , { Component } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import { save } from 'save-file';
import Loading from '../Loading';
import CustomerFollowupForm from './CustomerFollowupForm';
import { APIURL } from '../../config/config';
import 'react-vertical-timeline-component/style.min.css';
import { connect } from 'react-redux';
import { CustomerFollowupUtils } from './CustomerFollowupUtils';
import { checkGroupPermission, getGroupPermission } from '../../services/grouppermission';

class CustomerFollowupList extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isOpenFollowupForm: false,
      customer_id: null,
      followup_id: null,
      leads: [],
      followups: [],
      isLoading: false,
      customer: null,
      admin_followup_by: null,
      user: JSON.parse(this.props.user),
      menuID: [3502],
      grouppermissions: [],
    }
    this.loadCustomerLead = this.loadCustomerLead.bind(this);
    this.loadCustomerFollowUp = this.loadCustomerFollowUp.bind(this);
    this.loadGroupPermission = this.loadGroupPermission.bind(this);
    this.exportExcel = this.exportExcel.bind(this);
  }

  /*componentWillMount () {
    const queryString = require('query-string');
    let query = queryString.parse(this.props.location.search);
    console.log('---componentWillMount')
    console.log(query)
  }*/

  loadGroupPermission() {
    let type = this.state.user.type
    let menuID = this.state.menuID

    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    })
  }

  componentDidMount () {
    this.loadGroupPermission()
    try {
      const queryString = require('query-string');
      let query = queryString.parse(this.props.location.search);
      let followup_by = query.followup_by

      // กดดูรายละเอียด โดย admin
      this.setState({
        admin_followup_by: followup_by
      }, () => this.loadCustomerLead())
    } catch (e) {

      let username = this.state.user.user
      this.setState({
        admin_followup_by: username
      }, () => this.loadCustomerLead())

      //this.loadCustomerLead()
    }

  }

  loadCustomerLead() {
    let username = this.state.user.user
    let type = this.state.user.type
    let admin_followup_by = this.state.admin_followup_by

    // console.log(username, type, admin_followup_by)

    if(admin_followup_by !== null) {
      // console.log('admin load by user')
      let uri = APIURL+'/customer/followup/user/'+admin_followup_by;
      axios.get(uri).then(res => {
        // admin แสดง lead ของ user ทุกสถานะ
        this.setState({leads: res.data}, () => {
          if (res.data.length !== 0) {
            let customer = res.data[0]
            this.loadCustomerFollowUp(customer)
          }
        })
      })
    } else {
      if(type === 'admin') {
        // console.log('admin load all no select user')
        // for admin
        let uri = APIURL+'/customer/followup/admin';
        axios.get(uri).then(res => {
          this.setState({leads: res.data}, () => {
            if (res.data.length !== 0) {
              let customer = res.data[0]
              this.loadCustomerFollowUp(customer)
            }
          })
        });
      } else if(username !== undefined) {
        // console.log('user load : ', username)
        let uri = APIURL+'/customer/followup/user/'+username;
        axios.get(uri).then(res => {
          // show เฉพาะ lead ของ sale
          let customers = res.data.filter((customer) => customer.customer_type === 'lead')
          this.setState({leads: customers}, () => {
            if (customers.length !== 0) {
              let customer = customers[0]
              this.loadCustomerFollowUp(customer)
            }
          })
        })
      }

    }
  }

  loadCustomerFollowUp(customer) {
    let customer_id = customer.customer_id

    this.setState({
      isLoading: true
    })
    setTimeout(() => {
      let uri = APIURL+'/customer/followup/lead/'+customer_id;
      console.log(uri)
      axios.get(uri).then(res => {
        this.setState({
          customer: customer,
          followups: res.data,
          isLoading: false
        })
      })
    },1200)

  }

  exportExcel() {
    let username = this.state.user.user
    let admin_followup_by = this.state.admin_followup_by

    if(admin_followup_by !== null) {
      username = admin_followup_by
    }

    // console.log('export by user: ', username)
    let data = {
      username
    }

    this.setState({
      isLoading: true
    })

    let uri = APIURL+'/customer/followup/excel'
    axios.post(uri, data).then(async res => {
      // console.log(res.data)
      const excelBuffer = res.data.excelBuffer
      const currentDatetime = res.data.currentDatetime
      const fileName = 'รายการติดตามลูกค้า_export_'+currentDatetime+'.xlsx'
      this.setState({
        isLoading: false
      })
      await save(excelBuffer, fileName)

    }).catch(err => {
      console.log(err)
    })

  }

  render () {
    let user = this.state.user
    const {
      isOpenFollowupForm,
      customer_id,
      followup_id,
      leads,
      followups,
      customer
    } = this.state


    //  {/*  <i className="fa fa-cogs bg-primary p-3 font-2xl mr-3 float-left"></i>*/}
    let card_leads = leads.map((lead, index) => (
      <div  key={index} className="card">
        <div className="card-header font-weight-bold">
          <div className="row">
         <div className="col-8"> {lead.customer_name}{' '}{lead.customer_lastname} โทร.{lead.customer_mobile} </div>
         <div className="col-4 text-right">
           <span className={CustomerFollowupUtils.getClassBadge(lead.customer_type)} >
             {CustomerFollowupUtils.getFollowupStatusName(lead.customer_type)}
           </span>
         </div>
          </div>
        </div>
        <div className="card-block p-3 clearfix">
          <div className="h6 text-primary mb-0 mt-2">
            รายละเอียดลูกค้า : {lead.customer_note}
          </div>
          <div className="h6 text-muted text-uppercase font-weight-bold text-success mt-2">
            รถที่สนใจ : {lead.car_type_interest}
             &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
            ลูกค้าจาก : {lead.profile_from === null ? '-' : lead.profile_from}
          </div>
          <div className="text-muted text-uppercase font-weight-bold text-danger mt-2">
            ระดับความต้องการ : {lead.walkin_level === null || lead.walkin_level === '' ? '-' : lead.walkin_level}
          </div>
          <div className="text-muted text-uppercase font-weight-bold mt-2">
            เพิ่มโดย : {lead.user_updated}
            &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
            ติดตามโดย : <span className='badge badge-info'> {lead.followup_by} </span>
          </div>

          <div className="text-muted text-uppercase font-weight-bold mt-2">
            เมื่อ : {format(new Date(lead.updated_at), 'dd/MM/yyyy HH:mm:ss')}
          </div>

        </div>
        <div className="card-footer px-3 py-2">
          <a className="font-weight-bold font-xs btn-block text-muted"
             href="#" onClick={(e) => {
               e.preventDefault();
               this.loadCustomerFollowUp(lead)
           }}
          >
            รายละเอียดการติดตาม <i className="fa fa-angle-right float-right font-lg"></i>
          </a>
        </div>
      </div>
    ));

    // vertical-timeline-element-title
    // icon={<i className="fa fa-cogs bg-primary p-3 font-2xl mr-3 float-left" />}
    // background: 'rgb(33, 150, 243)'
    // date={followup.followup_times}
    let timeline_followups = followups.map((followup, index) => {
      let count = followups.length
      let times = count - (index)
      // <h6 className="vertical-timeline-element-subtitle">ครั้งที่ : {followup.followup_times}</h6>
      return <VerticalTimelineElement
        key={index}
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}


      >
        <h6 className="vertical-timeline-element-subtitle">ครั้งที่ : {times}</h6>
        <h6 className="vertical-timeline-element-subtitle  mt-1">วันที่นัดหมาย: {format(new Date(followup.followup_date), 'dd/MM/yyyy')}</h6>
        <h6 className="vertical-timeline-element-subtitle mt-1">วันที่ติดต่อ: {format(new Date(followup.contact_date), 'dd/MM/yyyy')}</h6>
        <hr/>
        <div>
            รายละเอียดการติดต่อ: <br/>
            {followup.followup_detail}
        </div>
        <div className="mt-2">
          หัวข้อการติดตามครั้งถัดไป: <br/>
          {followup.followup_next}
        </div>
        <div className="text-muted text-uppercase font-weight-bold mt-2">
          โดย : {followup.user_updated} <br />
          เมื่อ : {format(new Date(followup.updated_at), 'dd/MM/yyyy HH:mm:ss')}
        </div>
      </VerticalTimelineElement>
    });

    let customer_info = customer !== null ? customer.customer_name+' '+ customer.customer_lastname+' โทร.'+customer.customer_mobile : '-'

    return (
      <div className="row">

        {
          isOpenFollowupForm && <CustomerFollowupForm
            user={user}
            admin_followup_by={this.state.admin_followup_by}
            customer_id={customer_id}
            followup_id={followup_id}
            isOpen={isOpenFollowupForm}
            onToggle={(is_reload) => {

              if(is_reload) {
                this.setState({
                  isOpenFollowupForm: !this.state.isOpenFollowupForm,
                  customer_id: null,
                  followup_id: null,
                  leads: [],
                  followups: [],
                  customer: null
                }, () =>{
                  this.loadCustomerLead()
                  if (this.state.admin_followup_by === null) {
                    this.props.onCalendarReload(is_reload)
                  }

                })
              } else {
                this.setState({
                  isOpenFollowupForm: !this.state.isOpenFollowupForm,
                  customer_id: null,
                  followup_id: null,
                })
              }
            }}
          />
        }

        <Loading isLoading={this.state.isLoading} />

        <div className="col-sm-12 col-md-12">
          <div className="card card-accent-info">
            <div className="card-header">
              <div className="row">
                <div className="col-6 ">
                  <strong> ข้อมูลการติดตาม </strong>
                </div>
                <div className="col-6 text-right">

                  {
                    checkGroupPermission(3502, this.state.grouppermissions).printed ? (
                      <button className="btn btn-success mr-2"
                              style={{
                                height: 38,
                                paddingTop:0,
                                paddingBottom:0,
                                display: 'inline',
                                marginTop: 0
                              }}
                              onClick={() => {
                                this.exportExcel()
                              }}
                      >
                        <i className="icon-paper-clip"/> &nbsp; Excel  &nbsp; &nbsp;
                      </button>
                    ) : null
                  }

                  {
                    checkGroupPermission(3502, this.state.grouppermissions).created ? (
                      <button className="btn btn-primary"
                              style={{
                                height: 38,
                                paddingTop:0,
                                paddingBottom:0,
                                display: 'inline',
                                marginTop: 0
                              }}
                              onClick={() => {
                                this.setState({
                                  isOpenFollowupForm: true
                                })
                              }}
                      >
                        <i className="icon-plus"/> &nbsp; เพิ่มการติดตามใหม่
                      </button>
                    ) : null
                  }

                </div>
              </div>
            </div>
            <div className="card-block">
              <div className="row">
                <div className="col-5">
                  <div className="card card-accent-primary">
                    <div className="card-header ">
                      ลูกค้า (Lead) / ผู้สนใจ
                    </div>
                    <div className="card-block">
                      {card_leads}
                    </div>
                  </div>
                </div>
                <div className="col-7">
                  <div className="card card-accent-success">
                    <div className="card-header ">
                      รายละเอียดการติดตาม
                    </div>
                    <div className="card-block">
                      <div className="row">
                        <div className="col-8">
                           ลูกค้า(Lead) : {customer_info}
                        </div>
                        <div className="col-4">
                          {
                            customer !== null && checkGroupPermission(3502, this.state.grouppermissions).modified ?
                              <button className="btn btn-primary"
                                      style={{
                                        height: 38,
                                        paddingTop:0,
                                        paddingBottom:0,
                                        display: 'inline',
                                        marginTop: 0
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          isOpenFollowupForm: true,
                                          customer_id: customer.customer_id,
                                          followup_id: null,
                                        })
                                      }}
                              >
                                <i className="icon-plus"></i> &nbsp; เพิ่มการติดตาม {customer.customer_name}
                              </button>
                              : null
                          }

                        </div>
                      </div>
                      <div className="row col-12">
                        <VerticalTimeline
                          layout={'2-columns'}
                          lineColor={'rgb(33, 150, 243)'}
                        >
                           {timeline_followups}
                        </VerticalTimeline>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CustomerFollowupList)
