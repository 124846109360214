import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {ExcelRenderer} from 'react-excel-renderer';
import {AlertWarning} from "../Alert/Alert";
import {APIURL, PORT} from "../../config/config";
import uniq from 'lodash/uniq';
import {format, isValid} from 'date-fns'
import stringUtil from "../../utils/stringUtil";
import { da } from 'date-fns/locale';
import {DateUtil} from "../../utils/dateUtil";


const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

class ImportExcel extends Component{

  constructor (props) {
    super(props)
    this.state = {
      user: {},
      excelFile: '',
      attachFiles: false,
      isUpload: false,
      car_years: [],
      car_brands: [],
      car_models: [],
      provinces: [],
      car_colors: [],
      car_status: [],
      imageFile: null,
      carData: [],
      // for car repair
      cars: [],
      centers: [],
      repairData: [],
      branch: [],
      car_types: [],
      car_structure: [],
      car_type_engine:[],
      liciense_status : [],
    }

    this._clickUpload = this._clickUpload.bind(this)

    this._checkCarBrand = this._checkCarBrand.bind(this)
    this._checkCarYear = this._checkCarYear.bind(this)
    this._checkCarModel = this._checkCarModel.bind(this)
    this._checkProvinces = this._checkProvinces.bind(this)
    this._convertDateToDb = this._convertDateToDb.bind(this)
    this.loadCarBrandData = this.loadCarBrandData.bind(this)
    this.loadCarModelData = this.loadCarModelData.bind(this)
    this.loadCarYearData = this.loadCarYearData.bind(this)
    this.loadProvinceData = this.loadProvinceData.bind(this)
    this.loadCarColorData = this.loadCarColorData.bind(this)
    this.loadCarStatusData = this.loadCarStatusData.bind(this)
    this.loadCarCenterData = this.loadCarCenterData.bind(this)
    this.loadBranchData = this.loadBranchData.bind(this)

    this.addCarExpense = this.addCarExpense.bind(this)
    this._changeCarRepairFile = this._changeCarRepairFile.bind(this)
    this._clickUploadCarRepair = this._clickUploadCarRepair.bind(this)
    this._checkCenter = this._checkCenter.bind(this)
    this._checkCar = this._checkCar.bind(this)
    this.getJsDateFromExcel = this.getJsDateFromExcel.bind(this)

    this._changeFile = this._changeFile.bind(this)
    this._changeFileCarOk = this._changeFileCarOk.bind(this)
    this.loadCarType = this.loadCarType.bind(this)
    this.loadCarStructureData = this.loadCarStructureData.bind(this)
    this.loadCarTypeEngineData = this.loadCarTypeEngineData.bind(this)
    this.loadLicienseStatus = this.loadLicienseStatus.bind(this)

    this.loadCarSubModelDataByCarModelId = this.loadCarSubModelDataByCarModelId.bind(this)
  }

  componentDidMount () {
    this.loadCarBrandData()
    this.loadCarYearData()
    this.loadCarModelData()
    this.loadProvinceData()
    this.loadCarColorData()
    this.loadCarStatusData()
    this.loadCarCenterData();
    this.loadBranchData();
    this.loadCarType();
    this.loadCarStructureData();
    this.loadCarTypeEngineData();
    this.loadLicienseStatus();

    this.loadCarData();

  }

  componentWillMount() {
    this.setState({
      user: JSON.parse(this.props.user),
    })
  }

  async loadCarSubModelDataByCarModelId(car_model_id){

   let car_sub_models =  await axios.get(APIURL + '/car_sub_model/' + car_model_id)
    // console.log(car_sub_models)
    let data = car_sub_models.data

    let noset = {
      "car_sub_model_id": 0,
      "car_sub_model_name": "ไม่ระบุ",
      "car_model_id": 0,
      "status": "show"
    }
    data.push(noset)

    return data
      //   .then(res => {
      //     this.setState({car_sub_models: res.data})
      //   }).catch(error => {
      // this.setState({car_sub_models: []})
    // })

  }

  loadLicienseStatus() {
    axios.get(APIURL + '/liciense_status/')
        .then(res => {
          this.setState({liciense_status: res.data})
        }).catch(error => {
      this.setState({liciense_status: []})
    })
  }

  loadCarTypeEngineData () {
    axios.get(APIURL + '/car_type_engine/')
        .then(res => {
          this.setState({car_type_engine: res.data})
        }).catch(error => {
      this.setState({car_type_engine: []})
    })
  }

  loadCarStructureData () {
    axios.get(APIURL + '/car_structure/')
        .then(res => {
          this.setState({car_structure: res.data})
        }).catch(error => {
      this.setState({car_structure: []})
    })
  }

  loadCarType() {
    let url = APIURL+'/car_type/admin'
    axios.get(url).then(res => {
      // console.log(res.data)
      this.setState({
        car_types: res.data
      })
    })
  }

  loadBranchData () {
    axios.get(APIURL + '/branch/')
        .then(res => {
          console.log(res.data)
          this.setState({branch: res.data})
        }).catch(error => {
      this.setState({branch: []})
    })
  }

  loadCarCenterData() {
    axios.get(APIURL + '/center/')
        .then(res => {
          this.setState({centers: res.data})
        }).catch(error => {
      this.setState({centers: []})
    })
  }

  loadCarData() {
    axios.get(APIURL + '/car/')
        .then(res => {
          this.setState({cars: res.data})
        }).catch(error => {
      this.setState({cars: []})
    })
  }

  loadCarStatusData () {
    axios.get(APIURL + '/car_status/new')
        .then(res => {
          this.setState({car_status: res.data})
        }).catch(error => {
      this.setState({car_status: []})
    })
  }

  loadCarBrandData () {
    axios.get(APIURL + '/car_brand/')
        .then(res => {
          this.setState({car_brands: res.data})
        }).catch(error => {
      this.setState({car_brands: []})
    })
  }

  loadCarModelData() {
    axios.get(APIURL + '/car_brand_model/')
        .then(res => {
          this.setState({car_models: res.data})
        }).catch(error => {
      this.setState({car_models: []})
    })
  }

  loadCarYearData () {
    axios.get(APIURL + '/car_year/')
        .then(res => {
          this.setState({car_years: res.data})
        }).catch(error => {
      this.setState({car_years: []})
    })
  }

  loadProvinceData () {
    axios.get(APIURL + '/province/')
        .then(res => {
          this.setState({provinces: res.data})
        }).catch(error => {
      this.setState({provinces: []})
    })
  }

  loadCarColorData () {
    axios.get(APIURL + '/car_color/')
        .then(res => {
          this.setState({car_colors: res.data})
        }).catch(error => {
      this.setState({car_colors: []})
    })
  }

  async _changeFileCarOk(e) {
    if (e.target.files[0]) {
      this.setState({
        attachFiles: true,
      });

      const {
        car_years,
        car_brands,
        car_models,
        provinces,
        car_colors,
        car_status,
        branch,
        car_types,
        car_structure,
        car_type_engine,
        liciense_status
      } = this.state

      let fileObj = e.target.files[0];
      ExcelRenderer(fileObj, async (err, res) => {
        if(err) {
          console.log('error:', err)
        }else{
          const data = res.rows;
          const rows = data.slice(2);

          // console.log(rows)
          // return

          let carData = []
          for(let i=0; i< rows.length;i++) {
            if (i === 186) {
              break
            }
            let row = rows[i]
            // console.log('row=', row.length)
            let car_id = 4 + i
            let car_owner_old_order = row[0];
            let car_name_owner_old = row[1];
            let car_lastname_owner_old = row[2] === 'empty' ? '' : row[2] ;
            let car_id_card = row[3] === 'empty' ? '' : row[3] ;
            let car_telephone_old = row[4] === 'empty' ? '' : row[4];
            let car_address_old = row[5] === 'empty' ? '' : row[5];
            let car_license_plate_old = row[6];

            console.log(i,' => province_name_old=', row[7])
            let province_old = row[7] === 'empty' ? '--ไม่ระบุ--' : row[7];
            let province_old_id = provinces.filter(province=> {
              // console.log(i,' => province_name=', province.province_name.trim(), String(row[7]).trim(), province.province_name.trim()===String(row[7]).trim())
              return province.province_name.trim()===String(province_old).trim()
            })[0].province_id;
            let branch_id =  branch.filter(b=> b.branch_name.trim()===String(row[8]).trim())[0].branch_id;
            // console.log('province_old_id=', province_old_id)
            // console.log('branch_id=', branch_id)

            let car_date_in = DateUtil.convertDateThaiToDB(this.getJsDateFromExcel(row[9]));
            // console.log(i,' => car_date_in: ', this.getJsDateFromExcel(row[9]), ' ==> ' , car_date_in)

            let buy_car_from = row[10];
            let park_location = row[11];
            let car_grade = row[12] === 'empty' ? '' : row[12];
            // let code_item = row[13];
            let code_item = ''; // ยังไม่ต้อง add
            let car_brand_id =  car_brands.filter(car_brand=> car_brand.car_brand_name.trim()===String(row[14]).trim())[0].car_brand_id;

            // console.log(i, 'car_brand_id=', car_brand_id)
            //console.log(i, 'car_model_name=', row[15])
            let car_model_id = car_models.filter(car_model=> car_model.car_model_name.trim()===String(row[15]).trim())[0].car_model_id;
            // console.log(i, 'car_model_id=', car_model_id)
            // car_sub_model_id car_sub_model_name car_model_id
            let car_sub_models = await this.loadCarSubModelDataByCarModelId(car_model_id)
            // console.log(car_sub_models)
            // console.log('--- car_sub_models --- car_model_id:', car_model_id)
            // console.log(car_sub_models)
            // console.log(i, 'car_sub_model_name=', car_model_id, row[16])
            let car_name = row[14]+' '+row[16]

            // console.log(i, 'car_sub_model_name=', String(row[16]).trim())

            let car_sub_model_id = car_sub_models.filter(car_sub_model=> car_sub_model.car_sub_model_name.trim()===String(row[16]).trim())[0].car_sub_model_id;
            // console.log(i, 'car_sub_model_id=', car_sub_model_id)

            let car_gear_id = row[17] === 'เกียร์ธรรมดา' ? 'm' : (row[17] === 'เกียร์ออโต้' ? 'a': '-');
            // console.log(i, 'car_gear_id=', car_gear_id)
            let car_color_id = car_colors.filter(car_color=> car_color.car_color_name.trim()===String(row[18]).trim())[0].car_color_id;
            // console.log(i, 'car_color_id=', car_color_id)
            // console.log(i, 'car_year_name=', row[19])

            let car_year_id = car_years.filter(car_year=> car_year.car_year_name.trim()===String(row[19]).trim())[0].car_year_id;
            let car_license_plate_new = row[20] === 'empty' ? '-' : row[20];
            let province_new = row[21] === 'empty' ? '--ไม่ระบุ--' : row[21];
            console.log(i, 'car_license_plate_new=',car_license_plate_new )
            // console.log(i, 'province_new=',province_new, row[21] )

            let car_detail = car_name+' ปี '+row[19]+' สี'+row[18]

            let province_new_id = provinces.filter(province=> province.province_name.trim()===String(province_new).trim())[0].province_id;

            let car_number = row[22];
            let car_num_engines = row[23];
            let car_date_regis = DateUtil.convertDateThaiToDB(this.getJsDateFromExcel(row[24]));
            // console.log(i, 'province_new_id=', province_new_id)
            // console.log(i, 'car_date_regis=', car_date_regis)

            let car_type_id = car_types.filter(car_type => car_type.car_type_name.trim()===String(row[25]).trim())[0].car_type_id;

            // console.log(i, 'car_structure_name=', row[26])
            let car_structure_id = car_structure.filter(cs => cs.car_structure_name.trim()===String(row[26]).trim())[0].car_structure_id;

            // console.log(i, 'car_structure_id=', car_structure_id)

            // ประเภทเครืองยนต์  car_type_engine_id car_type_engine_name
            let car_type_engine_id = car_type_engine.filter(cs => cs.car_type_engine_name.trim()===String(row[27]).trim())[0].car_type_engine_id;
            let car_weight = row[28] === 'empty' ? '' : row[28];
            let car_size_engine = row[29];
            //console.log(i, 'car_date_tax=', row[30])
            let car_date_tax = row[30] === 'null' ? null : DateUtil.convertDateThaiToDB(this.getJsDateFromExcel(row[30]));
            //console.log(i, 'car_date_tax=', car_date_tax)

            let car_miles =  row[31] === 'empty' ? '' : row[31];

            //  liciense_status_id liciense_status_name
            let liciense_status_id = liciense_status.filter(cs => cs.liciense_status_name.trim()===String(row[32]).trim())[0].liciense_status_id;
            // console.log(i, ' => car_name_owner_old=', car_name_owner_old, liciense_status_id)
            //console.log(i, 'liciense_status_id=', liciense_status_id)

            let car_upload_code = stringUtil.getCarUploadCode();
            let book_status_text = row[32]

            console.log('car_license_plate_old=', car_license_plate_old)
            let data = {
              car_id : car_id ,
              car_name,
              car_detail,
              branch_id,
              car_brand_id : car_brand_id ,
              car_model_id : car_model_id ,
              car_sub_model_id : car_sub_model_id ,
              car_gear_id : car_gear_id ,
              car_year_id : car_year_id ,
              car_license_plate_old : car_license_plate_old ,
              province_old_id : province_old_id ,
              car_license_plate_new : car_license_plate_new ,
              province_new_id : province_new_id ,
              car_color_id : car_color_id ,
              car_name_owner_old : car_name_owner_old ,
              car_date_regis : car_date_regis ,
              car_num_engines : car_num_engines ,
              car_number : car_number ,
              car_date_in : car_date_in ,
              buy_car_from : buy_car_from ,
              car_miles : car_miles ,
              car_grade : car_grade ,
              park_location: park_location,
              vat_buy : 0 ,
              car_cost : 0 ,
              car_explain : '' ,
              car_status_id : 6 ,
              car_date_tax : car_date_tax ,
              car_cost_tax : 0 ,
              compulsory_insurance_end_date : null ,
              car_cost_act : 0 ,
              receipt_date : null ,
              cost_bangkhab_xon_lan : 0 ,
              car_upload_code: car_upload_code,
              person_vat_id: 0,
              car_cost_repair: 0,
              car_type_id: car_type_id,
              car_structure_id: car_structure_id,
              car_hassock_id: 0,
              car_system_drive_id: 0,
              car_type_engine_id: car_type_engine_id,
              car_brand_engine: 0,
              car_gas_number: 0,
              car_weight: car_weight,
              car_size_engine: car_size_engine,
              car_tax: 0,
              car_sale_price: 0,
              car_cost_commissions: 0,
              car_cost_expenses_other: 0,
              car_cost_recipe_other: 0,
              car_net_profit: 0,
              liciense_status_id: liciense_status_id,
              book_status_text: book_status_text,
              finance_id: 0,
              floorplan_credit: 0,
              finance_floorplan_id: 0,
              insurance_type_id: 0,
              insurance_company_id: 0,
              floorplan_start_date: null,
              floorplan_due_date: null,
              floorplan_annual_interest: 0,
              deposit_registration_id: 0,
              insurance_end_date : null,
              compulsory_insurance : '',
              compulsory_insurance_company : '',
              car_spare_key : '',
              car_manual : '',
              web_info: '',
              code_item : code_item,
              car_feature_other: '',
              cost_operation : 0,
              cost_service : 0,
              cost_transport : 0,
              cost_porobor_lan : 0,
              cost_bangkhab_phasi_lan : 0,
              cost_check_ton : 0,
              car_miles_center : '' ,
              car_miles_center_date : null ,
              car_miles_status : '' ,
              car_book_service : '' ,
              car_owner_old_order : car_owner_old_order,
              warantee_date_end : null,
              car_lastname_owner_old: car_lastname_owner_old,
              car_other: '',
              user_created: 'bosscar',
              user_updated: 'bosscar',
              car_telephone_old: car_telephone_old,
              car_address_old: car_address_old,
              car_id_card: car_id_card
            }
            carData.push(data)

          }

          console.log(carData)
          this.setState({
            carData: carData
          })

        }
      })

    }
  }

  _changeFile(e) {
    if (e.target.files[0]) {
      // console.log('attachFiles==', e.target.files[0])
      this.setState({
        attachFiles: true,
      })

      const {
        car_years,
        car_brands,
        car_models,
        provinces,
        car_colors,
        car_status
      } = this.state


      let fileObj = e.target.files[0];
      ExcelRenderer(fileObj, async (err, res) => {
         if(err) {
           console.log('error:', err)
         }else{
           const data = res.rows;
           const rows = data.slice(1);
           // this._checkMinCol(rows);
           // this._checkCarYear(rows);
           // this._checkCarBrand(rows);
           // this._checkCarModel(rows);
           // this._checkProvinces(rows, 6);
           // this._checkProvinces(rows, 8);
           // this._checkCarColor(rows);
           // 11 car_date_regis 09/01/2019
           //console.log('disable upload car')
           //return

           let carData = []
           for(let i=0; i< rows.length;i++) {
             let row = rows[i]

             let car_id = row[0];
             let car_name = row[1]+' '+row[2]
             let car_detail = row[1]+' '+row[2]+' ปี '+row[4]+' สี '+row[9]
             let branch_id = 1
             let car_brand_id = car_brands.filter(car_brand=> car_brand.car_brand_name.trim()===String(row[1]).trim())[0].car_brand_id;
             let car_model_id = car_models.filter(car_model=> car_model.car_model_name.trim()===String(row[2]).trim())[0].car_model_id;
             let car_gear_id = row[3];
             let car_year_id = car_years.filter(car_year=> car_year.car_year_name.trim()===String(row[4]).trim())[0].car_year_id;
             let car_license_plate_old = row[5];
             let province_old_id = provinces.filter(province=> province.province_name.trim()===String(row[6]).trim())[0].province_id;
             let car_license_plate_new = row[7] ==='NULL' ? '' : row[7];

             let province_new = row[8] ==='NULL' ? 'ไม่ระบุ' : row[8];
             let province_new_id =  provinces.filter(province=> province.province_name.trim()===String(province_new).trim())[0].province_id;
             let car_color_id = car_colors.filter(car_color=> car_color.car_color_name.trim()===String(row[9]).trim())[0].car_color_id;
             let car_name_owner_old = row[10] === 'NULL' ? '' : row[10];
             let car_date_regis = this.getJsDateFromExcel(row[11]);
             
             let car_num_engines = row[12] ==='NULL' ? '' : row[12];
             let car_number = row[13];
             let car_date_in = this.getJsDateFromExcel(row[14]);
             let buy_car_from = row[15];
             let car_miles = row[16];
             let car_grade = row[17] === 'empty' ? '': row[17] ;
             let vat_buy = row[18];
             let car_cost = row[19];

             let car_explain1 = row[20] === 'NULL' ? '': row[20];
             let car_explain2 = row[28]  === 'empty' ? '': ' ผู้รับงาน '+row[28];
             let car_explain = car_explain1+' '+car_explain2;

             let car_status_id = car_status.filter(status => status.car_status_name.trim()===String(row[21]).trim())[0].car_status_id
             let car_date_tax = this.getJsDateFromExcel(row[22]);
             let car_cost_tax = row[23];
             let compulsory_insurance_end_date = this.getJsDateFromExcel(row[24]);
             let car_cost_act = row[25];
             // ตรอ
             let car_repair1 = row[26] === 'NULL' ? null : row[26];
             // แบตเตอรี่
             let car_repair2 = row[27] === 'NULL' ? null : row[27];
             let car_cost_expenses_other = ( row[26] === 'NULL' ? 0 : Number(row[26]) ) + ( row[27] === 'NULL' ? 0 : Number(row[27]) )

             let receipt_date = this.getJsDateFromExcel(row[29]);
             let cost_bangkhab_xon_lan = row[30];

             let car_upload_code = stringUtil.getCarUploadCode();

             let person_vat_id = 0
             if(car_cost > 0 && vat_buy === 0){
               // 1 = รถบ้าน
               person_vat_id = 1
             }else if (car_cost > 0 && (vat_buy > 0)){
               // 2 = รถ VAT
               person_vat_id = 2
             }else if (car_cost === 0  && vat_buy === 0) {
               // 3 = รถฝากขาย
               if (PORT === 9048){
                 person_vat_id = 3
               }else {
                 person_vat_id = 0
               }
             }

             let data = {
               car_id : car_id ,
               car_name,
               car_detail,
               branch_id,
               car_brand_id : car_brand_id ,
               car_model_id : car_model_id ,
               car_gear_id : car_gear_id ,
               car_year_id : car_year_id ,
               car_license_plate_old : car_license_plate_old ,
               province_old_id : province_old_id ,
               car_license_plate_new : car_license_plate_new ,
               province_new_id : province_new_id ,
               car_color_id : car_color_id ,
               car_name_owner_old : car_name_owner_old ,
               car_date_regis : car_date_regis ,
               car_num_engines : car_num_engines ,
               car_number : car_number ,
               car_date_in : car_date_in ,
               buy_car_from : buy_car_from ,
               car_miles : car_miles ,
               car_grade : car_grade ,
               vat_buy : vat_buy ,
               car_cost : car_cost ,
               car_explain : car_explain ,
               car_status_id : car_status_id ,
               car_date_tax : car_date_tax ,
               car_cost_tax : car_cost_tax ,
               compulsory_insurance_end_date : compulsory_insurance_end_date ,
               car_cost_act : car_cost_act ,
               car_repair1 : car_repair1 ,
               car_repair2 : car_repair2 ,
               receipt_date : receipt_date ,
               cost_bangkhab_xon_lan : cost_bangkhab_xon_lan ,
               car_upload_code: car_upload_code,
               person_vat_id: person_vat_id,
               car_cost_repair: 0,
               car_sub_model_id: 0,
               car_type_id: 0,
               car_structure_id: 0,
               car_hassock_id: 0,
               car_system_drive_id: 0,
               car_type_engine_id: 0,
               car_brand_engine: 0,
               car_gas_number: 0,
               car_weight: 0,
               car_size_engine: 0,
               car_tax: 0,
               car_sale_price: 0,
               car_cost_commissions: 0,
               car_cost_expenses_other: car_cost_expenses_other,
               car_cost_recipe_other: 0,
               car_net_profit: 0,
               liciense_status_id: 0,
               finance_id: 0,
               floorplan_credit: 0,
               finance_floorplan_id: 0,
               insurance_type_id: 0,
               insurance_company_id: 0,
               floorplan_start_date: null,
               floorplan_due_date: null,
               floorplan_annual_interest: 0,
               deposit_registration_id: 0,
               insurance_end_date : null,
               compulsory_insurance : '',
               compulsory_insurance_company : '',
               car_spare_key : '',
               car_manual : '',
               park_location : '',
               web_info: '',
               code_item : '',
               car_feature_other: '',
               cost_operation : 0,
               cost_service : 0,
               cost_transport : 0,
               cost_porobor_lan : 0,
               cost_bangkhab_phasi_lan : 0,
               cost_check_ton : 0,
               car_miles_center : '' ,
               car_miles_center_date : null ,
               car_miles_status : '' ,
               car_book_service : '' ,
               car_owner_old_order : 0,
               warantee_date_end : null,
               car_lastname_owner_old: '',
               car_other: '',
               user_created: 'bosscar',
               user_updated: 'bosscar',
               car_telephone_old: '',
               car_address_old: '',
               car_id_card: ''
             }
             carData.push(data)
             
           }

           // console.log(carData)
           this.setState({
             carData: carData
           })

           // console.log(rows.length)
           // console.log(rows[0])
           // console.log(rows[1])
           // console.log(rows[2])
           // console.log(rows[3])
         }
      });

    }
  }

  getJsDateFromExcel(excelDate) {
    // return new Date((excelDate - (25567 + 1)) * 86400 * 1000);
    // 26/06/2019 21/10/2023 => 2019-06-26 2023-10-21
    // console.log('excelDate=', excelDate, typeof excelDate)

    if(typeof excelDate === 'number') {
      try{
        let newDate = new Date((excelDate - (25567+2)) * 86400 * 1000);
        if (isValid(newDate)) {
          return  format(newDate, 'yyyy-MM-dd');
        }
        return null;
      }catch (e) {
        return null;
      }
    } else {
      return this._convertDateToDb(excelDate)
    }
  }

  _convertDateToDb(excel_date) {
    // 09/01/2019 => 2019-01-09
    if(excel_date==='NULL') {
      return null
    }

    let arrDate = excel_date.split('/')
    let dd = arrDate[0]
    let mm = arrDate[1]
    let yyyy = arrDate[2]
    return  yyyy+'-'+mm+'-'+dd
  }

  _checkMinCol(rows) {
    let min
    for(let i=0; i < rows.length ; i++) {
      let row = rows[i]
      let len = row.length
      if (i===0) {
        min = len
      } else {
        if (len < min) {
          min = len
        }
      }
    }
  }

  _checkCarYear(rows) {
    let car_years = this.state.car_years
    let car_year_not_exits = []
    for(let i=0; i < rows.length ; i++) {
      let car_year_name = String(rows[i][4]);
      let check = car_years.filter(car_year => car_year.car_year_name === car_year_name)
      if (check.length === 1) {
        continue
      }
      car_year_not_exits.push(car_year_name)
    }
  }

  _checkCarBrand(rows) {
    let car_brands = this.state.car_brands
    let car_brand_not_exits = []
    for(let i=0; i < rows.length ; i++) {
      let car_brand_name = String(rows[i][1]);
      let check = car_brands.filter(car_brand => {
        return car_brand.car_brand_name.trim() === car_brand_name.trim()
      });

      if (check.length === 1) {
        continue
      }
      car_brand_not_exits.push(car_brand_name)
    }
    let car_brand_uniq = uniq(car_brand_not_exits)
    console.log('car_brand_uniq', car_brand_uniq)
  }

  _checkCarModel(rows) {
    let car_models = this.state.car_models
    let car_model_not_exits = []
    for(let i=0; i < rows.length ; i++) {
      let car_model_name = String(rows[i][2]);
      let check = car_models.filter(car_model => {
        return car_model.car_model_name.trim() === car_model_name.trim()
      });

      if (check.length === 1) {
        continue
      }
      car_model_not_exits.push(car_model_name)
    }
    let car_model_uniq = uniq(car_model_not_exits)
    console.log('car_model_uniq', car_model_uniq)
  }

  _checkProvinces(rows, index) {
    let provinces = this.state.provinces
    let provinces_not_exits = []
    for(let i=0; i < rows.length ; i++) {
      let province_name = String(rows[i][index]);
      let check = provinces.filter(province => {
        return province.province_name.trim() === province_name.trim()
      });

      if (check.length === 1) {
        continue
      }
      provinces_not_exits.push(province_name)
    }
    let provinces_uniq = uniq(provinces_not_exits)
    // console.log(provinces_uniq)
  }

  _checkCarColor(rows) {
    let car_colors = this.state.car_colors
    let car_color_not_exits = []
    for(let i=0; i < rows.length ; i++) {
      let car_color_name = String(rows[i][9]);
      let check = car_colors.filter(car_color => car_color.car_color_name.trim() === car_color_name.trim())
      if (check.length === 1) {
        continue
      }
      car_color_not_exits.push(car_color_name)
    }
    let car_color_uniq = uniq(car_color_not_exits)
    console.log('car_color_uniq', car_color_uniq)
  }

  _clickUpload() {
    //const tabName = this.state.tabName
    const attachFiles = this.state.attachFiles
    const imageFile = this.state.imageFile
    const carData = this.state.carData

    if(imageFile === null) {
      AlertWarning('กรุณาเลือกไฟล์ ภาพปกรถเริ่มต้น')
      return
    }

    if(!attachFiles) {
      AlertWarning('กรุณาเลือกไฟล์ Excel')
      return
    }

    let input_file_name = imageFile.name
    // console.log('input_file_name=', input_file_name)

    let car_uploads = []
    // TODO: upload car & repair data
    for(let i=0; i < carData.length; i++) {
      let car = carData[i];
      let car_upload = {
        car_id: car.car_id,
        upload_code: car.car_upload_code
      };
      car_uploads.push(car_upload)
    }

    // TODO: upload car image
    car_uploads.map(async (car_upload) => {
      let car_id = car_upload.car_id
      let upload_code = car_upload.upload_code
      let data = new FormData();
      data.append('uploads', imageFile, input_file_name)
      data.append('upload_code', upload_code);
      data.append('user_updated', 'bosscar');
      data.append('car_upload_position', 'front_car');
      data.append('car_upload_id', 0)

      let response = await axios.put(APIURL+'/car_upload', data);

    });


    carData.map( async (car, index) => {
      // TODO: add car
      await delay(3000);
      console.log(index, ' sleep=', car.car_license_plate_old, '-------')

      axios.post(APIURL + '/car/', car).then(res => {
        // TODO: add ค่าใช้จ่ายอื่นๆของรถ ไม่มี center_id
        if(res.status === 200){
          console.log(car)

          const car_id = res.data.car_id

          console.log('car_id=', car_id)

          const branch_id = car.branch_id
          let expense_date = format(new Date(), 'yyyy-MM-dd')

          console.log('car_id=', car_id)
          console.log('branch_id=', branch_id)
          console.log('car_repair1=', car.car_repair1)
          console.log('car_repair2=', car.car_repair2)
          

         /* if (car.car_repair1 !== null) {
            // ตรอ = สถานตรวจสภาพรถ
             let income_expense_item_id = 37
             let expense_name = 'ตรอ'
             let expense_amount = Number(car.car_repair1)
             let pay_by = 'นำเข้า'
             let expense_amount_vat = 0
             let invoice_no = ''
             let buy_from_company = ''
             let include_vat = 0
             let cost_type_id = 0

             let data = {
              expense_date: expense_date,
              branch_id: branch_id,
              car_id: car_id,
              income_expense_item_id: income_expense_item_id,
              expense_name: expense_name,
              expense_amount: expense_amount,
              expense_type_id: '1', //ของรถ
              user_created: 'bosscar',
              user_updated: 'bosscar',
              expense_image_file: 'nophoto.png',
              pay_by: pay_by,
              expense_amount_vat: expense_amount_vat,
              invoice_no: invoice_no,
              buy_from_company: buy_from_company,
              include_vat: include_vat,
              cost_type_id: cost_type_id
            }
            this.addCarExpense(data);
          }*/

          /*if (car.car_repair2 !== null) {
            // แบตเตอรี่ 38 ค่าอะไหล่
             let income_expense_item_id = 38
             let expense_name = 'แบตเตอรี่'
             let expense_amount = Number(car.car_repair2)
             let pay_by = 'นำเข้า'
             let expense_amount_vat = 0
             let invoice_no = ''
             let buy_from_company = ''
             let include_vat = 0
             let cost_type_id = 0

             let data = {
              expense_date: expense_date,
              branch_id: branch_id,
              car_id: car_id,
              income_expense_item_id: income_expense_item_id,
              expense_name: expense_name,
              expense_amount: expense_amount,
              expense_type_id: '1', //ของรถ
              user_created: 'bosscar',
              user_updated: 'bosscar',
              expense_image_file: 'nophoto.png',
              pay_by: pay_by,
              expense_amount_vat: expense_amount_vat,
              invoice_no: invoice_no,
              buy_from_company: buy_from_company,
              include_vat: include_vat,
              cost_type_id: cost_type_id
            }
            this.addCarExpense(data);
          }*/
        }
      }).catch(e => console.log('error: ', e ));

    })

  }

  /*_resetData() {
    this.setState({
      excelFile: '',
      attachFiles: false,
      isUpload: false
    }, () => this._loadData())
  }*/

  addCarExpense(data) {
    axios.post(APIURL + '/expense/nofile/', data)
    .then(res => {
      console.log('res:', res)
    })
    .catch(function (error) {
      console.log('error:', error)
    })
  }


  _changeCarRepairFile(e) {
    if (e.target.files[0]) {

      const {
        cars,
        centers
      } = this.state

      let fileObj = e.target.files[0];
      ExcelRenderer(fileObj, async (err, res) => {
         if (err) return
         
          const data = res.rows;
          const rows = data.slice(1);

        
          // create funtion check car_id
          // console.log(rows.length)
          let repairData = []
          for(let i=0; i< rows.length;i++) {
            let row = rows[i]
            let car_id = ''
            // console.log(i, 'row[0]=', row[0])
            try{
              car_id = cars.filter(car => car.car_license_plate_old === row[0])[0].car_id;
            } catch (e) {
              car_id = cars.filter(car => car.car_license_plate_new === row[0])[0].car_id;
            }
            
            // console.log('car_id=', car_id)
            // console.log('center_name=', row[1])

            let center_id = centers.filter(center => center.center_name.trim() === String(row[1]).trim())[0].center_id;
            let car_repair_name = row[2];
            let car_repair_cost = Number(row[3]);
            let repair_type_id = row[4];
            let car_repair_send = this.getJsDateFromExcel(row[5]);
            let car_repair_complete = this.getJsDateFromExcel(row[6]);
            let pickup_date = this.getJsDateFromExcel(row[7]);
            let is_pickup = row[8] ? 1 : 0;
            let car_repair_note = row[9];

            // car_repair_type : 'ซ่อมปรับแต่ง' , is_after_sell: 0, 
            let data = {
              car_id: car_id,
              center_id: center_id,
              car_repair_name: car_repair_name,
              car_repair_cost: car_repair_cost,
              repair_type_id: repair_type_id,
              car_repair_send: car_repair_send,
              car_repair_complete: car_repair_complete,
              pickup_date: pickup_date,
              is_pickup: is_pickup,
              car_repair_note: car_repair_note,
              car_repair_type : 'ซ่อมปรับแต่ง' ,
              is_after_sell: 0, 
              user_created: 'bosscar',
              user_updated: 'bosscar'
            }
            repairData.push(data)

          }

          // console.log(repairData)
          this.setState({ repairData: repairData })

      });
    }
  }

  _clickUploadCarRepair() {
    const repairData = this.state.repairData
    return

    if(repairData.length === 0) {
      AlertWarning('กรุณาเลือกไฟล์ Excel รายการซ่อม/ปรับแต่ง')
      return
    }

    repairData.map( async (repair, index) => {
       let res = await axios.post(APIURL+'/car_repair/nofile/', repair)
       // console.log(index, res.data) 
       await delay(2000);
    });

  }

  _checkCenter() {

  }

  _checkCar() {

  }


  render () {

    return (
      <div className="card">
        <div className="card-block">
          <div className="row">
            <div className="col-sm-12">
              <form onSubmit={(e) => e.preventDefault()}>
               <div className="card card-accent-info" >
                  <div className="card-header">
                    <strong className="text-title">นำเข้าไฟล์</strong>
                  </div>

                  <div className="card-block">
                    
                    <div className="row form-inline">
                      <h3>ข้อมูลรถ</h3>
                      <div className="form-group p-1">
                        <label>เลือกภาพปกรถ :</label>

                        <input type="file"
                               className="form-control ml-3"
                               id="imageFile"
                               aria-describedby="imageFile"
                               accept=".png,.jpg,.jpeg"
                               name="imageFile"
                               onChange={(e) => {
                                 if (e.target.files[0]) {
                                   let imageFile = e.target.files[0];
                                   this.setState({
                                     imageFile: imageFile
                                   })
                                 }
                               }}
                        />
                      </div>

                      <div className="form-group p-1">
                          <label>เลือกไฟล์ Excel :</label>

                          <input type="file"
                                 className="form-control ml-3"
                                 id="excelFile"
                                 aria-describedby="excelFile"
                                 accept=".xls,.xlsx"
                                 name="excelFile"
                                 value={this.state.excelFile}
                                 onChange={this._changeFileCarOk}
                          />

                          <button type="button" className="btn btn-success ml-3 "
                                  onClick={() => this._clickUpload()}
                          >
                            <i className="fas icon-cloud-download" />
                            &nbsp; UploadFile
                          </button>
                        </div>

                    </div>

                    <hr/>

                    <div className="row form-inline"> 
                       <h3>ข้อมูลการซ่อม/ปรับแต่ง</h3> <br/>

                       <div className="form-group p-1">
                          <label>เลือกไฟล์ Excel :</label>

                          <input type="file"
                                 className="form-control ml-3"
                                 id="repairFile"
                                 aria-describedby="repairFile"
                                 accept=".xls,.xlsx"
                                 name="repairFile"
                                 value={this.state.repairFile}
                                 onChange={this._changeCarRepairFile}
                          />

                          <button type="button" className="btn btn-success ml-3 "
                                  onClick={() => this._clickUploadCarRepair()}
                          >
                            <i className="fas icon-cloud-download" />
                            &nbsp; UploadFile
                          </button>
                        </div>
                    </div>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }

}


const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  },
  fileInputWrapper: {
    position: 'relative',
    overflow: 'hidden',
    height: 180,
    width: 200,
  },
  btnFileInput: {
    height: 180,
    width: 200,
    display: 'inline-block',
    backgroundColor: "#D2DDE2",
    backgroundImage: "linear-gradient(to bottom, #D2DDE2, #FCF8FF)",
    backgroundRepeat: "repeat-x",
    color: "#ffffff",
    textShadow: "0 -1px 0 rgba(0, 0, 0, 0.25)"
  },
  btnFileInputImage:{
    position: 'absolute',
    top: 0,
    left: 0,
    height: 180,
    width: 200,
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0,
    height: 180,
    width: 200,
    cursor: 'pointer'
  },
  button: {
    borderRadius:6,
    height: 36,
  },
  inputSearch: {
    borderRadius:5,
  },
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ImportExcel)
