import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import 'moment/locale/th';
import NumberFormat from 'react-number-format';
import { CLOUDFRONT_URL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL } from '../../../config/config'
import PreviewImage from '../../../views/PreviewImage/';
import {  Modal, ModalBody } from 'reactstrap';
import { checkGroupPermission } from '../../../services/grouppermission'

class ReceiptsListRow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            preview_image_url: '',
            preview_image_select: false
        }
        this.togglePreviewImage = this.togglePreviewImage.bind(this)
    }

    onPreviewSelected(url){
        this.setState({
            preview_image_url: url,
            preview_image_select: true
        });
    }

    togglePreviewImage() {
        this.setState({
            preview_image_select: !this.state.preview_image_select
        });
    }

    render(){
       let grouppermissions = this.props.grouppermissions

        let receiptsList = this.props.receipts_list.map((receipts,index)=>{
            let include_vat = receipts.include_vat

          // receipts_image_file  receipts_image_key
            let image_url = ''
          if (receipts.receipts_image_file !== null) {
            if (receipts.receipts_image_file.includes('https')) {
              let receipts_image_key = receipts.receipts_image_key
              image_url = CLOUDFRONT_URL+receipts_image_key
            }else {
              if (receipts.receipts_image_file==='nophoto.png') {
                image_url = IMAGE_NOPHOTO_URL
              } else {
                image_url = IMAGE_FULL_URL + receipts.receipts_image_file
              }
            }
          } else {
            image_url = IMAGE_NOPHOTO_URL
          }

            return (
                <tr key={index}>
                    <td className="text-center">{index+1}</td>
                    <td className="text-center">{moment(receipts.receipts_date).locale('th').format('LL')}
                        <br/> <p className="text-left mt-1">เลขที่บิล : {receipts.receipts_no} <br/> รับจาก : {receipts.receipts_company}</p>
                    </td>

                    <td className="text-center">{receipts.branch_name}</td>
                    <td className="text-center">{receipts.receipts_name}</td>
                    <td className="text-center">
                        <img
                          src={image_url}
                            style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                            alt="เอกสาร"
                            onClick={()=>this.onPreviewSelected(image_url)}
                        />
                    </td>
                    <td className="text-center">
                      ยอดรายรับ :  <NumberFormat value={receipts.receipts_amount}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'฿'}/>
                                      <br/>
                      {
                        include_vat === 1 ? '*รวม vat': '*ไม่รวม vat'
                      }
                      <hr/>
                      ยอด VAT : <NumberFormat value={receipts.receipts_amount_vat}
                                              displayType={'text'}
                                              thousandSeparator={true}
                                              prefix={'฿'}/>

                    </td>
                    <td className="text-center">{receipts.fullname}
                        <br/>
                        {moment(receipts.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}
                    </td>
                    <td className="text-center">

                      {
                        checkGroupPermission(1101, grouppermissions).modified ?
                          <button
                            className="btn btn-sm btn-warning"
                            style={{width: 110, marginTop: 8}}
                            onClick={()=>this.props.onOpenReceiptsFormEdit(receipts)}
                          >
                            <i className="fa fa-pencil" /> แก้ไข
                          </button>
                          : null
                      }

                      {
                        checkGroupPermission(1102, grouppermissions).created && this.props.is_vat === 1 ?
                          <button
                            className="btn btn-sm btn-primary"
                            style={{width: 110, marginTop: 8}}
                            onClick={()=>this.props.handleOnClick('receipts/income-tax-invoice', receipts)}
                          >
                            <i className="fa fa-print" /> ออกใบกำกับ
                          </button>
                          : null
                      }



                      {
                        checkGroupPermission(1101, grouppermissions).deleted ?
                          <button
                            className="btn btn-sm btn-danger"
                            style={{width: 110, marginTop: 8}}
                            onClick={()=>this.props.onDeleteHandle(receipts)}
                          >
                            <i className="fa fa-remove" /> ลบข้อมูล
                          </button>
                          : null
                      }


                    </td>
                </tr>
            )
        });

        return (
            <div className="card-block">
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">วันที่รับเงิน</th>
                        <th className="text-center">สาขา</th>
                        <th className="text-center">รายละเอียด</th>
                        <th className="text-center">ไฟล์เอกสาร</th>
                        <th className="text-center">ยอดรายรับ</th>
                        <th className="text-center">ผู้บันทึก</th>
                        <th className="text-center" style={{width: 115}} >จัดการ</th>
                    </tr>
                    </thead>
                    <tbody>
                    {receiptsList}
                    </tbody>
                </table>

                <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
                    <ModalBody>
                        <PreviewImage
                            imageUrl={this.state.preview_image_url}
                        />
                    </ModalBody>
                </Modal>
                {/*<ul className="pagination">
                    <li className="page-item"><a className="page-link" href="#">Prev</a></li>
                    <li className="page-item active">
                        <a className="page-link" href="#">1</a>
                    </li>
                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                    <li className="page-item"><a className="page-link" href="#">4</a></li>
                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                </ul>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ReceiptsListRow)
