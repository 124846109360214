import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from "../Loading";
import axios from "axios";
import {APIURL, PORT} from "../../config/config";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import DatePicker from "react-datepicker";
import {setInputStyleDocNo} from "../../services/docLogService";
import * as moment from "moment";
import {DateUtil} from "../../utils/dateUtil";
import {NumberUtil} from "../../utils/number-util";
import ThaiBaht from "thai-baht-text";
import NumberFormat from "react-number-format";

const port = Number(window.env.PORT);

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: 'THSarabunNew-Regular.ttf',
        bold: 'THSarabunNew-Bold.ttf',
        italics: 'THSarabunNew-Italic.ttf',
        bolditalics: 'THSarabunNew-BoldItalic.ttf'
    },
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
    }
}

class PaymentVoucher extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: {},
            isLoading: false,
            logoBase64Img: '',
            company_setting: null,
            startDatePaymentVoucher: new Date(), // moment(),
            isOpenDatePaymentVoucher: false,
            payment_voucher_date_thai: null,
        }

        this.loadCompanySetting = this.loadCompanySetting.bind(this);
        this.loadCompanyLogoBase64 = this.loadCompanyLogoBase64.bind(this);
        this.handleDatePaymentVoucher = this.handleDatePaymentVoucher.bind(this);
    }

    handleDatePaymentVoucher (date) {
        let payment_voucher_date =  moment(date).format('YYYY-MM-DD')
        let payment_voucher_date_thai = DateUtil.convertLongDateThai(payment_voucher_date)

        this.setState({
            startDatePaymentVoucher : date,
            isOpenDatePaymentVoucher : false,
            payment_voucher_date: payment_voucher_date,
            payment_voucher_date_thai: payment_voucher_date_thai
        })
    }

    async loadCompanySetting () {
        await axios.get(APIURL + '/company_setting/1')
            .then(res => {
                let data = res.data
                let company_setting = data.company_setting
                this.setState({
                    company_setting: company_setting
            })
        })
    }

    async loadCompanyLogoBase64() {
        let uri =  APIURL + '/company_setting/logoimage/1';
        await axios.get(uri)
            .then(res => {
                let body = res.data.body
                this.setState({ logoBase64Img: body })
            }).catch(error => {
                console.log('error:', error)
            })
    }

    /*updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }*/

    /*componentWillUnmount() {
        this.mounted = false
        window.removeEventListener('resize', this.updateWindowDimensions);
    }*/

    componentWillMount () {
        // const queryString = require('query-string');
        this.setState({
            user: JSON.parse(this.props.user)
        })
    }

    async componentDidMount () {

        this.mounted = true

        await this.loadCompanyLogoBase64();
        await this.loadCompanySetting();

        /*this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);*/
    }

    render () {
        const {company_setting} = this.state
        let logoWidth = 100
        let base64Img = this.state.logoBase64Img

        return(
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <Loading isLoading={this.state.isLoading} />
                        <div className="card-header">
                            <strong className="text-title">ใบสำคัญจ่าย</strong>
                        </div>

                        <div className="card-block" >
                            <div className="row" style={{lineHeight: 2.0}}>
                                <table style={styles.table}>
                                    <tbody>
                                        <tr>
                                            <td width={'22%'} style={styles.tdContentCenter} >
                                                <img src={base64Img} width={logoWidth} alt='logo' />
                                            </td>

                                            <td style={styles.tdContentCenter}>
                                                {
                                                    this.state.company_setting !== null ? (
                                                        <>
                                                            <div className="text-center">
                                                                {
                                                                    company_setting.company_name
                                                                }
                                                                <br/>
                                                                {
                                                                    company_setting.company_address
                                                                }
                                                                <br/>
                                                                หมายเลขผู้เสียภาษี {  company_setting.juristic_number } &nbsp; &nbsp; เบอร์โทรศัพท์ { company_setting.company_tel }
                                                            </div>
                                                        </>
                                                    ) : null
                                                }
                                            </td>

                                            <td width={'22%'}>&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row">
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td width={'40%'}>&nbsp;</td>
                                        <td width={'30%'}>&nbsp;</td>

                                        <td>
                                            <div className="form-inline mt-5">
                                                เลขที่&nbsp;&nbsp;
                                                <input className="form-control ml-3"
                                                       style={styles.inputDataNum}
                                                       //value={this.state.tax_invoice_number || ''}
                                                        /*onChange={(e) => {
                                                            this.setState({
                                                            tax_invoice_number: e.target.value,
                                                            check_doc_no: 'noset'
                                                            })
                                                        }}*/
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row">
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td width={'40%'}>&nbsp;</td>
                                        <td width={'30%'}>&nbsp;</td>
                                        <td>
                                            <div className="row form-inline mt-2 ml-3">
                                                วันที่ &nbsp;&nbsp;&nbsp;
                                                <input readOnly={true}
                                                       className="form-control"
                                                       style={{width: 160}}
                                                       value={this.state.payment_voucher_date_thai === null ? '' : this.state.payment_voucher_date_thai}
                                                />
                                                <button type="button"
                                                        className="btn btn-primary ml-1"
                                                        onClick={(e) => {
                                                            this.setState({
                                                                isOpenDatePaymentVoucher: true
                                                            });
                                                        }}
                                                >
                                                    <i className="icon-calendar"/>
                                                </button>

                                                {
                                                    this.state.isOpenDatePaymentVoucher && (
                                                        <DatePicker
                                                            selected={this.state.startDatePaymentVoucher}
                                                            onChange={this.handleDatePaymentVoucher}
                                                            dateFormat="yyyy-MM-dd"
                                                            inline
                                                        />
                                                    )
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mt-5" style={{lineHeight: 2.0}}>
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td width={'10%'}/>
                                        <td>
                                            <div className="form-inline mt-3">
                                                จ่ายให้
                                                <input type="text" style={styles.inputData}
                                                       className="form-control ml-5"
                                                       placeholder=""
                                                        //value={this.state.tax_invoice_address1 || ''}
                                                        /*onChange={(e) => {
                                                            let val = e.target.value;
                                                            this.setState({
                                                                tax_invoice_address1: val
                                                            });
                                                        }}*/
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mt-1">
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td width={'10%'}/>
                                        <td>
                                            <div className="form-inline">
                                                ที่อยู่ &nbsp;&nbsp;
                                                <input type="text"
                                                       style={styles.inputData}
                                                       className="form-control ml-5"
                                                       placeholder=""
                                                    //value={this.state.tax_invoice_address1 || ''}
                                                    /*onChange={(e) => {
                                                        let val = e.target.value;
                                                        this.setState({
                                                            tax_invoice_address1: val
                                                        });
                                                    }}*/
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mt-1">
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td width={'10%'}/>
                                        <td>
                                            <div className="form-inline">
                                                เลขประจำตัวผู้เสียภาษี &nbsp;
                                                <input type="text"
                                                       style={styles.inputTax}
                                                       className="form-control ml-3"
                                                       placeholder=""
                                                    //value={this.state.tax_invoice_address1 || ''}
                                                    /*onChange={(e) => {
                                                        let val = e.target.value;
                                                        this.setState({
                                                            tax_invoice_address1: val
                                                        });
                                                    }}*/
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mt-5">
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td width={'10%'}/>
                                        <td>
                                            <div className="form-inline">
                                                ชำระโดย &nbsp;&nbsp;&nbsp;&nbsp;
                                                <input className="ml-4 mr-2"
                                                       type="checkbox"
                                                       //name="customer_type"
                                                       //id="customer_type1"
                                                       //value="finance"
                                                       /*onClick={(e) => {
                                                           // let val = e.target.value

                                                       }}
                                                       checked={this.state.customer_type === 'finance'}
                                                       onChange={(e) => {
                                                           let val = e.target.value
                                                           this.setState({
                                                               customer_type: val
                                                           })
                                                       }}*/
                                                />
                                                <label id="customer_type1" className="mr-5">เงินสด/Cash</label>
                                            </div>

                                            <div className="form-inline mt-2 ml-5">
                                                <input className="ml-5 mr-2"
                                                       type="checkbox"
                                                    //name="customer_type"
                                                    //id="customer_type1"
                                                    //value="finance"
                                                    /*onClick={(e) => {
                                                        // let val = e.target.value

                                                    }}
                                                    checked={this.state.customer_type === 'finance'}
                                                    onChange={(e) => {
                                                        let val = e.target.value
                                                        this.setState({
                                                            customer_type: val
                                                        })
                                                    }}*/
                                                />
                                                <label id="customer_type1" className="mr-5">เงินโอน</label>
                                            </div>

                                            <div className="form-inline mt-2 ml-5">
                                                <input className="ml-5 mr-2"
                                                       type="checkbox"
                                                    //name="customer_type"
                                                    //id="customer_type1"
                                                    //value="finance"
                                                    /*onClick={(e) => {
                                                        // let val = e.target.value

                                                    }}
                                                    checked={this.state.customer_type === 'finance'}
                                                    onChange={(e) => {
                                                        let val = e.target.value
                                                        this.setState({
                                                            customer_type: val
                                                        })
                                                    }}*/
                                                />
                                                <label id="customer_type1" className="mr-2">เช็ค</label>
                                                <input type="text"
                                                       style={styles.inputTax}
                                                       className="form-control ml-1"
                                                       placeholder="ธนาคาร................เลขที่................ลงวันที่................"
                                                    //value={this.state.tax_invoice_address1 || ''}
                                                    /*onChange={(e) => {
                                                        let val = e.target.value;
                                                        this.setState({
                                                            tax_invoice_address1: val
                                                        });
                                                    }}*/
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mt-5" style={styles.tablePd}>
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <th style={styles.tableTh} width={'13%'}>ลำดับ</th>
                                        <th style={styles.tableTh} width={'68%'}>รายการ</th>
                                        <th style={styles.tableTh} width={'20%'}>จำนวนเงิน (บาท)</th>
                                    </tr>

                                    <tr>
                                        <td style={styles.tableTdLeft}>1</td>
                                        <td style={styles.tableTdCenter}>

                                            <table style={styles.table}>
                                                <tbody>
                                                    <tr>
                                                        <td width={'30%'}>
                                                            เปลี่ยนแม๊กและยางรถยนต์
                                                        </td>
                                                    </tr>
                                                    <tr><td>&nbsp;</td></tr>
                                                    <tr><td>&nbsp;</td></tr>
                                                    <tr><td>&nbsp;</td></tr>
                                                    <tr><td>&nbsp;</td></tr>
                                                </tbody>
                                            </table>
                                        </td>

                                        <td style={styles.tableTdRigth}>
                                            <table style={{width: '100%'}}>
                                                <tbody>
                                                    <tr>
                                                        <td style={styles.tableTdRigthInner}>
                                                            0.00
                                                            {
                                                                //this.genCarPrice()
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>

                                    </tr>
                                    </tbody>
                                </table>

                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td style={styles.amountsText} width={'63%'}>
                                            {/*{ThaiBaht(this.state.tax_invoice_total)}*/}
                                        </td>
                                        <td style={styles.tableTdRigth} width={'18%'}><strong>รวมราคาทั้งสิ้น &nbsp;</strong></td>
                                        <td style={styles.tableTdRigth} >
                                            <NumberFormat
                                                className="form-control text-right"
                                                thousandSeparator={true}
                                                decimalScale={2} fixedDecimalScale
                                                min={0}
                                                max={1000000000}
                                                step={1000}
                                                size={10}
                                                allowNegative={false}
                                                //value={this.state.tax_invoice_amount}
                                                /*onValueChange = {(values) => {
                                                    const {value} = values;
                                                    this.setState({tax_invoice_amount: value},()=>{
                                                        this.calInvoice()
                                                    })
                                                }}*/
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="row mt-5 mb-3 ml-5">
                                <table style={styles.table}>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <p>ลงชื่อ...............................................................................(ผู้รับเงิน)</p>
                                        </td>

                                        <td>
                                            <p>ลงชื่อ...............................................................................(ผู้จ่ายเงิน)</p>
                                        </td>

                                        <td>
                                            <p>ลงชื่อ...............................................................................(ผู้อนุมัติ)</p>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <input type="text" style={{ ...styles.inputData, width: 360}}
                                                   className="form-control mb-2"
                                                   placeholder=""
                                                   //value={this.state.tax_invoice_payee || ''}
                                                   /*onChange={(e) => {
                                                       let val = e.target.value;
                                                       this.setState({
                                                           tax_invoice_payee: val
                                                       });
                                                   }}*/
                                            />
                                        </td>

                                        <td>
                                            <input type="text" style={{ ...styles.inputData, width: 360}}
                                                   className="form-control mb-2"
                                                   placeholder=""
                                                //value={this.state.tax_invoice_payee || ''}
                                                /*onChange={(e) => {
                                                    let val = e.target.value;
                                                    this.setState({
                                                        tax_invoice_payee: val
                                                    });
                                                }}*/
                                            />
                                        </td>

                                        <td>
                                            <input type="text" style={{ ...styles.inputData, width: 360}}
                                                   className="form-control mb-2"
                                                   placeholder=""
                                                //value={this.state.tax_invoice_payee || ''}
                                                /*onChange={(e) => {
                                                    let val = e.target.value;
                                                    this.setState({
                                                        tax_invoice_payee: val
                                                    });
                                                }}*/
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="col-md-12 text-right mt-5">
                                <div className="card-block">
                                    <button type="button"
                                            className="btn btn-success btn-md mr-2"
                                            style={{width: 120}}
                                    >
                                        <i className="icon-check"/> บันทึก
                                    </button>

                                    <button type="button"
                                            className="btn btn-primary btn-md mr-2"
                                            style={{width: 120}}
                                    >
                                        <i className="icon-printer"/>  พิมพ์
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const styles = {
    table: {
        width: '100%',
        maxWidth: '100%'
    },
    tdContentCenter: {
        textAlign: 'center',
        verticalAlign: 'middle',
    },
    tdContentRight: {
        textAlign: 'right',
        verticalAlign: 'middle',
        justifyContent: 'right',
    },
    tdContentLeft: {
        textAlign: 'left',
        verticalAlign: 'middle'
    },
    tableTh:{
        maxWidth: '100%',
        borderColor: '#626262',
        borderWidth: '1px',
        borderStyle: 'solid',
        textAlign: 'center',
        verticalAlign: 'middle',
    },
    tableTdLeft:{
        maxWidth: '100%',
        borderColor: '#626262',
        borderWidth: '1px',
        borderStyle: 'solid',
        textAlign: 'center',
        verticalAlign: 'top',
    },
    tableTdCenter:{
        maxWidth: '100%',
        borderColor: '#626262',
        borderWidth: '1px',
        borderStyle: 'solid',
        textAlign: 'left',
        verticalAlign: 'middle',
        paddingLeft: '70px',
    },
    tableTdRigth:{
        maxWidth: '100%',
        borderColor: '#626262',
        borderWidth: '1px',
        borderStyle: 'solid',
        textAlign: 'right',
        verticalAlign: 'top',
    },
    tableTdRigthInner:{
        maxWidth: '100%',
        borderColor: '#fff',
        borderWidth: '0px',
        borderStyle: 'solid',
        textAlign: 'right',
        verticalAlign: 'top',
        paddingRight: '8px'
    },
    tablePd:{
        paddingLeft: '150px',
        paddingRight: '150px',
        lineHeight: '2.2',
    },
    amountsText:{
        maxWidth: '100%',
        borderColor: '#626262',
        borderWidth: '1px',
        borderStyle: 'solid',
        textAlign: 'center',
        verticalAlign: 'middle',
        fontWeight: 'bold',
    },
    amountsTextNoBorder:{
        maxWidth: '100%',
        textAlign: 'center',
        verticalAlign: 'middle',
    },
    amountsRigth:{
        maxWidth: '100%',
        borderColor: '#626262',
        borderWidth: '1px',
        borderStyle: 'solid',
        textAlign: 'right',
        verticalAlign: 'top',
        fontWeight: 'bold',
    },
    buttonHeight: {
        height: 32,
    },
    inputData:{
        borderRadius: '5px',
        width: '550px',
    },
    inputTax:{
        borderRadius: '5px',
        width: '481px',
    },
    inputDataNo:{
        borderRadius: '5px',
        width: '100px'
    },
    inputDataNum:{
        borderRadius: '5px',
        width: '160px'
    },
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(PaymentVoucher)

