import React, { Component } from 'react'
import { getTaxDocTypeById } from '../../services/taxDocTypeService'
import DatePicker from 'react-datepicker'
import '../../css/customDatePickerWidth.css'
import { format, lastDayOfMonth } from 'date-fns'
import TaxDocumentForm from './TaxDocumentForm'
import { DateUtil } from '../../utils/dateUtil'
import axios from 'axios'
import { APIURL, CLOUDFRONT_URL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL } from '../../config/config'
import { AlertError, AlertSuccess, AlertWarning } from '../Alert/Alert'
import Loading from '../Loading'
import { th } from 'date-fns/locale'
import { NumberUtil } from '../../utils/number-util'
import { Modal, ModalBody } from 'reactstrap'
import PreviewImage from '../../views/PreviewImage'
import Swal from 'sweetalert2'
import moment from 'moment'
import { checkGroupPermission } from '../../services/grouppermission'

class TaxDocument extends Component {

  constructor (props) {
    super(props);
    this.state = {
      tax_doc_type: null,
      date_end: null,
      date_start: null,
      form_status: 'add',
      is_modal: false,
      tax_documents: [],
      isLoading: false,
      tax_document_selected: null,
      preview_image_url: '',
      preview_image_select: false,
    };

    this.handleDateStart = this.handleDateStart.bind(this);
    this.handleDateEnd = this.handleDateEnd.bind(this);
    this.reportByDate = this.reportByDate.bind(this);
    this.setTaxDocType = this.setTaxDocType.bind(this);
    this.openAddModal = this.openAddModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onTaxDocumentEditSelected = this.onTaxDocumentEditSelected.bind(this);
    this.onPreviewSelected = this.onPreviewSelected.bind(this);
    this.togglePreviewImage = this.togglePreviewImage.bind(this);
    this.deleteTaxDocument = this.deleteTaxDocument.bind(this);
  }

  componentDidMount () {
     let menu_id = this.props.menu_id
     let tax_doc_type_id = this.props.tax_doc_type_id
     let tax_doc_types = this.props.tax_doc_types

     this.setTaxDocType(tax_doc_type_id, tax_doc_types)
     this.reportByDate(true)
  }

  setTaxDocType(tax_doc_type_id, tax_doc_types) {
    if (tax_doc_types.length > 0) {
      let tax_doc_type_arr = tax_doc_types.filter((tax_doc_type) => tax_doc_type.tax_doc_type_id === tax_doc_type_id )
      this.setState({tax_doc_type: tax_doc_type_arr[0]})
    }else{
      this.setState({tax_doc_type: null})
    }
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  reportByDate(is_first_load) {
    let start = null
    let end = null

    if (is_first_load === true){
      let today = new Date()
      start = format(today, 'yyyy-MM-01')
      end = format(today, 'yyyy-MM-dd')

      this.setState({
        date_start : new  Date(start),
        date_end : new  Date(end),
      })
    } else {
      start = format(this.state.date_start, 'yyyy-MM-dd')
      end = format(this.state.date_end, 'yyyy-MM-dd')
      // const lastDateOfMonth = format(lastDayOfMonth(date), 'yyyy-MM-dd')
    }

    let tax_doc_type_id = this.props.tax_doc_type_id
    let url = APIURL + '/tax_document/criteria'
    let data = {
      tax_doc_type_id,
      start,
      end
    }

    this.setState({
      isLoading: true
    }, () => {
      axios.post(url, data).then(res => {
        let tax_documents = res.data

        this.setState({
          tax_documents: tax_documents,
          isLoading: false
        })

      }).catch(err => {
        console.log('error:', err)
      })
    })

  }

  openAddModal() {
    this.setState({
      form_status: 'add',
      is_modal: true,
      tax_document_selected: null
    })
  }

  closeModal() {
    this.setState({
      is_modal: false
    })
  }

  onTaxDocumentEditSelected(tax_document_selected) {
    this.setState({
      form_status: 'edit',
      is_modal: true,
      tax_document_selected: tax_document_selected
    })
  }


  onSubmitForm(e){
    e.preventDefault()
    let tax_doc_type_id = this.props.tax_doc_type_id
    let username = this.props.username
    let form_status = this.state.form_status

    let tax_document_date = e.target.tax_document_date.value
    let tax_document_date_db = DateUtil.convertCCtoCCDB(tax_document_date)

    let tax_document_detail = e.target.tax_document_detail.value
    let tax_amount = Number(e.target.tax_amount.value)
    let tax_document_id = Number(e.target.tax_document_id.value)

    if (tax_amount === 0) {
      AlertWarning('กรุณากรอกยอดค่าใช้จ่าย')
      return
    }

    if (tax_document_detail === '') {
      AlertWarning('กรุณากรอกรายละเอียด')
      return
    }

    if(e.target.tax_doc_file.files.length === 0) {
      // no file
      let data = {
        tax_doc_type_id,
        username,
        tax_document_date: tax_document_date_db,
        tax_document_detail: tax_document_detail,
        tax_amount: tax_amount,
        tax_document_id: tax_document_id,
        form_status: form_status
      }

      axios.post(APIURL + '/tax_document/nofile/', data)
        .then(res => {
          console.log(res.data)
          if (res.data === 'success') {
            AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
            this.reportByDate(false);
            this.closeModal();
          }else if (res.data === 'fail') {
            AlertError('ผิดพลาด: ไม่สามารถบันทึกได้');
          }
        }).catch(err => {
        console.log('error:', err)
      })

    } else {

      const dataForm = new FormData();
      dataForm.append('file', e.target.tax_doc_file.files[0], e.target.tax_doc_file.files[0].name);
      dataForm.append('tax_doc_type_id', tax_doc_type_id);
      dataForm.append('username', username);
      dataForm.append('tax_document_date', tax_document_date_db);
      dataForm.append('tax_document_detail', tax_document_detail);
      dataForm.append('tax_amount', tax_amount);
      dataForm.append('tax_document_id', tax_document_id);
      dataForm.append('form_status', form_status);

      axios.post(APIURL + '/tax_document', dataForm)
        .then(res => {
          console.log(res.data)
          if (res.data === 'success') {
            AlertSuccess('บันทึกข้อมูลเรียบร้อยแล้ว');
            this.reportByDate(false);
            this.closeModal();
          }else if (res.data === 'fail') {
            AlertError('ผิดพลาด: ไม่สามารถบันทึกได้');
          }
        })
        .catch(function (error) {
          console.log('error:', error)
        })
    }
  }

  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

  deleteTaxDocument(tax_document) {
    let tax_document_detail = tax_document.tax_document_detail

    Swal.fire({
      title: 'ต้องการลบเอกสารรายการ '+ tax_document_detail + ' ใช่หรือไม่',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {

        let tax_document_id = tax_document.tax_document_id
        let username = this.props.username

        axios.delete(APIURL+'/tax_document/'+ tax_document_id+'/'+username)
          .then(res => {
            if(res.status === 200) {
              AlertSuccess('ลบเอกสารเรียบร้อยแล้ว');
              this.reportByDate(false)
            }
          })
          .catch(function(error){
            console.log('error:' + error.response);
            // alert("error:"+error.response);
          });
      }
    })
  }

  render () {
    const {
      tax_doc_type,
      is_modal,
      form_status,
      tax_documents,
      tax_document_selected
    } = this.state

    let tax_doc_type_id = this.props.tax_doc_type_id
    let isDateFill = this.props.isDateFill
    let menuID = this.props.menu_id
    let grouppermissions = this.props.grouppermissions

    let tax_amount_sum = 0
    const emptyBody = <tr><td colSpan={6} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

    let taxDocumentRows = tax_documents.map((tax_document, index) => {
      const tax_document_date = format(new Date(tax_document.tax_document_date), 'dd MMMM yyyy',  { locale: th });
      const tax_amount = parseFloat(String(tax_document.tax_amount)).toFixed(2)
      const tax_document_detail = tax_document.tax_document_detail
      const tax_doc_file = tax_document.tax_doc_file
      const tax_doc_key = tax_document.tax_doc_key
      const tax_document_id = tax_document.tax_document_id

      tax_amount_sum += Number(tax_amount)

      let docType = 'image'

      let tax_doc_url = ''

      if (tax_doc_key === null) {
        tax_doc_url = IMAGE_NOPHOTO_URL
      } else {
        if (tax_doc_file.includes('https')) {
          // car_doc_url = car_doc.car_doc_file
          docType = tax_doc_file.indexOf('.pdf') !== -1 ? 'pdf' : 'image'
          tax_doc_url = CLOUDFRONT_URL + tax_doc_key
        } else {
          tax_doc_url = IMAGE_FULL_URL + tax_doc_file
        }
      }

      return (
        <tr key={index}>
          <td className="text-center">{index+1}</td>
          <td>{tax_document_date}</td>
          <td>{NumberUtil.addCommas(tax_amount)}</td>
          <td>{tax_document_detail}</td>
          <td>
            {
              docType === 'pdf' ?
                <a href={tax_doc_url} target='_blank' rel="noopener noreferrer">เอกสารภาษี(pdf)</a>

                :
                <img
                  id={'doc'+tax_document_id}
                  name={'doc'+tax_document_id}
                  src={tax_doc_url}
                  style={{ 'cursor': 'pointer', 'width': '120px', 'height': 'auto' }}
                  onClick={(e) => this.onPreviewSelected(tax_doc_url)}
                  alt="เอกสาร"
                />

            }
          </td>
          <td>
            {
              checkGroupPermission(menuID, grouppermissions).modified ?
                <button className="btn btn-sm btn-warning btn-md-width" style={{width: 100}}
                        onClick={()=>{
                          this.onTaxDocumentEditSelected(tax_document)
                        }}
                >
                  <i className="fa fa-pencil"/> แก้ไข
                </button>
                :
                null
            }

            {
              checkGroupPermission(menuID, grouppermissions).deleted ?
                <button className="btn btn-sm btn-danger btn-md-width mt-2" style={{width: 100}}
                        onClick={()=> {
                          this.deleteTaxDocument(tax_document)
                        }}

                >
                  <i className="fa fa-remove"/> ลบ
                </button>
                : null
            }


          </td>
        </tr>
      )

    })


    const tableRows = tax_documents.length === 0 ? emptyBody : taxDocumentRows

    return <div className="row">

      <Loading isLoading={this.state.isLoading} />

      {
        is_modal === true && tax_doc_type !== null &&
        <TaxDocumentForm
          is_modal={is_modal}
          tax_doc_type_id={tax_doc_type_id}
          tax_doc_type={tax_doc_type}
          form_status={form_status}
          isDateFill={isDateFill}
          tax_document_selected={tax_document_selected}
          closeModal={this.closeModal}
          onSubmitForm={this.onSubmitForm}
        />
      }

      <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
        <ModalBody>
          <PreviewImage
            imageUrl={this.state.preview_image_url}
          />
        </ModalBody>
      </Modal>

      <div className="col-sm-12 col-md-12">
        <div className="card card-accent-success">
          <div className="card-header card-customer">
            <div className="row">

              <div className="font-weight-bold">
                 เอกสารภาษี : {tax_doc_type !== null && tax_doc_type.tax_doc_type_name}
              </div>

              <div className=" ml-1 mr-1" style={{ right: 10, position: 'absolute', marginTop: -3 }}>
                {
                  checkGroupPermission(menuID, grouppermissions).created ?
                    <button type="button" style={{width: 140}}
                            className="btn btn-sm btn-success"
                            onClick={() => {
                              this.openAddModal()
                            }} >
                      เพิ่มเอกสาร
                    </button>
                    : null
                }

              </div>

            </div>
          </div>
          <div className="card-block">
            <div className="row">

                <div className="mt-1 ml-4 mr-2">
                  จาก
                </div>
                <div className="mr-2"  style={{width: 130}} >
                  <DatePicker
                    wrapperClassName="datepicker"
                    selected={this.state.date_start}
                    onChange={this.handleDateStart}
                    dateFormat="yyyy-MM-dd"
                    type="text"
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div  className="mr-2" style={{width: 130}}>
                  <DatePicker
                    wrapperClassName="datepicker"
                    selected={this.state.date_end}
                    onChange={this.handleDateEnd}
                    dateFormat="yyyy-MM-dd"
                    type="text"
                    className="form-control"
                    placeholder=""
                  />
                </div>

              <div className=" ml-1 mr-1">
                <button type="button" style={{width: 80}}
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                           this.reportByDate(false)
                        }} >
                  ตกลง
                </button>
              </div>



            </div>
            <hr/>
            <div className="row mt-1">
              <div className="col-12">
                <table className="table table-striped table-responsive">
                  <thead>
                  <tr>
                    <th className="text-center" style={{width: 28}}>ลำดับ</th>
                    <th style={{width: 150}} className=" text-nowrap">วันที่ออกเอกสาร</th>
                    <th style={{width: 150}} className=" text-nowrap">ยอดค่าใช้จ่าย</th>
                    <th  className=" text-nowrap">รายละเอียดเอกสาร</th>
                    <th className="text-center text-nowrap">เอกสารแนบ</th>
                    <th style={{width: 150}} className="text-center text-nowrap">จัดการ</th>
                  </tr>
                  </thead>
                  <tbody>
                  {tableRows}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  }
}

export default TaxDocument
