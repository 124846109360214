import React, { Component } from 'react'
// import DatePicker from 'react-datepicker'
import MaskedInput  from 'react-maskedinput'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css';
import NumberFormat from 'react-number-format'
import axios from 'axios'
import { APIURL } from '../../../config/config'
import OccupationModal from '../OccupationModal'
import {format} from "date-fns";
import {DateUtil} from "../../../utils/dateUtil";
moment.locale('th');

export  default class CustomerEdit extends Component{
    constructor(props){
        super(props);
        this.state={
            lists: [],
            sales_type:[],
            load_data: true,
            danger: false,
            action: "บันทึก",
            customer: '' ,

            customer_id: this.props.customer.customer_id,
            customer_name: this.props.customer.customer_name,
            customer_lastname:this.props.customer.customer_lastname,
            customer_card_id:this.props.customer.customer_card_id,
            customer_birth_mask: this.props.customer.customer_birth === null || this.props.customer.customer_birth === '0000-00-00' ? '' : this.convertCCtoBB(moment(this.props.customer.customer_birth).format("YYYY-MM-DD")),
            customer_birth: this.props.customer.customer_birth === null || this.props.customer.customer_birth === '0000-00-00' ? '' : moment(this.props.customer.customer_birth).format("YYYY-MM-DD"),
            customer_age: this.props.customer.customer_age === null ? '' : this.props.customer.customer_age,
            customer_mobile:this.props.customer.customer_mobile,
            customer_address:this.props.customer.customer_address,
            guarantor_name:this.props.customer.guarantor_name,
            guarantor_address:this.props.customer.guarantor_address,
            guarantor_mobile:this.props.customer.guarantor_mobile,
            customer_income: this.props.customer.customer_income,
            occupation_id: this.props.customer.occupation_id,
            occupation_name: this.props.customer.occupation_name,
            province_id: this.props.customer.province_id,
            employee_id: this.props.customer.employee_id,
            //customer_date_book: this.props.customer.customer_date_book,
            customer_date_book_mask: this.props.customer.customer_date_book === null || this.props.customer.customer_date_book === '0000-00-00' ? '' : this.convertCCtoBB(moment(this.props.customer.customer_date_book).format("YYYY-MM-DD")),
            customer_date_book: this.props.customer.customer_date_book === null || this.props.customer.customer_date_book === '0000-00-00' ? '' : moment(this.props.customer.customer_date_book).format("YYYY-MM-DD"),
            customer_note: this.props.customer.customer_note,

            customer_line: this.props.customer.customer_line === null ? '' : this.props.customer.customer_line,
            customer_facebook: this.props.customer.customer_facebook === null ? '' : this.props.customer.customer_facebook,

          province: [],
          employee:[],
          startDateCustomerEdit1: moment(),
          startDateCustomerEditDateBook: moment(),
          isOccupationModal: false
        }

        this.handleDateCustomerEdit1 = this.handleDateCustomerEdit1.bind(this)
        this.convertBBtoCC = this.convertBBtoCC.bind(this)
        this.convertCCtoBB =  this.convertCCtoBB.bind(this)
        this.onMaskedDateCustomerEdit1 = this.onMaskedDateCustomerEdit1.bind(this)
        this.onInputChangeCustomer = this.onInputChangeCustomer.bind(this)
        this.loadProvinceData = this.loadProvinceData.bind(this)
        this.onSelectOccupation = this.onSelectOccupation.bind(this)

        this.loadEmployeeData = this.loadEmployeeData.bind(this)
        this.onMaskedDateCustomerEditDateBook = this.onMaskedDateCustomerEditDateBook.bind(this)
        this.handleDateCustomerEditDateBook = this.handleDateCustomerEditDateBook.bind(this)
    }

    componentDidMount(){
      this.loadProvinceData()
      this.loadEmployeeData()
    }

  onSelectOccupation(occupation) {
    this.setState({
      occupation: occupation,
      occupation_id: occupation.occupation_id,
      occupation_name: occupation.occupation_name,
      isOccupationModal: false
    })
  }

    loadProvinceData () {
      axios.get(APIURL + '/province/')
        .then(res => {
          this.setState({province: res.data})
        }).catch(error => {
        this.setState({province: []})
      })
    }

    loadEmployeeData () {
        axios.get(APIURL + '/employee/')
            .then(res => {
                this.setState({employee: res.data})
            }).catch(error => {
            this.setState({employee: []})
        })
    }

    handleDateCustomerEdit1(date) {
        // this.setState({
        //     startDateCustomerEdit1: moment(date),
        //     customer_birth : date
        // });
        // ---
        let customer_birth = format(new Date(date),'yyyy-MM-dd');
        let years = DateUtil.calculateAge(customer_birth);

        // startDateCustomerNew1: moment(date).format('YYYY-MM-DD')
        this.setState({
            startDateCustomerNew1: format(new Date(date),'yyyy-MM-dd'),
            customer_birth: customer_birth,
            customer_age: years
        });
    }

    handleDateCustomerEditDateBook(date) {
        //alert (date);
        this.setState({
            startDateCustomerEditDateBook: moment(date),
            customer_date_book : date
        });
    }

    onMaskedDateCustomerEdit1(e){

        let buddhist_date = e.target.value ;

        this.setState({
            customer_birth_text: buddhist_date
        })

        if (buddhist_date.length===0) {
            this.setState({
                customer_birth: null
            })
        }

      if(buddhist_date.indexOf('_') === -1 ) {
        if (buddhist_date.length === 10) {
          let cc_date = this.convertBBtoCC(buddhist_date)
          this.handleDateCustomerEdit1(cc_date)
        }
      }
    }

    onMaskedDateCustomerEditDateBook(e){
        let buddhist_date = e.target.value ;

        if(buddhist_date.indexOf('_') === -1 ) {
            if (buddhist_date.length === 10) {
                let cc_date = this.convertBBtoCC(buddhist_date)
                this.handleDateCustomerEditDateBook(cc_date)
            }
        }
    }

    convertBBtoCC(buddhist_date){
        // dd-mm-yyyy to yyyy-mm-dd
        let date_arr = buddhist_date.split('-');
        let dd = date_arr[0];
        let mm = date_arr[1];
        let yyyy = Number(date_arr[2])-543;
        let cc_date= yyyy+'-'+mm+'-'+dd ;
        if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
            //alert(cc_date);
            return cc_date ;
        }
        return moment() ;
    }

    convertCCtoBB(cc_date) { // "1972-10-31"
      let date_arr=cc_date.split('-');
      let dd = date_arr[2];
      let mm = date_arr[1];
      let yyyy = Number(date_arr[0])+543;

      if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
        //alert(cc_date);
        return dd+'-'+mm+'-'+yyyy ;
      }
      return moment() ;
    }

    onInputChangeCustomer(event) {
        event.preventDefault()

      let name = event.target.value

      if (event.target.id === 'customer_name') {
            this.setState({customer_name: name});
        } else if (event.target.id === 'customer_lastname') {
            this.setState({customer_lastname: name})
        } else if (event.target.id === 'customer_card_id') {
            this.setState({customer_card_id: name})
        } else if (event.target.id === 'customer_address') {
            // name = this.refs.customer_address.val;
            this.setState({customer_address: name})
        }else if (event.target.id === 'customer_birth') {
            this.setState({customer_birth: name})
        }else if (event.target.id === 'customer_age') {
            this.setState({customer_age: name})
        }else if (event.target.id === 'customer_mobile') {
            this.setState({customer_mobile: name})
        }else if (event.target.id === 'guarantor_name') {
            this.setState({guarantor_name: name})
        }else if (event.target.id === 'guarantor_address') {
            this.setState({guarantor_address: name})
        }else if (event.target.id === 'guarantor_mobile') {
            this.setState({guarantor_mobile: name})
        }else if (event.target.id === 'customer_note') {
          this.setState({customer_note: name})
        }else if (event.target.id === 'customer_date_book') {
          this.setState({customer_date_book: name})
        }else if (event.target.id === 'customer_line'){
          this.setState({customer_line: name})
        }else if (event.target.id === 'customer_facebook'){
          this.setState({customer_facebook: name})
        }
    }

    render(){
        // const { history } = this.props

      let province_list = this.state.province.map((province, index) => {
        return (
          <option key={index+1} value={province.province_id}>{province.province_name}</option>
        )
      })

        let employee_list = this.state.employee.map((employee, index) => {
            return (
                <option key={index+1} value={employee.employee_id}>{employee.employee_name}</option>
            )
        })

        return(

            <div className="card card-accent-warning">

              <OccupationModal
                isModal={this.state.isOccupationModal}
                onSelectOccupation={this.onSelectOccupation}
                closeModal={() => {
                  this.setState({
                    isOccupationModal: false,
                    occupation: null
                  })
                }}
              />

                <form action="" method="post"
                      onSubmit={this.props.onCustomerSubmit.update}>

                    <div className="card-header card-edit">
                        <strong className="text-title">แก้ไขข้อมูลลูกค้า</strong>
                    </div>

                    <div className="card-block">
                        <div className="row">
                            <input type="hidden"
                                   className="form-control"
                                   id="customer_id"
                                   placeholder=""
                                   value={this.state.customer_id}
                                   onChange={this.onInputChangeCustomer}
                            />
                            <div className="form-group col-sm-6">
                                <label htmlFor="customer_name">ชื่อ</label>
                                <input type="text"
                                       className="form-control"
                                       id="customer_name"
                                       placeholder=""
                                       value={this.state.customer_name}
                                       onChange={this.onInputChangeCustomer}
                                />
                            </div>
                            <div className="form-group col-sm-6">
                                <label htmlFor="customer_lastname">นามสกุล</label>
                                <input type="text"
                                       className="form-control"
                                       id="customer_lastname"
                                       placeholder=""
                                       ref="customer_lastname"
                                       value={this.state.customer_lastname}
                                       onChange={this.onInputChangeCustomer}
                                />
                            </div>

                            <div className="form-group col-sm-12">
                                <label htmlFor="customer_address">ที่อยู่ปัจจุบัน</label>
                                <input type="text"
                                       className="form-control"
                                       id="customer_address"
                                       name="customer_address"
                                       placeholder=""
                                       ref="customer_address"
                                       value={this.state.customer_address || ''}
                                       onChange={this.onInputChangeCustomer}
                                />
                            </div>

                            <div className="form-group col-sm-4">
                                <label htmlFor="customer_card_id">เลขบัตรประชาชน</label>
                                <input type="text"
                                       className="form-control hidden"
                                       id="customer_card_id_old-bk"
                                       placeholder=""
                                       ref="customer_card_id_old-bk"
                                      // maxLength="13"
                                      // value={this.state.customer_card_id}
                                      // onChange={this.onInputChangeCustomer}
                                />

                                <MaskedInput
                                    mask="1-1111-11111-11-1"
                                    id="customer_card_id"
                                    name="customer_card_id"
                                    ref="customer_card_id"
                                    placeholder=""
                                    className="form-control"
                                    maxLength="20"
                                    value={this.state.customer_card_id}
                                    onChange={this.onInputChangeCustomer}
                                />
                            </div>

                            <div className="form-group col-sm-4">
                                <label htmlFor="customer_birth_text">วันเดือนปีเกิด (พ.ศ.)</label>
                                <MaskedInput
                                    mask="11-11-1111"
                                    name="customer_birth_text"
                                    placeholder="dd-mm-yyyy"
                                    onChange={this.onMaskedDateCustomerEdit1}
                                    className="form-control"
                                    value={this.state.customer_birth_mask}
                                />
                                <input className="form-control hidden"
                                       type="text"
                                       id="customer_birth"
                                       name="customer_birth"
                                       ref="customer_birth"
                                       value={this.state.customer_birth} onChange={()=>{}}
                                />
                                {/*<DatePicker
                                       selected={this.state.startDateCustomerEdit1}
                                       dateFormat="yyyy-MM-dd"
                                       type="text"
                                       className="form-control hidden"
                                       id="customer_birth"
                                       placeholder=""
                                       ref="customer_birth"
                                       value={this.state.customer_birth}
                                       onChange={this.handleDateCustomerEdit1}
                                />*/}
                            </div>
                            <div className="form-group col-sm-4">
                                <label htmlFor="customer_age">อายุ</label>
                                <input type="text"
                                       className="form-control"
                                       id="customer_age"
                                       placeholder=""
                                       ref="customer_age"
                                       value={this.state.customer_age}
                                       onChange={this.onInputChangeCustomer}
                                />
                            </div>

                            <div className="form-group col-sm-4">
                                <label htmlFor="customer_mobile">เบอร์โทรศัพท์</label>
                                <input type="text"
                                       className="form-control hidden"
                                       id="customer_mobile_old-bk"
                                       placeholder=""
                                       ref="customer_mobile_old-bk"
                                       // value={this.state.customer_mobile}
                                       // onChange={this.onInputChangeCustomer}
                                />

                                <MaskedInput
                                    mask="111-111-1111"
                                    id="customer_mobile"
                                    name="customer_mobile"
                                    ref="customer_mobile"
                                    placeholder=""
                                    className="form-control"
                                    value={this.state.customer_mobile}
                                    onChange={this.onInputChangeCustomer}
                                />
                            </div>

                            <div className="form-group col-sm-4">
                                <label htmlFor="customer_line">Line</label>
                                <input type="text"
                                       className="form-control"
                                       id="customer_line"
                                       placeholder=""
                                       ref="customer_line"
                                       value={this.state.customer_line}
                                       onChange={this.onInputChangeCustomer}
                                />
                            </div>

                            <div className="form-group col-sm-4">
                                <label htmlFor="customer_facebook">Facebook</label>
                                <input type="text"
                                       className="form-control"
                                       id="customer_facebook"
                                       placeholder=""
                                       ref="customer_facebook"
                                       value={this.state.customer_facebook}
                                       onChange={this.onInputChangeCustomer}
                                />
                            </div>

                          <div className="form-group col-sm-6">
                            <label htmlFor="receipt_date">อาชีพ</label>
                            <input type="text"
                                   className="form-control hidden"
                                   id="occupation_id"
                                   name="occupation_id"
                                   placeholder=""
                                   value={this.state.occupation_id}
                                   disabled={false}
                                   onChange={() => {}}
                            />
                            <div className="form-group row">
                              <div className="col-md-12">
                                <div className="input-group">
                                  <input type="text"
                                         className="form-control"
                                         placeholder=""
                                         value={this.state.occupation_id !== 0 ? this.state.occupation_name : '' }
                                         disabled={true}
                                  />
                                  <span className="input-group-btn">
                                            <button className="btn btn-secondary"
                                                    name="agent_find"
                                                    type="button"
                                                    style={styles.buttonHeight}
                                                    onClick={() => {
                                                      this.setState({
                                                        isOccupationModal: true
                                                      })
                                                    }}
                                            >
                                                <i className="icon-magnifier"/>
                                            </button>

                                        </span>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div className="form-group col-sm-6">
                            <label htmlFor="cost">รายได้</label>
                            <input type="text"
                                   className="form-control hidden "
                                   name="customer_income"
                                   placeholder=""
                                   ref="customer_income"
                                   value={this.state.customer_income}
                                   onChange={() => {}}
                            />
                            <NumberFormat
                              className="form-control"
                              thousandSeparator={true}
                              prefix={'฿'}
                              placeholder="รายได้"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              allowNegative={false}
                              value={this.state.customer_income}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({customer_income: value})
                              }}
                            />
                          </div>


                          <div className="form-group col-sm-4">
                            <label htmlFor="province">จังหวัด</label>
                            <select className="form-control"
                                    name="province_id"
                                   value={this.state.province_id}
                                    onChange={(e) => {
                                       this.setState({
                                         province_id: e.target.value
                                       })
                                    }}
                            >
                              <option key={0} value={0}>ไม่ระบุ</option>
                              {province_list}
                            </select>
                          </div>

                            <div className="form-group col-sm-4">
                                <label htmlFor="employee">เซลล์ผู้ดูแล</label>
                                <select className="form-control"
                                        name="employee_id"
                                        value={this.state.employee_id}
                                        onChange={(e) => {
                                            this.setState({
                                                employee_id: e.target.value
                                            })
                                        }}
                                >
                                    <option key={0} value={0}>ไม่ระบุ</option>
                                    {employee_list}
                                </select>
                            </div>

                            <div className="form-group col-sm-4">
                                <label htmlFor="customer_date_book_text">วันที่จอง ว/ด/ป (พ.ศ.)</label>
                                <MaskedInput
                                    mask="11-11-1111"
                                    name="customer_date_book_text"
                                    placeholder="dd-mm-yyyy"
                                    onChange={this.onMaskedDateCustomerEditDateBook}
                                    className="form-control"
                                    value={this.state.customer_date_book_mask}
                                />
                                <input className="form-control hidden"
                                       type="text"
                                       id="customer_date_book"
                                       name="customer_date_book"
                                       ref="customer_date_book"
                                       value={this.state.customer_date_book} onChange={()=>{}}
                                />
                            </div>

                            <div className="form-group col-sm-12">
                                <label htmlFor="customer_note">หมายเหตุ</label>
                                <input type="text"
                                       className="form-control"
                                       id="customer_note"
                                       placeholder=""
                                       ref="customer_note"
                                       value={this.state.customer_note || ''}
                                       onChange={this.onInputChangeCustomer}
                                />
                            </div>


                        </div>

                      <div className="row">
                        <div className="col-sm-12">
                          <hr />
                        </div>
                        <div className="col-sm-12">
                          <strong>ข้อมูลผู้ค้ำประกัน</strong>
                        </div>
                        <div className="form-group col-sm-12" style={{marginTop: 20}}>
                          <label htmlFor="guarantor_name">ชื่อ-นามสกุลผู้ค้ำ</label>
                          <input type="text"
                                 className="form-control"
                                 id="guarantor_name"
                                 placeholder=""
                                 ref="guarantor_name"
                                 value={this.state.guarantor_name || ''}
                                 onChange={this.onInputChangeCustomer}
                          />
                        </div>
                        <div className="form-group col-sm-12">
                          <label htmlFor="guarantor_address">ที่อยู่ผู้ค้ำ</label>
                          <input type="text"
                                 className="form-control"
                                 id="guarantor_address"
                                 placeholder=""
                                 ref="guarantor_address"
                                 value={this.state.guarantor_address || ''}
                                 onChange={this.onInputChangeCustomer}
                          />
                        </div>
                        <div className="form-group col-sm-12">
                          <label htmlFor="guarantor_mobile">เบอร์โทรผู้ค้ำ</label>
                          <input type="text"
                                 className="form-control hidden"
                                 id="guarantor_mobile_old-bk"
                                 placeholder=""
                                 ref="guarantor_mobile_old-bk"
                                 // value={this.state.guarantor_mobile || ''}
                                 // onChange={this.onInputChangeCustomer}
                          />

                            <MaskedInput
                                mask="111-111-1111"
                                id="guarantor_mobile"
                                name="guarantor_mobile"
                                ref="guarantor_mobile"
                                placeholder=""
                                className="form-control"
                                value={this.state.guarantor_mobile || ''}
                                onChange={this.onInputChangeCustomer}
                            />
                        </div>
                      </div>
                    </div>
                    <div className="card-footer text-right">

                      <button type="reset"
                              onClick={this.props.onToggle}
                              className="btn btn-sm btn-danger mr-2">
                        <i className="fa fa-close"/> ปิดหน้านี้
                      </button>

                        <button type="submit"
                                className="btn btn-sm btn-warning">
                            <i className="fa fa-save"/> แก้ไข
                        </button>
                    </div>
                </form>
            </div>

        )
    }
}

const styles = {
  buttonHeight: {
    height: 40,
  }
}
