import React , { Component } from 'react';
import axios from "axios";
import {APIURL, CLOUDFRONT_URL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL} from "../../config/config";
import * as moment from "moment";
import NumberFormat from "react-number-format";
import {checkGroupPermission} from "../../services/grouppermission";
import {AlertError} from "../Alert/Alert";
import ReactToPrint, {PrintContextConsumer} from "react-to-print";
import {Link, Redirect} from "react-router-dom";

const port = Number(window.env.PORT);

class CarRequestRepair extends Component{

    constructor (props) {
        super(props);
        this.state = {
            car_repair_list: [],
            preview_image_url: '',
            preview_image_select: false,
            selected_car_id: null
        }

        this.loadCarRequestRepair = this.loadCarRequestRepair.bind(this);
        this.onPreviewSelected = this.onPreviewSelected.bind(this);

    }

    componentDidMount() {
        this.loadCarRequestRepair()
    }

    onPreviewSelected(url){
        this.setState({
            preview_image_url: url,
            preview_image_select: true
        });
    }

     loadCarRequestRepair() {
         let username = this.props.user.user
         // console.log('user=', username)
         axios.get(APIURL + '/car_repair/request/'+username)
             .then(res => {
                 // console.log(res.data)
                 this.setState({car_repair_list: res.data})
             }).catch(error => {
             this.setState({car_repair_list: []})
         });
     }

    render() {

        let car_repair_list = this.state.car_repair_list.map((car_repair,index)=>{

            let image_url = ''
            if( car_repair.car_repair_file === 'nophoto.png') {
                image_url = IMAGE_NOPHOTO_URL
            } else {
                // check https
                if (car_repair.car_repair_file !== null) {
                    if (car_repair.car_repair_file.includes('https')) {
                        // image_url = car_repair.car_repair_file
                        // console.log('car_repair_key=',)
                        image_url = CLOUDFRONT_URL+car_repair.car_repair_key
                    }else{
                        image_url = IMAGE_FULL_URL + car_repair.car_repair_file
                    }
                }else{
                    image_url = IMAGE_NOPHOTO_URL
                }
            }

            let image_url2 = ''
            if( car_repair.car_repair_file2 === 'nophoto.png' || car_repair.car_repair_file2 === null) {
                image_url2 = IMAGE_NOPHOTO_URL
            } else {
                // check https
                if (car_repair.car_repair_file2 !== null) {
                    if (car_repair.car_repair_file2.includes('https')) {
                        // image_url2 = car_repair.car_repair_file2
                        image_url2 = CLOUDFRONT_URL+car_repair.car_repair_key2
                    }else{
                        image_url2 = IMAGE_FULL_URL + car_repair.car_repair_file2
                    }
                }else{
                    image_url2 = IMAGE_NOPHOTO_URL
                }
            }

            let spare_part_url = car_repair.spare_part_url

            if(image_url === IMAGE_NOPHOTO_URL && spare_part_url !== null) {
                image_url = car_repair.spare_part_url
            }

            return(
                <tr key={index} >
                    <td className="text-center">{index+1}</td>
                    <td className="text-left">
                        ชื่อรถ: {car_repair.car_name} <br/>
                        ทะเบียนใหม่: {car_repair.car_license_plate_new} <br/>
                        ทะเบียนเดิม: {car_repair.car_license_plate_old} <br/>
                        รายการซ่อม: {car_repair.car_repair_name} <br/>
                        ประเภท: {car_repair.repair_type_name}
                        <br/>
                        <span className="badge badge-danger" style={{backgroundColor: car_repair.repair_type_color}}>
              {car_repair.repair_type_name}
            </span>

                        {car_repair.car_repair_note !== null && car_repair.car_repair_note !== '' ?
                            (
                                <> <br/><font color='red'> หมายเหตุ : {car_repair.car_repair_note} </font> </>
                            )
                            : null }
                        <br/>
                        {
                            car_repair.center_name === 'เบิกอะไหล่' ?
                                (
                                    <> <br/>  <font color='red'> *** หมายเหตุเบิกอะไหล่ : {car_repair.out_note === '' ? '-' : car_repair.out_note} , *ผู้เบิก : {car_repair.requestor} </font> </>
                                ): null
                        }

                    </td>
                    <td className="text-center text-nowrap">
                        &nbsp;&nbsp;ส่งซ่อม : {moment(car_repair.car_repair_send).locale('th').format('LL')} <br/>
                        ซ่อมเสร็จ : {moment(car_repair.car_repair_complete).locale('th').format('LL')} <br/>
                        &nbsp;&nbsp;&nbsp;อู่ &nbsp;: {car_repair.center_name}
                    </td>

                    <td className={port === 9040 && this.props.user_type !== 'admin'? 'hidden' : "text-center"}>
                        <div className="row">
                            <div className="col-12">เอกสาร 1</div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <img
                                    src={image_url}
                                    style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                                    onClick={()=>this.onPreviewSelected(image_url)}
                                    alt="เอกสาร"
                                />
                            </div>
                        </div>

                        {
                            image_url2 === IMAGE_NOPHOTO_URL ? null : <>
                                <br/>
                                <div className="row text-center">
                                    <div className="col-12">
                                        เอกสาร 2
                                    </div>
                                </div>
                                <div className="row text-center">
                                    <div className="col-12">
                                        <img
                                            src={image_url2}
                                            style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                                            onClick={()=>this.onPreviewSelected(image_url2)}
                                            alt="เอกสาร"
                                        />
                                    </div>
                                </div>
                            </>
                        }

                    </td>

                    <td className={port === 9040 && this.props.user_type !== 'admin'? 'hidden' : "text-center"}>
                        <NumberFormat value={car_repair.car_repair_cost} displayType={'text'} thousandSeparator={true} prefix={'฿'} />
                    </td>

                    <td className="text-center text-nowrap">
                        {car_repair.fullname}
                        <br/>
                        {moment(car_repair.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}

                        <br/>
                        <span className="badge badge-danger">
                            {car_repair.car_repair_type}
                        </span>

                        <br/><br/>
                        {
                            car_repair.center_name === 'เบิกอะไหล่' ? (<font color='red'> *** เบิกอะไหล่ลบได้ที่เมนูข้อมูลอะไหล่ </font>): null
                        }
                    </td>
                    <td className="text-center">

                       {/*{
                            checkGroupPermission(3, this.props.grouppermissions).modified ? (
                                <>
                                    <button className="btn btn-sm btn-warning btn-md-width" style={{width: 100}}
                                            onClick={()=>{
                                                // if (sell_status_check) {
                                                //     AlertError('ไม่สามารถแก้ไขค่าซ่อมได้ เนื่องจากปิดการขายแล้ว');
                                                //     return
                                                // }
                                                this.props.onCarRepairEditSelected(car_repair)
                                            }}
                                    >
                                        <i className="fa fa-pencil"/> แก้ไข
                                    </button>
                                    <br/>
                                </>
                            ) : null
                        }*/}

                        {
                            checkGroupPermission(3, this.props.grouppermissions).modified ? (
                                <>
                                    <button onClick={() => {}} type="button"
                                            className={'btn btn-primary btn-sm btn-block'}
                                    >
                                        <Link to={"/car-repair/new?car_id="+car_repair.car_id+"&is_after_sell=0"}
                                              target='_blank'
                                              style={{color: 'white'}}
                                        >
                                            <i className="icon-list">
                                                &nbsp;จัดการ</i>
                                        </Link>
                                    </button>
                                </>
                            ) : null
                        }

                       {/* {
                            checkGroupPermission(3, this.props.grouppermissions).deleted ? (
                                <button className="btn btn-sm btn-danger btn-md-width mt-2" style={{width: 100}}
                                        onClick={()=> {
                                            if (sell_status_check) {
                                                AlertError('ไม่สามารถลบค่าซ่อมได้ เนื่องจากปิดการขายแล้ว');
                                                return
                                            }
                                            this.onCarRepairDelete(car_repair)
                                        }}
                                        disabled={car_repair.car_repair_type === 'เบิกอะไหล่'}
                                >
                                    <i className="fa fa-remove"/> ลบ
                                </button>
                            ): null
                        }*/}



                        {/*{
                            checkGroupPermission(3, this.props.grouppermissions).printed ? (
                                <ReactToPrint
                                    content={() => this.componentRef}
                                >
                                    <PrintContextConsumer>
                                        {({ handlePrint }) => (
                                            <button className={port === 9040 && this.props.user_type !== 'admin'? 'hidden' : "btn btn-sm btn-primary btn-md-width mt-2" }
                                                    style={{width: 100}} onClick={()=> this.handlePrintImage(image_url, handlePrint)}>
                                                <i className="fa fa-print"/> พิมพ์
                                            </button>
                                        )}
                                    </PrintContextConsumer>
                                </ReactToPrint>
                            ) : null

                        }*/}

                       {/* {
                            checkGroupPermission(3, this.props.grouppermissions).modified === 0
                            && checkGroupPermission(3, this.props.grouppermissions).deleted === 0
                            && checkGroupPermission(3, this.props.grouppermissions).printed === 0
                                ? <i className="icon-close"/> : null
                        }
                        <i className="icon-close"/>*/}



                    </td>
                </tr>
            )
        })
        return (
            <div>
                <table className={(this.state.car_repair_list.length>0)?'table table-striped table-responsive ' : 'table table-striped hidden'}>
                    <thead>
                    <tr>
                        <th className="text-center">#</th>
                        <th className="text-center" style={{width: '35%'}}>ชื่อรายการ</th>
                        <th className="text-center text-nowrap">วันที่ส่งซ่อม/ซ่อมเสร็จ</th>
                        <th className={ "text-center"}>เอกสารแนบ</th>

                        <th className={ "text-center"}>ราคา</th>
                        <th className="text-center text-nowrap">ผู้ทำรายการ</th>
                        <th className={ "text-center"}>จัดการ</th>
                    </tr>
                    </thead>
                    <tbody>
                    {car_repair_list}
                    </tbody>
                </table>

                <div className={this.state.car_repair_list.length ===0 ?' text-center  ' : 'hidden'} >
                    <strong className="text-danger">ไม่พบรายการซ่อม</strong>
                </div>
            </div>
        )
    }
}

export default CarRequestRepair;
