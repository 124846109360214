import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios/index'
import { APIURL } from '../../../config/config'

class ProfileForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      profile_id:'',
      profile_from: '',
      profile_address: '',
      profile_fn1: '',
      profile_fn2: '',
      profile_fn3: '',
      profile_name: '',
      profile_ncb1: '',
      profile_guarantor1: '',
      profile_guarantor_ncb1: '',
      profile_guarantor2: '',
      profile_guarantor_ncb2: '',
      customer_select: false,
      customer_list: [],
      customer: {},
      customer_id: '',
      profile_froms: []
    }
    this.onChangedProfileFrom = this.onChangedProfileFrom.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.loadProfileFrom = this.loadProfileFrom.bind(this);
    this.getRenderProfileFrom = this.getRenderProfileFrom.bind(this);
    this.handleChangeProfile = this.handleChangeProfile.bind(this);
  }

  componentDidMount() {


    this.loadProfileFrom()

    let customer_id = this.props.customer_id

    // console.log('componentDidMount this.props.form_status=', this.props.form_status)
    // console.log('customer_id='+customer_id);

    if(customer_id!==''){
      // load customer by id
      this.getDataCustomerById(customer_id);
    }

    if(this.props.form_status === 'edit') {
      this.setEditToForm();
    }else  if(this.props.form_status === 'add') {
      this.setState({profile_from: 'เพจ'});
    }
  }

 /* componentWillReceiveProps(nextProps) {
    console.log(nextProps)
    if(nextProps.profile_selected !== this.props.profile_selected) {
      console.log('===componentWillReceiveProps nextProps')
      console.log(nextProps.profile_selected)
    }
  } */

  loadProfileFrom() {
    axios.get(APIURL + '/profile_from')
      .then(res => {
        this.setState({profile_froms: res.data})
      }).catch(error => {
      this.setState({profile_froms: []})
    })
  }

  setEditToForm() {

    let profile = this.props.profile_selected

    this.setState({
      profile_id: profile.profile_id,
      profile_from: profile.profile_from === null || profile.profile_from === '' ? '' : profile.profile_from,
      profile_address: profile.profile_address === null ||  profile.profile_address === '' ? '' : profile.profile_address ,
      profile_fn1: profile.profile_fn1 === null ||  profile.profile_fn1 === '' ? '' : profile.profile_fn1,
      profile_fn2: profile.profile_fn2 === null ||  profile.profile_fn2 === '' ? '' : profile.profile_fn2 ,
      profile_fn3: profile.profile_fn3 === null ||  profile.profile_fn3 === '' ? '' : profile.profile_fn3,
      profile_name: profile.profile_name === null ||  profile.profile_name === '' ? '' : profile.profile_name ,
      profile_ncb1: profile.profile_ncb1 === null ||  profile.profile_ncb1 === '' ? '' : profile.profile_ncb1,
      profile_guarantor1: profile.profile_guarantor1 === null ||  profile.profile_guarantor1 === '' ? '' : profile.profile_guarantor1,
      profile_guarantor_ncb1: profile.profile_guarantor_ncb1 === null ||  profile.profile_guarantor_ncb1 === '' ? '' : profile.profile_guarantor_ncb1,
      profile_guarantor2: profile.profile_guarantor2 === null ||  profile.profile_guarantor2 === '' ? '' : profile.profile_guarantor2,
      profile_guarantor_ncb2: profile.profile_guarantor_ncb2 === null ||  profile.profile_guarantor_ncb2 === '' ? '' : profile.profile_guarantor_ncb2,
    });
  }

  getDataCustomerById (customer_id) {
    axios.get(APIURL + '/customer/' + customer_id)
      .then(res => {
        if(res.data.length > 0) {
          this.setState({customer: res.data[0], customer_select: true})
        }
        //alert (res.data)
      }).catch(error => {
      this.setState({customer: {}})
    })
  }

  onChangedProfileFrom(e) {
    let profile_from = e.currentTarget.value
    this.setState({
      profile_from: profile_from
    });
  }

  handleChange(event) {
    let targetName = event.target.name;
    let value = event.target.value;

    if(targetName === 'profile_address'){
      this.setState({ profile_address: value });
    } else if(targetName === 'profile_fn1'){
      this.setState({ profile_fn1: value });
    } else if(targetName === 'profile_fn2'){
      this.setState({ profile_fn2: value });
    } else if(targetName === 'profile_fn3'){
      this.setState({ profile_fn3: value });
    } else if(targetName === 'profile_name'){
      this.setState({ profile_name: value });
    } else if(targetName === 'profile_ncb1'){
      this.setState({ profile_ncb1: value });
    } else if(targetName === 'profile_guarantor1'){
      this.setState({ profile_guarantor1: value });
    } else if(targetName === 'profile_guarantor_ncb1'){
      this.setState({ profile_guarantor_ncb1: value });
    } else if(targetName === 'profile_guarantor2'){
      this.setState({ profile_guarantor2: value });
    } else if(targetName === 'profile_guarantor_ncb2'){
      this.setState({ profile_guarantor_ncb2: value });
    }

  }

  searchCustomer () {
    let search_text = this.refs.search_customer_name.value
    //alert(search_text);
    this.getDataCustomerSearch(search_text)
  }

  getDataCustomerSearch (search_text) {
    axios.get(APIURL + '/customer/search/' + search_text)
      .then(res => {
        this.setState({customer_list: res.data})
      }).catch(error => {
      this.setState({customer_list: []})
    })
  }

  onCustomerSelect (customer, event) {
    this.setState({
      customer_select: true,
      customer: customer,
      customer_id: customer.customer_id
    });
  }

  handleChangeProfile(event) {
    let targetName = event.target.name;
    let value = event.target.value;
    this.setState({ [targetName]: value });
  }

  getRenderProfileFrom(){
    let profile_froms = this.state.profile_froms

    let profile_froms_radio =  profile_froms.map((profile_from, index) => {
      let profile_from_name = profile_from.profile_from_name
      return <div style={{minWidth: 40, padding: 6}} key={index}>
        <input type="radio"
               name="profile_from"
               value={profile_from_name}
               className="btn btn-primary"
               checked={this.state.profile_from === profile_from_name}
               onChange={this.handleChangeProfile}
        /> &nbsp;<span style={{marginTop: 6}}>{profile_from_name}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    });

    return profile_froms_radio

  }

  render() {

    var search_customer_list = this.state.customer_list.map((customer_list, index) => {
      return (
        <tr key={index}>
          <td><strong>ชื่อ</strong> &nbsp;&nbsp; {customer_list.customer_name}</td>
          <td><strong>นามสกุล</strong> &nbsp;&nbsp; {customer_list.customer_lastname}</td>
          <td><strong>เลขประจำตัวประชาชน</strong> &nbsp;&nbsp; {customer_list.customer_card_id}</td>
          <td><strong>เบอร์โทรศัพท์</strong> &nbsp;&nbsp; {customer_list.customer_mobile}</td>
          <td>
            <button onClick={() => this.onCustomerSelect(customer_list, this)} type="button"
                    className="btn btn-primary">
              <i className="fa fa-hand-pointer-o"></i>&nbsp;&nbsp; เลือก
            </button>
          </td>
        </tr>
      )
    })

    return (
      <div className="card card-accent-primary">
        <form action="" method="post" onSubmit={this.props.onSubmit}>
          <div className="card-header">
            <strong className="text-title">ข้อมูลโปรไฟล์ </strong>
          </div>
          <div className="card-block">

            <div className={!this.state.customer_select ? 'row ' : ' hidden'}>
              <div className="controls col-md-12">
                <div className="input-group has-success">
                  <input onChange={this.searchCustomer.bind(this)}
                         ref="search_customer_name"
                         placeholder="ค้นหาชื่อลูกค้า"
                         className="form-control"
                         size="16"
                         type="text"/>

                  <span className="input-group-btn ">
                    <button className="btn btn-success"
                            type="button"
                            onClick={this.searchCustomer.bind(this)}>ค้นหา
                    </button>
                  </span>
                </div>

                <div id="show_customer" className="row">
                  <div className="controls col-md-12">
                    <table className="table table-striped">
                      <tbody>
                      {search_customer_list}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className={this.state.customer_select ? 'row ' : ' hidden'}>

              <div className="card-header controls col-md-12 hidden ">
                <strong className="text-title">ข้อมูลลูกค้า</strong>
              </div>

              <div className="card-block has-success">
                <div className="row">
                  <div className="col-lg-12">
                    <div className=" form-group row" style={styles.bottomLine}>
                      <label className="col-md-3"><strong> ชื่อลูกค้า </strong></label>
                      <div className="col-md-3">
                        <p className="text-justify text-success ">
                          <strong>{this.state.customer.customer_name}</strong></p>
                      </div>

                      <label className="col-md-3"><strong> นามสกุล </strong></label>
                      <div className="col-md-3">
                        <p className="text-justify text-success">
                          <strong> {this.state.customer.customer_lastname} </strong></p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className=" form-group row" style={styles.bottomLine}>
                      <label className="col-md-3"><strong>
                        เลขบัตรประชาชน </strong></label>
                      <div className="col-md-3">
                        <p className="text-justify text-success">
                          <strong>{this.state.customer.customer_card_id}</strong></p>
                      </div>

                      <label className="col-md-3"><strong>
                        เบอร์โทรศัพท์ </strong></label>
                      <div className="col-md-3">
                        <p className="text-justify text-success">
                          <strong> {this.state.customer.customer_mobile} </strong></p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className=" form-group row" style={styles.bottomLine}>
                      <label className="col-md-3"><strong>
                        ที่อยู่ปัจจุบัน </strong></label>
                      <div className="col-md-9">
                        <p className="text-justify text-success">
                          <strong>{this.state.customer.customer_address}</strong></p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


            </div>


            <div className="row">
              {/*<div className="btn-group colors col-sm-12" style={{height: 40, padding: 10}}>
                <input type="radio"
                       name="profile_from"
                       value="เพจ"
                       className="btn btn-primary"
                       checked={this.state.profile_from === 'เพจ'}
                       onChange={this.onChangedProfileFrom}
                /> &nbsp;<span style={{marginTop: 6}}>เพจ</span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <input type="radio"
                       name="profile_from"
                       value="Web"
                       className="btn btn-primary"
                       checked={this.state.profile_from === 'Web'}
                       onChange={this.onChangedProfileFrom}
                /> &nbsp;<span style={{marginTop: 6}}>Web</span>
                 &nbsp;&nbsp;&nbsp;&nbsp;
                 <input type="radio"
                        name="profile_from"
                        value="Google"
                        className="btn btn-primary"
                        checked={this.state.profile_from === 'Google'}
                        onChange={this.onChangedProfileFrom}
                 /> &nbsp;<span style={{marginTop: 6}}>Google</span>
                 &nbsp;&nbsp;&nbsp;&nbsp;
                 <input type="radio"
                        name="profile_from"
                        value="One2Car"
                        className="btn btn-primary"
                        checked={this.state.profile_from === 'One2Car'}
                        onChange={this.onChangedProfileFrom}
                 /> &nbsp;<span style={{marginTop: 6}}>One2Car</span>
                 &nbsp;&nbsp;&nbsp;&nbsp;
                 <input type="radio"
                        name="profile_from"
                        value="Freelance(Line)"
                        className="btn btn-primary"
                        checked={this.state.profile_from === 'Freelance(Line)'}
                        onChange={this.onChangedProfileFrom}
                 /> &nbsp;<span style={{marginTop: 6}}>Freelance(Line)</span>
                 &nbsp;&nbsp;&nbsp;&nbsp;
                 <input type="radio"
                        name="profile_from"
                        value="หน้าร้าน"
                        className="btn btn-primary"
                        checked={this.state.profile_from === 'หน้าร้าน'}
                        onChange={this.onChangedProfileFrom}
                 /> &nbsp;<span style={{marginTop: 6}}>หน้าร้าน</span>
                 &nbsp;&nbsp;&nbsp;&nbsp;
                 <input type="radio"
                        name="profile_from"
                        value="แนะนำ"
                        className="btn btn-primary"
                        checked={this.state.profile_from === 'แนะนำ'}
                        onChange={this.onChangedProfileFrom}
                 /> &nbsp;<span style={{marginTop: 6}}>แนะนำ</span>
                 &nbsp;&nbsp;&nbsp;&nbsp;
                 <input type="radio"
                        name="profile_from"
                        value="นายหน้า"
                        className="btn btn-primary"
                        checked={this.state.profile_from === 'นายหน้า'}
                        onChange={this.onChangedProfileFrom}
                 /> &nbsp;<span style={{marginTop: 6}}>นายหน้า</span>



                 {
                   this.state.user.type === 'admin' ?
                     <button className="btn btn-primary"
                             type="button"
                             onClick={() => {
                               this.setState({
                                 is_open_profile_from_modal: true
                               })
                             }}
                     >
                       +
                     </button>
                     : null
                 }

                </div>*/}
              <div className="btn-group colors col-sm-12" style={styles.bottomLineAndMargin}>
                {this.getRenderProfileFrom()}
              </div>
            </div>


            <div className="row mt-3">
              <div className="form-group form-inline col-sm-12">
                <label htmlFor="profile_address form-inline"> ลูกค้ามาจาก(ที่อยู่) :&nbsp;&nbsp;</label>
                <input type="text"
                       className="form-control form-inline form-check"
                       style={{width: '80%'}}
                       name="profile_address"
                       maxLength={150}
                       placeholder=""
                       value={this.state.profile_address}
                       onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group form-inline col-sm-4">
                <label htmlFor="profile_address form-inline"> F/N :&nbsp;&nbsp;</label>
                <input type="text"
                       className="form-control form-inline form-check"
                       name="profile_fn1"
                       maxLength={100}
                       placeholder=""
                       value={this.state.profile_fn1}
                       onChange={this.handleChange}
                />
              </div>
              <div className="form-group form-inline col-sm-4">
                <label htmlFor="profile_address form-inline"> F/N :&nbsp;&nbsp;</label>
                <input type="text"
                       className="form-control form-inline form-check"
                       name="profile_fn2"
                       maxLength={100}
                       placeholder=""
                       value={this.state.profile_fn2}
                       onChange={this.handleChange}
                />
              </div>
              <div className="form-group form-inline col-sm-4">
                <label htmlFor="profile_address form-inline"> F/N :&nbsp;&nbsp;</label>
                <input type="text"
                       className="form-control form-inline form-check"
                       name="profile_fn3"
                       maxLength={100}
                       placeholder=""
                       value={this.state.profile_fn3}
                       onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="profile_name">โปรไฟล์ลูกค้า</label>
                <textarea
                name="profile_name"
                className="form-control"
                value={this.state.profile_name}
                onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="profile_ncb1">NCB</label>
                <input type="text"
                       className="form-control"
                       name="profile_ncb1"
                       placeholder=""
                       maxLength={150}
                       value={this.state.profile_ncb1}
                       onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="profile_guarantor1">ค้ำ 1</label>
                <input type="text"
                       className="form-control"
                       name="profile_guarantor1"
                       placeholder=""
                       maxLength={150}
                       value={this.state.profile_guarantor1}
                       onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="profile_guarantor_ncb1">ค้ำ 1 NCB</label>
                <input type="text"
                       className="form-control"
                       name="profile_guarantor_ncb1"
                       placeholder=""
                       maxLength={150}
                       value={this.state.profile_guarantor_ncb1}
                       onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="profile_guarantor2">ค้ำ 2</label>
                <input type="text"
                       className="form-control"
                       name="profile_guarantor2"
                       placeholder=""
                       maxLength={150}
                       value={this.state.profile_guarantor2}
                       onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-sm-12">
                <label htmlFor="profile_guarantor_ncb2">ค้ำ 2 NCB</label>
                <input type="text"
                       className="form-control"
                       name="profile_guarantor_ncb2"
                       placeholder=""
                       maxLength={150}
                       value={this.state.profile_guarantor_ncb2}
                       onChange={this.handleChange}
                />
              </div>
            </div>

          </div>
          <div className="card-footer text-right">
            <button type="reset"
                    onClick={this.props.onToggle}
                    className="btn btn-sm btn-danger mr-2">
              <i className="fa fa-refresh"/> ยกเลิก
            </button>
            <button type="submit"
                    className={this.props.form_status==='add'? "btn btn-sm btn-primary" :  "btn btn-sm btn-warning" }>
              <i className="fa fa-save"/>
              {this.props.form_status==='add'? ' บันทึก' : ' แก้ไข'}
            </button>
            <input
              type="hidden"
              name="customer_id"
              value={this.state.customer_id}
            />
            <input
              type="hidden"
              name="profile_id"
              value={this.state.profile_id}
            />
          </div>
        </form>
      </div>
    );
  }
}

const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  },
  bottomLineAndMargin: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
    paddingBottom: 8
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ProfileForm)
