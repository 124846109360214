import React,{Component} from 'react'
import axios from 'axios';
import { APIURL } from '../../config/config';
import NumberFormat from 'react-number-format'

const port = window.env.PORT;

// user_type
class CarLeasingViewCarOk extends Component {
    constructor (props){
        super(props)
        this.state = {
            lists: [],
            car: this.props.car,
            car_id : this.props.car.car_id,
            // leasingName: ['SCB', 'GE', 'TA', 'KK', 'TIS']
            // leasingName: ['AYCAL', 'TBANK', 'TISCO', 'CIMB', 'SCB']
            leasingName: []
        }
    }

    async componentDidMount() {
        this.mounted = true
        await this.loadCarLeasingForm();
        await this.loadCarLeasing();
    }

    componentWillUnmount() {
        this.mounted = false
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.carLeasingList.length > 0){
            //console.log('===nextProps car_id=' + nextProps.car.car_id)
            if(nextProps.carLeasingList[0].car_id === nextProps.car.car_id){
                this.setState({
                    lists: nextProps.carLeasingList
                });
            }
        }

        if(nextProps.car !== this.props.car) {
            this.setState({
                car_id: nextProps.car.car_id,
                car: nextProps.car,
            },()=>{
                this.loadCarLeasing();
            });
        }
    }

    loadCarLeasingForm() {
        var url = APIURL + '/leasing_setting/';
        axios.get(url)
            .then(res => {
                let leasingName = []
                res.data.forEach(leasing => {
                    leasingName.push(leasing.name_credit)
                })

                if(this.mounted) {
                    this.setState({leasingName: leasingName})
                }

            });
    }

    loadCarLeasing() {
        //console.log('===loadCarLeasing car_id=' + this.state.car_id)
        let uri =  APIURL + '/car_leasing/'+this.state.car_id;
        //console.log(uri)

        axios.get(uri)
            .then(res => {
                if(this.mounted) {
                    this.setState({ lists: res.data })
                }
            }).catch(error => {
            if(this.mounted) {
                this.setState({ lists: [] })
            }
        })
    }

    render(){
        const {
            car_id,
            car,
            lists
        } = this.state

        let bank_change_books = this.props.bank_change_books
        let bank_car_adapts = this.props.bank_car_adapts

        if(this.state.lists.length === 0){
            // let trLeasing = this.state.leasingName.map((leasing, index) => {
            //     return (
            //         <tr key={index}>
            //             <td style={{width: 100}}>{leasing}</td>
            //             <td style={{width: 100}}>&nbsp;-&nbsp;</td>
            //             <td style={{width: 100}}>&nbsp;-&nbsp;</td>
            //             <td style={{width: 100}}>&nbsp;-&nbsp;</td>
            //         </tr>
            //     )
            // })
            return (
                <div>
                    <table className="tableWhitesmoke" style={{marginTop: 8}}>
                        <tbody>
                         <tr>
                             <td>&nbsp;&nbsp;&nbsp; ไม่พบข้อมูลราคากลาง &nbsp;&nbsp;&nbsp;</td>
                         </tr>
                        </tbody>
                    </table>
                </div>
            )
        }else {
            let trLeasing = this.state.lists.map((leasing, index) => {
                //if(leasing.car_leasing_name!=='MPRICE') {
                //  <td style={{width: 100}}>
                //                             <NumberFormat
                //                                 value={leasing.car_leasing_price} displayType={'text'} thousandSeparator={true}
                //                                 prefix={'฿'}/>
                //                         </td>
                //                         <td style={{width: 100}}>
                //                             <NumberFormat
                //                                 value={leasing.car_leasing_price_nc} displayType={'text'} thousandSeparator={true}
                //                                 prefix={'฿'}/>
                //                         </td>
                let bank_change_book_filter = bank_change_books.filter(bank_change_book => bank_change_book.car_leasing_name === leasing.car_leasing_name)
                let bank_change_book_text = ''
                bank_change_book_filter.map((bank_change_book, index2) => {
                    bank_change_book_text += bank_change_book.change_book_status_name
                    if (index2 !== bank_change_book_filter.length - 1) {
                        bank_change_book_text += ', '
                    }
                })

                let bank_car_adapt_filter = bank_car_adapts.filter(bank_car_adapt => bank_car_adapt.car_leasing_name === leasing.car_leasing_name)
                let bank_car_adapt_text = ''
                bank_car_adapt_filter.map((bank_car_adapt, index3) => {
                    bank_car_adapt_text += bank_car_adapt.car_adapt_status_name
                    if (index3 !== bank_car_adapt_filter.length - 1) {
                        bank_car_adapt_text += ', '
                    }
                })

                return (
                    <tr key={index}>
                        <td style={{width: 100}}>{leasing.car_leasing_name}</td>
                        <td style={{width: 100}}>
                            <NumberFormat
                                value={leasing.car_leasing_price_median} displayType={'text'} thousandSeparator={true}
                                prefix={'฿'}/>
                        </td>
                        <td className="text-center text-nowrap " style={{width: 100}}>
                            {bank_change_book_text}
                        </td>
                        <td className="text-center text-nowrap " style={{width: 100}}>
                            {bank_car_adapt_text}
                        </td>
                    </tr>
                )
            })

            return (
                <div>
                    <hr/>
                    <div>
                     จัดกับไฟแนนซ์ : {car.finance_leasing === null ? '-' : car.finance_leasing} <br/>
                     เงื่อนไขการจัด : {car.finance_note === null ? '-' : car.finance_note}
                    </div>

                    <table className="tableWhitesmoke" style={{marginTop: 8}}>
                        <thead>
                        <tr>
                            <th>ธนาคาร</th>
                            <th>ราคากลาง</th>
                            <th className="text-center"> เงื่อนไขไม่รับจัด(เล่ม) </th>
                            <th className="text-center"> เงื่อนไขไม่รับจัด(ดัดแปลง) </th>
                        </tr>

                        </thead>
                        <tbody>
                        {trLeasing}
                        </tbody>
                    </table>
                </div>
            )
        }
    }
}

/*const styles = {
  tableLeasing: {
    backgroundColor: 'tableWhitesmoke'
  }
}*/

export default CarLeasingViewCarOk ;
