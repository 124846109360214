import React, { Component } from 'react';
import axios from "axios";
import * as XLSX from "xlsx-js-style";
import * as FileSaver from "file-saver";
import { APIURL, PORT } from '../../config/config'
import {MonthUtil} from "../../utils/monthUtil";
import Loading from "../Loading";
import {AlertWarning} from "../Alert/Alert";
import {NumberUtil} from "../../utils/number-util";
import {format} from "date-fns";
import {ExcelUtil} from "../../utils/excelUtil";

class ExpenseMonthReport extends Component{
    constructor (props) {
        super(props);
        this.state = {
            branches: [],
            month_year_list: [],
            isLoading: false,
            expense_month_year: '',
            branch_id: '',
            expense_list: [],
            company_setting: null
        }
        this.loadBranchData = this.loadBranchData.bind(this)
        this.loadExpenseMonthList = this.loadExpenseMonthList.bind(this)
        this.loadReportExpenseMonth = this.loadReportExpenseMonth.bind(this)
        this.loadCompanySetting = this.loadCompanySetting.bind(this)
        this.getReportHeader = this.getReportHeader.bind(this)
        this.getReportSubHeader = this.getReportSubHeader.bind(this)
        this.exportExpenseMonth = this.exportExpenseMonth.bind(this)
    }

    componentDidMount () {
        this.loadCompanySetting();
        this.loadBranchData();
        this.loadExpenseMonthList();
    }

    loadCompanySetting() {
        axios.get(APIURL + '/company_setting/1').then(res => {
            let data = res.data
            let company_setting = data.company_setting
            this.setState({
                company_setting: company_setting,
            })
        })
    }

    loadBranchData () {
        axios.get(APIURL + '/branch/')
            .then(res => {
                this.setState({branches: res.data})
            }).catch(error => {
            this.setState({branches: []})
        })
    }

    loadExpenseMonthList() {
        let uri = APIURL + '/expense/list/month'
        axios.get(uri)
            .then(res => {
                let months = res.data
                let month_year_list = months.map(month => {
                    let month_list_thai = MonthUtil.convertMonthYear(month.month_list)
                    return {
                        ...month,
                        month_list_thai: month_list_thai
                    }
                });

                this.setState({
                    month_year_list: month_year_list
                })

            }).catch(error => {
            console.log('error:', error)
        })
    }

  async loadReportExpenseMonth() {
        const {
            month_year_list,
            branch_id,
            expense_month_year
        } = this.state

        if(branch_id === '') {
            AlertWarning('กรุณาเลือกสาขา');
            return
        }

        if(expense_month_year === '') {
            AlertWarning('กรุณาเลือกเดือน');
            return
        }

        let month_year = month_year_list.filter(my => my.month_list === expense_month_year)
        let end_date = month_year[0].end_date
        let start_date = month_year[0].start_date

        let data = {
            end_date,
            start_date,
            branch_id
        }

        this.setState({
            isLoading: true
        })

      let url = APIURL + '/reportexpense/month'
      if (PORT === 9043) {
          url = APIURL + '/reportexpense/monthat'
      }

      await axios.post(url, data)
          .then(res => {

              this.setState({
                  expense_list: res.data
              });
              setTimeout(() => {
                  this.setState({
                      isLoading: false,
                  })
              }, 1200)
          }).catch(error => {
              console.log('error:', error)
          })
    }

    getReportHeader() {
        const {
            company_setting,
            branch_id,
            branches
        } = this.state

        let branch = branches.filter(b => b.branch_id === Number(branch_id))

        if (company_setting !== null && branch.length !== 0) {
            return (
                <div className="row mt-2">
                    <div className="col-12 text-md-center">
                        <h5><strong> {company_setting.company_name} ({branch[0].branch_name}) </strong></h5>
                    </div>
                </div>
            )
        }else if (company_setting !== null) {
            return (
                <div className="row mt-2">
                    <div className="col-12 text-md-center">
                        <h5><strong> {company_setting.company_name} </strong></h5>
                    </div>
                </div>
            )
        }

        return null
    }

    getReportSubHeader() {
        let month_year_list = this.state.month_year_list
        let expense_month_year = this.state.expense_month_year

        let month_year = month_year_list.filter(my => my.month_list === expense_month_year)
        let name = month_year.length === 1 ? month_year[0].month_list_thai : ' - '

        return (
            <div className="row">
                <div className="col-12 text-lg-center">
                    <h6>
                        สรุปยอดรายจ่ายทั่วไป/ต้นทุนรถ ประจำเดือน{name}
                    </h6>
                </div>
            </div>
        )
    }

    async clickExpenseMonth() {
        const {
            branch_id,
            expense_month_year,
            expense_list
        } = this.state

        if(branch_id === '') {
            AlertWarning('กรุณาเลือกสาขา');
            return
        }

        if(expense_month_year === '') {
            AlertWarning('กรุณาเลือกเดือน');
            return
        }

        if(expense_list.length === 0) {
            await this.loadReportExpenseMonth()
            this.exportExpenseMonth()
        } else {
            this.exportExpenseMonth()
        }


    }

    exportExpenseMonth() {
        const {
            month_year_list,
            branch_id,
            expense_month_year,
            company_setting,
            branches,
            expense_list
        } = this.state

        if(branch_id === '') {
            AlertWarning('กรุณาเลือกสาขา');
            return
        }

        if(expense_month_year === '') {
            AlertWarning('กรุณาเลือกเดือน');
            return
        }

        if(expense_list.length === 0) {
            AlertWarning('ไม่พบข้อมูล กรุณากดตกลงเพื่อค้นหาข้อมูล');
            return
        }

        let branch = branches.filter(b => b.branch_id === Number(branch_id))
        let month_year = month_year_list.filter(my => my.month_list === expense_month_year)
        let company_name = company_setting === null ? ' - ' : company_setting.company_name
        let month_name = month_year.length === 1 ? month_year[0].month_list_thai : ' - '

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        let curr = format(new Date(), 'dd/MM/yyyy HH:mm:ss')

        let expenseExcels = expense_list.map(expense => {
            return {
                A: expense.name,
                B: expense.cost_other === 0 ? '-' : expense.cost_other,
                C: expense.cost_car === 0 ? '-' : expense.cost_car,
                D: expense.cost_total === 0 ? '-' : expense.cost_total,
                E: expense.note,
            }
        })

        let sum_cost_other = expense_list.reduce((acc, expense) => acc+expense.cost_other, 0)
        let sum_cost_car = expense_list.reduce((acc, expense) => acc+expense.cost_car, 0)
        let sum_cost_total = expense_list.reduce((acc, expense) => acc+expense.cost_total, 0)

        let title1 = company_name + ' ('+branch[0].branch_name+')'
        let title2 = 'สรุปยอดรายจ่ายทั่วไป/ต้นทุนรถ ประจำเดือน'+month_name

        // { v: "Courier: 24", t: "s", s: { font: { name: "Courier", sz: 24 } } },
        const titleRow = [
            {
                A: { v: title1, t: "s", s: { font: { name: "AngsanaUPC", sz: 22, bold: true, }, alignment: {horizontal: 'center'} } }
            },
            {
                A: { v: title2, t: "s", s: { font: { name: "AngsanaUPC", sz: 18, bold: true, }, alignment: {horizontal: 'center'} } }
            },
            {
                A: 'วัน/เดือน/ปี',
                B: 'ค่าใช้จ่ายทั่วไป',
                C: 'ต้นทุนรถ',
                D: 'รวม',
                E: 'หมายเหตุ',
            }
        ]

        const sumRow = [
            {
                A: 'รวม',
                B: sum_cost_other,
                C: sum_cost_car,
                D: sum_cost_total,
                E: '',
            }
        ]

        const reportData = [...titleRow, ...expenseExcels, ...sumRow]

        this.setState({isLoading: true})

        setTimeout(() => {
            const ws = XLSX.utils.json_to_sheet(reportData, { origin: 'A1', skipHeader: true });

            let wscols = [
                {wch:24},
                {wch:18},
                {wch:18},
                {wch:18},
                {wch:18}
                ];

            let last_row = expense_list.length + 2
            let last_col = 5

            let wsrows = [];
            for(let  i=1 ; i <=last_row + 1; i++){
                if (i===1){
                    wsrows.push({hpt: 32})
                } else if (i===2){
                    wsrows.push({hpt: 26})
                } else {
                    wsrows.push({hpt: 21})
                }
            }

            const merge = [
                { s: { r: 0, c: 0 }, e: { r: 0, c: last_col-1 } },
                { s: { r: 1, c: 0 }, e: { r: 1, c: last_col-1 } },
            ];

            ws['!cols'] = wscols;
            ws['!rows'] = wsrows;
            ws["!merges"] = merge;

            let rangeTitle = { s: {r:2, c:0}, e: {r:2, c: last_col} };
            ExcelUtil.setCellBoldWithBorder(ws, rangeTitle);

            let rangeOrder = { s: {r:3, c:0}, e: {r:last_row, c:0} };
            ExcelUtil.setCellCenterNormalWithBorder(ws, rangeOrder);

            last_row = last_row +1

            let rangeNumber = { s: {r:3, c:1}, e: {r:last_row, c: last_col} };
            ExcelUtil.setCellNumberFormatWIthBorder(ws, rangeNumber);

            let rangeSum = { s: {r:last_row, c:0}, e: {r:last_row, c: last_col} };
            ExcelUtil.setCellTextRightBoldWithBorder(ws, rangeSum);

            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});

            let currFileName = format(new Date(), 'yyyy-MM-dd')
            let fileName = 'report_expense_month'+'_'+currFileName
            FileSaver.saveAs(data, fileName + fileExtension);

            this.setState({isLoading: false})

        }, 1200)

    }

    render() {
        const {
            branches,
            month_year_list,
            expense_list
        } = this.state;

        let branch_list = branches.map((branch,index)=>{
            return(
                <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
            )
        });

        let month_year_list_option = month_year_list.map((month_year, index) => (
            <option key={index} value={month_year.month_list}>{month_year.month_list_thai}</option>
        ));

        const emptyBody = <tr><td colSpan={5} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

        const expenseRows = expense_list.map((expense, index) => {

            return (
                <tr key={index}>
                    <td className="text-center">{expense.name}</td>
                    <td className="text-center">{expense.cost_other === 0 ? '-' : NumberUtil.addCommas(expense.cost_other)}</td>
                    <td className="text-center">{expense.cost_car === 0 ? '-' : NumberUtil.addCommas(expense.cost_car)}</td>
                    <td className="text-center">{expense.cost_total === 0 ? '-' : NumberUtil.addCommas(expense.cost_total)}</td>
                    <td className="text-center">{expense.note}</td>
                </tr>
            )
        })

        const tableRows = expense_list.length === 0 ? emptyBody : expenseRows

        let sum_cost_other = expense_list.reduce((acc, expense) => acc+expense.cost_other, 0)
        let sum_cost_car = expense_list.reduce((acc, expense) => acc+expense.cost_car, 0)
        let sum_cost_total = expense_list.reduce((acc, expense) => acc+expense.cost_total, 0)

        let expenseSumRows = expense_list.length === 0 ? null : <tr>
            <td className="text-center"><strong> รวม </strong> </td>
            <td className="text-center"><strong> { NumberUtil.addCommas(sum_cost_other) } </strong> </td>
            <td className="text-center"><strong> { NumberUtil.addCommas(sum_cost_car) } </strong> </td>
            <td className="text-center"><strong> { NumberUtil.addCommas(sum_cost_total) } </strong> </td>
            <td className="text-center">&nbsp;</td>
        </tr>

        return (
            <div>
                <Loading isLoading={this.state.isLoading} />


                        <div className="card card-accent-primary">
                            <div className="card-header">
                                <div className="row col-12 mt-1">
                                    <div className="col-6">
                                        <strong className="text-title">รายงานค่าใช้จ่ายรายเดือน</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="card-block" style={styles.overflowY}>
                                <div className="row">
                                    <div className="form-group col-3">
                                        <label htmlFor="branch_id">สาขา</label>
                                        <select className="form-control"
                                                name="branch_id"
                                                ref="branch_id"
                                                value={this.state.branch_id}
                                                onChange={(e) => {
                                                    let val = e.target.value
                                                    // let fixcost_settings = this.state.fixcost_settings
                                                    if(val ===  '') {
                                                        this.setState({
                                                            branch_id: val
                                                        });
                                                    } else if(this.state.expense_month_year !== ''){
                                                        this.setState({
                                                            branch_id: val
                                                        }, () => {
                                                            // this.loadFixCostData()
                                                        });
                                                    }else {
                                                        this.setState({
                                                            branch_id: val
                                                        });
                                                    }

                                                }}
                                        >
                                            <option value=''>เลือกสาขา</option>
                                            {branch_list}
                                        </select>
                                    </div>


                                    <div className="form-group col-3">
                                        <label htmlFor="expense_month_year">เลือกเดือน</label>
                                        <select
                                            className="form-control"
                                            name="expense_month_year"
                                            value={this.state.expense_month_year}
                                            onChange={(e) => {
                                                let month_list = e.target.value
                                                if(month_list !== '') {
                                                    // let month_year = month_year_list.filter(my => my.month_list === month_list)
                                                    // let end_date = month_year[0].end_date
                                                    this.setState({
                                                        expense_month_year: month_list,
                                                    });
                                                } else {
                                                    this.setState({
                                                        expense_month_year: month_list,
                                                    });
                                                }
                                            }}
                                        >
                                            <option value=''>กรุณาเลือกเดือน</option>
                                            {month_year_list_option}
                                        </select>
                                    </div>


                                    <div className="form-group col-2">
                                        <label htmlFor="button">&nbsp;</label>
                                        <br/>
                                        <button type="button"
                                                className="btn btn-sm btn-primary"
                                                onClick={() => {
                                                    this.loadReportExpenseMonth();
                                                }}
                                        >
                                            &nbsp; ตกลง &nbsp;
                                        </button>

                                        &nbsp;&nbsp;
                                        <button type="button"
                                                onClick={() => this.clickExpenseMonth()}
                                                className="btn btn-success float-right ml-2">
                                            Excel
                                        </button>
                                    </div>



                                </div>

                                {/*<div className="row">
                                    <div className="col-12">
                                        branch_id: {this.state.branch_id}
                                    </div>
                                </div>*/}
                                {
                                    this.getReportHeader()
                                }

                                {
                                    this.getReportSubHeader()
                                }

                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-striped table-responsive">
                                            <thead>
                                            <tr>
                                                <th style={{width: 15}} className="text-center text-nowrap">วัน/เดือน/ปี </th>
                                                <th style={{width: 30}} className="text-center text-nowrap">ค่าใช้จ่ายทั่วไป</th>
                                                <th style={{width: 25}} className="text-center text-nowrap">ต้นทุนรถ</th>
                                                <th style={{width: 25}} className="text-center text-nowrap">รวม</th>
                                                <th style={{width: 25}} className="text-center text-nowrap">หมายเหตุ</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                              {tableRows}
                                              {expenseSumRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>


            </div>
        )
    }
}

const styles = {
    overflowY: {
        overflow: 'scroll',
        width: '100%'
    }
}

export default ExpenseMonthReport
