import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import html2canvas from "html2canvas";
import { APIURL } from "../../../config/config";
import axios from "axios";
// import {getDocTypeConfig} from "../../../services/companysetting";


export  default class CarDownSchedule extends Component {

  constructor (props) {
    super(props)
    this.printRef = React.createRef();
    //this.handleOnSubmitCustomer.bind(this);
    this.state = {
      lists: [],
      load_data: true,
      danger: false,
      car_id: 0,
      action: 'บันทึก',
      car: this.props.car,
      company_setting: null,
      carBase64Img: '',
      logoBase64Img: '',
      carDownPercent : [],
      car_down_note : [],
      is_download: false,
      car_down_schedule_note: '',

    }

    this.togglePreviewImage = this.togglePreviewImage.bind(this)
    this.onPreviewSelected = this.onPreviewSelected.bind(this)
    this.handleDownloadImage = this.handleDownloadImage.bind(this)
    this.loadCompanySetting = this.loadCompanySetting.bind(this);
    this.loadCompanyLogoBase64 = this.loadCompanyLogoBase64.bind(this);
    this.loadCarImageBase64 = this.loadCarImageBase64.bind(this);
    //this.handleChangeInput = this.handleChangeInput.bind(this);
    //this.onInputChange = this.onInputChange.bind(this)
  }

  componentDidMount () {
    this.mounted = true

    this.loadCompanyLogoBase64();
    this.loadCompanySetting();
    //this.loadCarDownPercent();
    this.loadCarDownSchedule();
    this.loadCarImageBase64();

    this.setState({car_sale_price: this.state.car.car_sale_price})
  }

  componentWillUnmount() {
    this.mounted = false
  }

  loadCarDownPercent() {
    let url = APIURL + '/car_down_percent/';
    axios.get(url)
        .then(res => {
          if(this.mounted) {
            this.setState({ carDownPercent: res.data })
    }
    });
  }

  loadCarDownSchedule() {
    let car_id =this.props.car.car_id
    //console.log(this.props.car)
    let username = this.props.username
    //let car_down_schedule_note =this.props.car.car_down_schedule_note
    let uri =  APIURL + '/car_down_schedule/'+car_id;
    axios.get(uri)
        .then(res => {
          if(this.mounted) {
            let data = res.data
            let car_down_schedule_new = []

            let car_down_schedule_note = data.car_down_schedule_note

            if(data.car_down_schedule.length === 0){
              let car_down_percents = data.car_down_percent
              for(let i = 0 ; i < car_down_percents.length ; i++){
                let car_down_percent = car_down_percents[i]

                let down_schedule_new = {
                  car_id : car_id,
                  car_down_percent_name : car_down_percent.car_down_percent_name,
                  car_down_percent_id : car_down_percent.car_down_percent_id,
                  car_down_schedule_id : i+1,
                  car_down_schedule_price : '',
                  monthly_installment_48 : '',
                  monthly_installment_60 : '',
                  monthly_installment_72 : '',
                  monthly_installment_84 : '',
                  monthly_installment_96 : '',
                  user_created : username,
                  status_text : 'new',
                }
                car_down_schedule_new.push(down_schedule_new)
              }
            } else {
              let car_down_schedules = data.car_down_schedule
              for(let i=0; i < car_down_schedules.length  ; i++) {
                let car_down_schedule = car_down_schedules[i]
                let car_id = car_down_schedule.car_id
                let car_down_percent_name = car_down_schedule.car_down_percent_name
                let car_down_percent_id = car_down_schedule.car_down_percent_id
                let car_down_schedule_id = car_down_schedule.car_down_schedule_id
                let car_down_schedule_price = car_down_schedule.car_down_schedule_price
                let monthly_installment_48 = car_down_schedule.monthly_installment_48
                let monthly_installment_60 = car_down_schedule.monthly_installment_60
                let monthly_installment_72 = car_down_schedule.monthly_installment_72
                let monthly_installment_84 = car_down_schedule.monthly_installment_84
                let monthly_installment_96 = car_down_schedule.monthly_installment_96

                let down_schedule_new = {
                  car_id : car_id,
                  car_down_percent_name : car_down_percent_name,
                  car_down_percent_id : car_down_percent_id,
                  car_down_schedule_id : car_down_schedule_id,
                  car_down_schedule_price : car_down_schedule_price,
                  monthly_installment_48 : monthly_installment_48,
                  monthly_installment_60 : monthly_installment_60,
                  monthly_installment_72 : monthly_installment_72,
                  monthly_installment_84 : monthly_installment_84,
                  monthly_installment_96 : monthly_installment_96,
                  user_created : username,
                  status_text : 'exits',
                  //car_down_schedule_note : '',
                }
                //console.log('edit - car_down_schedule_note==', car_down_schedule_note)
                car_down_schedule_new.push(down_schedule_new)
              }
            }
            if(data.car_down_schedule.length === 0){
              this.setState({
                lists: car_down_schedule_new,
                carDownPercent: data.car_down_percent,
                car_down_schedule_note: car_down_schedule_note,
                action: 'add'
              })
            }else {
              this.setState({
                lists: car_down_schedule_new,
                carDownPercent: data.car_down_percent,
                car_down_schedule_note: car_down_schedule_note,
                action: 'edit'
              })
            }
          }
        }).catch(error => {
      if(this.mounted) {
        this.setState({ lists: [] })
      }
    })
  }

  onPreviewSelected(url){
    this.setState({
      preview_image_url: url,
      preview_image_select: true
    });
  }

  togglePreviewImage() {
    this.setState({
      preview_image_select: !this.state.preview_image_select
    });
  }

  async handleDownloadImage () {

    this.setState({
      is_download: true
    }, async () => {
      const element = this.printRef.current;
      const canvas = await html2canvas(element, { letterRendering: 1, allowTaint : true, useCORS: true });

      const data = canvas.toDataURL('image/jpg');
      const link = document.createElement('a');

      if (typeof link.download === 'string') {
        link.href = data;
        link.download = 'ตารางราคา.jpg';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }

      setTimeout(() => {
        this.setState({is_download: false})
      }, 1200)

    })
  }

  loadCompanyLogoBase64() {
    let uri =  APIURL + '/company_setting/logoimage/1';
    axios.get(uri)
        .then(res => {
          let body = res.data.body
          this.setState({ logoBase64Img: body })
        }).catch(error => {
          console.log('error:', error)
        })
  }

  loadCompanySetting() {
    axios.get(APIURL + '/company_setting/1').then(res => {
      let data = res.data
      let company_setting = data.company_setting
      this.setState({
        company_name: company_setting.company_name,
        company_tel: company_setting.company_tel
      })
    })
  }

  async loadCarImageBase64() {
    let car_upload_id = this.props.car.car_upload_id
    let uri =  APIURL + '/car/image/' + car_upload_id;
    await axios.get(uri)
        .then(res => {
          let body = res.data.body
          this.setState({ carBase64Img: body })
        }).catch(error => {
          console.log('error:', error)
        })
  }

  onChangeInputRow(carDownScheduleSelect, index, event) {
    let car_down_schedule_id = carDownScheduleSelect.car_down_schedule_id
    let name = event.target.name
    let value = event.target.value

    let status_text = carDownScheduleSelect.status_text === 'new' ? 'new' : 'edit'

    let list = this.state.lists.map(car => car.car_down_schedule_id === car_down_schedule_id ? {...car, [name]: value, status_text: status_text } : car )

    this.setState({
      lists : list
    });
  }

  onInputChange(e) {
    let name = e.target.name
    let value = e.target.value

    this.setState({
      [name]: value
    })
  }


  render () {

    /*let image_url = ''
    if (this.state.car.car_upload_filename.includes('https')) {
      image_url = this.state.car.car_upload_filename
    } else {
      image_url = IMAGE_THUMB_URL + this.state.car.car_upload_filename
    }*/

    //car_upload_id
    /*if (this.props.car_upload_id.includes('https')) {
      image_url = this.props.car_upload_id
    } else {
      image_url = IMAGE_THUMB_URL + this.props.car_upload_id
    }*/

    let image_url = this.state.carBase64Img
    let logo = this.state.logoBase64Img

    let logoWidth = 60

    let trDownSchedule = ''

    let trDownScheduleNew = this.state.lists.map((down_percent, index)=>{
      let car_down_percent_name = down_percent.car_down_percent_name
      // let car_down_schedule_price = down_percent.car_down_schedule_price
      // let monthly_48 = down_percent.monthly_installment_48
      // let monthly_60 = down_percent.monthly_installment_60
      // let monthly_72 = down_percent.monthly_installment_72
      // let monthly_84 = down_percent.monthly_installment_84

      let input_down = ''
      let input_monthly_48 = ''
      let input_monthly_60 = ''
      let input_monthly_72 = ''
      let input_monthly_84 = ''
      let input_monthly_96 = ''

      input_down = (
          <NumberFormat
              className="form-control text-center"
              thousandSeparator={true}
              min={0}
              max={1000000000}
              step={1000}
              size={10}
              allowNegative={false}
              id="car_down_schedule_price"
              name="car_down_schedule_price"
              value={this.state.lists[index].car_down_schedule_price || ''}
              onChange={(event)=> this.onChangeInputRow(down_percent, index, event)}
          />
      )

      input_monthly_48 = (
          <NumberFormat
              className="form-control text-center"
              thousandSeparator={true}
              min={0}
              max={1000000000}
              step={1000}
              size={10}
              allowNegative={false}
              id="monthly_installment_48"
              name="monthly_installment_48"
              value={this.state.lists[index].monthly_installment_48 || ''}
              onChange={(event)=> this.onChangeInputRow(down_percent, index, event)}
          />
      )

      input_monthly_60 = (
          <NumberFormat
              className="form-control text-center"
              thousandSeparator={true}
              min={0}
              max={1000000000}
              step={1000}
              size={10}
              allowNegative={false}
              id="monthly_installment_60"
              name="monthly_installment_60"
              value={this.state.lists[index].monthly_installment_60 || ''}
              onChange={(event)=> this.onChangeInputRow(down_percent, index, event)}
          />
      )

      input_monthly_72 = (
          <NumberFormat
              className="form-control text-center"
              thousandSeparator={true}
              min={0}
              max={1000000000}
              step={1000}
              size={10}
              allowNegative={false}
              id="monthly_installment_72"
              name="monthly_installment_72"
              value={this.state.lists[index].monthly_installment_72 || ''}
              onChange={(event)=> this.onChangeInputRow(down_percent, index, event)}
          />
      )

      input_monthly_84 = (
          <NumberFormat
              className="form-control text-center"
              thousandSeparator={true}
              min={0}
              max={1000000000}
              step={1000}
              size={10}
              allowNegative={false}
              id="monthly_installment_84"
              name="monthly_installment_84"
              value={this.state.lists[index].monthly_installment_84 || ''}
              onChange={(event)=> this.onChangeInputRow(down_percent, index, event)}
          />
      )

      return (
          <tr key={index}>
            <td style={{width: 105}}>{car_down_percent_name}</td>
            <td style={{width: 132}}>{input_down}</td>
            <td style={{width: 132}}>{input_monthly_48}</td>
            <td style={{width: 132}}>{input_monthly_60}</td>
            <td style={{width: 132}}>{input_monthly_72}</td>
            <td style={{width: 132}}>{input_monthly_84}</td>
          </tr>
      )
    })

    return (
      <>
        <div className="card card-accent-info" ref={this.printRef}>
          <form action=""
                method="post"
                onSubmit={(event) => {
                  this.props.onSubmit(event, this.state.lists, this.state.car_down_schedule_note)
                }}
          >
            <div className="card-header text-center">
              <h6><strong>ตารางผ่อน - ดาวน์รถ</strong></h6>
            </div>

            <div className="card-block">
              <div className="text-center">
                <img src={logo} width={logoWidth} alt="logo"/>
                <span className="ml-3">{this.state.company_name}&nbsp;&nbsp;{this.state.company_tel}</span>
              </div>

               <div className="text-center mt-3 mb-3">
                  <img src={image_url} width="600" alt="car"/>
                </div>

                  <div className="row">
                    <div className="col-sm-12 text-center">
                      <h6><strong className="font-weight-bold">{this.state.car.car_name}&nbsp; สี{this.state.car.car_color_name}</strong></h6>
                    </div>
                  </div>

                  <div className="row text-center">
                       <div className="col-12">
                         <h6>
                           <strong>ราคารถยนต์ : &nbsp;&nbsp;

                             <NumberFormat
                                 value={this.state.car_sale_price}
                                 displayType={'text'}
                                 thousandSeparator={true}
                                 //prefix={'฿'}
                             />&nbsp; บาท

                           </strong>
                         </h6>
                       </div>
                  </div>

                  <hr/>

                  <div className="row text-center">
                    <table className="" style={{marginTop: 20, marginBottom:5}}>
                      <thead>
                      <tr>
                        <th className="text-center" >ดาวน์</th>
                        <th className="text-center" >เงินดาวน์</th>
                        <th className="text-center" >48 เดือน</th>
                        <th className="text-center" >60 เดือน</th>
                        <th className="text-center" >72 เดือน</th>
                        <th className="text-center" >84 เดือน</th>
                      </tr>
                      </thead>
                      <tbody>
                      {trDownScheduleNew}
                      </tbody>
                    </table>
                  </div>
              <div className="row mt-4">
                <div className="form-group col-2 text-center">
                  <strong>หมายเหตุ :</strong>
                </div>

                <div className="form-group col-9">
                  <input type="text"
                         className="form-control"
                         id="car_down_schedule_note"
                         name="car_down_schedule_note"
                         value={this.state.car_down_schedule_note || ''}
                         onChange={(e)=>{
                           console.log('note', this.state.car_down_schedule_note)
                           this.setState({
                             car_down_schedule_note: e.target.value
                           })
                           //console.log('input car_down_schedule_note==', this.state.car_down_schedule_note)
                         }}
                  />
                </div>
              </div>
            </div>

            <div className="card-footer text-right" >
              <button type="reset"
                      onClick={this.props.onHandle.cancel}
                      className={this.state.is_download === false ? "btn btn-sm btn-danger mr-2" : "hidden"}
                      style={{width: 80}}
              >
                <i className="fa fa-refresh"/> ปิด
              </button>

                    <button type="submit"
                            className={ this.state.is_download === true ? "hidden" : this.state.action==='add'  ? "btn btn-sm btn-primary" : "btn btn-sm btn-warning" }
                            style={{width: 80}}
                    >
                      <i className="fa fa-save"/>
                      {this.state.action==='add'? " บันทึก" : " แก้ไข" }
                    </button>

              <input type="hidden" name="action" defaultValue={this.state.action}  />

            </div>
          </form>
        </div>

        <div>
          <button className="float-right mb-5 btn btn-secondary"
                  type="button"
                  onClick={()=>this.handleDownloadImage()}
          >
            ดาวน์โหลดตารางผ่อน - ดาวน์รถ.jpg
          </button>
        </div>

      </>
    )
  }
}
