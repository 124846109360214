import React, { Component } from 'react'
import axios from 'axios'
import { APIURL } from '../../config/config'
// import NumberFormat from 'react-number-format'
const port = Number(window.env.PORT);

class DebtButtonComponent extends  Component {
  constructor (props) {
    super(props)
    this.state = {
      sum_pay: 0,
      is_load: false,
      is_bank_advance_payment: false,
      remain: 0
    }

    this.loadDataCarSellPay = this.loadDataCarSellPay.bind(this)
  }

  componentDidMount () {
    this.loadDataCarSellPay()
  }

  loadDataCarSellPay () {

    if (this.props.car_id === '' || this.props.customer_id === '') {
      return
    }

    let car_sell = this.props.car_sell

    axios.get(APIURL + '/car_sell_pay/buy/' + this.props.car_id + '/' + this.props.customer_id)
      .then(res => {
        //  car_sell_pay.car_sell_pay_count
        let car_sell_pay_list = res.data

        // console.log(car_sell_pay_list)

        let sum_pay = car_sell_pay_list.filter(pay => pay.tax_status === 'show').reduce((acc, pay)=>acc + pay.car_sell_pay_count, 0)
        // console.log('sum_pay=', sum_pay)

        // ค่างวดล่างหน้าธนาคาร
        let bank_advance_payments = car_sell_pay_list.filter(pay => pay.car_sell_list_pay_name === 'ค่างวดล่วงหน้า')

        // console.log(bank_advance_payments.length)
        // console.log('sum_pay=', sum_pay)
        let is_bank_advance_payment = bank_advance_payments.length > 0 ? true : false

        let insurance_price = (car_sell.insurance_buy ==='ประกันแถม') ? 0 : car_sell.insurance_price //ราคาประกัน
        let rate_finance = car_sell.rate_finance
        let real_sell_price = car_sell.real_sell_price
        let cost_finance = car_sell.cost_finance
        let cost_transfer = car_sell.cost_transfer
        let pay_advance_finance = car_sell.pay_advance_finance
        let insurance_pa = car_sell.insurance_pa
        // ส่วนลดเงินดาวน์
        let sell_discount = Number(car_sell.sell_discount)
        let gearbox_amount = Number(car_sell.gearbox_amount)
        let debt_insurance_amount = Number(car_sell.debt_insurance_amount)

        if (port === 9005) {
          // pisan ไม่รวม pa ในรวมออกรถ
          insurance_pa = 0
        }

        let bank_finance_id = this.props.bank_finance_id
        // let total_outof_car = car_sell.total_outof_car
        let total_outof_car = (Number(real_sell_price) - Number(rate_finance) - Number(sell_discount)) + Number(insurance_price) + Number(cost_finance) + Number(cost_transfer) + Number(pay_advance_finance) + Number(insurance_pa) + gearbox_amount + debt_insurance_amount

        // ยอดที่จ่ายเข้ามาทั้งหมด
        let balance_debt = this.props.balance_debt

        // console.log("isShowText =",isShowText)
        // let remain = balance_debt - sum_pay
        let remain = total_outof_car - sum_pay

        let insurance_buy = car_sell.insurance_buy
        // let insurance_price = car_sell.insurance_price
        // ค่างวดรถต่อเดือน
        // let monthly_installment = car_sell.monthly_installment

        // let insurance_pa = car_sell.insurance_pa

        // remain += Number(insurance_pa)

        // sompongyontakan จ่ายงวดแรกของให้ finance
        //  ค่างวดล่างหน้าธนาคาร ถ้ามี เอาไปคิดเงินคงเหลือด้วย ใช้ pay_advance_finance แทน
        // if (is_bank_advance_payment) {
        //   remain += Number(monthly_installment)
        // }

        // รวมจ่าย PA
        if (insurance_buy === 'ซื้อเอง') {
          // remain += Number(insurance_price)
        }

        if (bank_finance_id === 1) {
          remain = balance_debt
        }
        if (total_outof_car === sum_pay) {
          remain = 0
        }


        this.setState({
          sum_pay: sum_pay,
          is_load: true,
          is_bank_advance_payment: is_bank_advance_payment,
          remain: remain
        }, () => {
          if (remain > 0) {
            this.props.setCarSellRemain(remain, car_sell.car_sell_id)
          }
        })
      }).catch(error => {
       console.log(error)
      // this.setState({car_sell_pay_list: []})
    })
  }

  render () {
    let car_sell = this.props.car_sell
    const { is_load, remain } = this.state
    // sum_pay, is_bank_advance_payment,
    // let isShowText = this.props.isShowText
    // console.log('remain2=', remain)
    // {car_sell.remain !== undefined ? car_sell.remain : ''}

    return (
      <>
        {
          is_load === true ?
            <button type="button"
                    className={remain  > 0 ? "btn btn-sm btn-warning btn-block": "btn btn-sm btn-warning btn-block hidden"}
                    onClick={() => {
                       this.props.onOpenDebtor();
                    }}
                    disabled={car_sell.debtors_type_id === 1 ? true: false}
            >
              <i className="icon-doc">&nbsp; ตั้งหนี้ค้างดาวน์ </i>
            </button>
            :
            null
        }
      </>
    )
  }

}
export default DebtButtonComponent
